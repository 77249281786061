import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const TIPOS_MEDICAMENTOS = [
  "Ampola",
  "Caixa",
  "Cápsula",
  "Comprimido",
  "Frasco",
  "Pacote",
  "Pomada",
  "Saquetas",
  "Solução Oral",
  "Tubo",
  "Xarope",
];

function SugestaoForm({
  medicamentos,
  handleAdicionarSugestao,
  sugestaoModalOpen,
  closeModalSugestao,
}) {
  const [open, setOpen] = useState(sugestaoModalOpen);
  const [medicamento, setMedicamento] = useState("");
  const [duracao, setDuracao] = useState();
  const [qtdd, setQtdd] = useState();
  const [tipo, setTipo] = useState(TIPOS_MEDICAMENTOS[0]);
  const [posologia, setPosologia] = useState("");

  useEffect(() => {
    setOpen(sugestaoModalOpen);
  }, [sugestaoModalOpen]);

  const medicamentoChangeHandler = (e, value) => {
    setMedicamento(value);
  };

  const handleClose = () => {
    closeModalSugestao();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Adicionar Sugestão</DialogTitle>
        <DialogContent>
          <Autocomplete
            freeSolo
            disablePortal
            id="sugestaoMedicamentos"
            options={medicamentos}
            style={{ width: "100%", marginTop: "20px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome do medicamento (ex: Paracetamol 200mg)"
              />
            )}
            onChange={medicamentoChangeHandler}
            onInputChange={medicamentoChangeHandler}
            value={medicamento}
          />

          <TextField
            margin="dense"
            id="sugestaoDuracao"
            label="Duração em dias (ex: 3)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setDuracao(e.target.value)}
          />

          <TextField
            margin="dense"
            id="sugestaoQtdd"
            label="Quantidade de embalagens (ex: 3)"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setQtdd(e.target.value)}
          />

          <FormControl sx={{ minWidth: "100%", mt: 3 }}>
            <InputLabel id="demo-controlled-open-select-label">Tipo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tipo}
              label="Tipo"
              onChange={(e) => setTipo(e.target.value)}
            >
              {TIPOS_MEDICAMENTOS.map((t, index) => (
                <MenuItem value={t} key={index}>
                  {t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            id="posologia"
            label="Posologia (ex: 1 comp. 3 em 3 horas)"
            type="text"
            fullWidth
            variant="standard"
            value={posologia}
            onChange={(e) => setPosologia(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleAdicionarSugestao.bind(
              null,
              medicamento,
              duracao,
              Number(qtdd),
              tipo,
              posologia
            )}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SugestaoForm;
