import React from "react";
import moment from "moment";
import { useContext, useEffect, useState, useCallback } from "react";
import "./home.scss";
// import Top5tratamentos from "../components/top5tratamentos/Top5tratamentos";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import Chart from "../../../shared/components/chart/Chart";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";
import PdfListaAtendimentos from "../../../shared/components/PdfGenerator/PdfListaAtendimentos/PdfListaAtendimentos";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ModalInfo from "../../../shared/components/UIElements/ModalInfo";

import AvaliacoesPorEfetuarModal from "./components/AvaliacoesPorEfetuarModal";
import UrgenciasModal from "./components/UrgenciasModal";

import Tasks from "../components/tasks/Tasks";
import InformacoesDaClinica from "../components/informacoesDaClinica/InformacoesDaClinica";

import useWebSocket from "../../../shared/hooks/use-websocket";
import Procedimentos from "./components/Procedimentos";
import { useLoading } from "../../../shared/context/LoadingContext";
import "../components/top5tratamentos/Top5tratamentos.scss";
import "../../../shared/css/Checkbox.scss";

function HomeMedicoAdministrador() {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const [userData, setUserData] = useState();
  const [urgencias, setUrgencias] = useState();
  const [openModalUrgencia, setOpenModalUrgencia] = useState(false);
  const [openModalAvaliacoesPorEfetuar, setOpenModalAvaliacoesPorEfetuar] =
    useState(false);

  const [avaliacoesPorEfetuar, setAvaliacoesPorEfetuar] = useState([]);
  const [estatisticaDeProcedimentos, setEstatisticaProcedimentos] = useState(
    []
  );
  const [listaAtendimentoData, setListaAtendimentoData] = useState({
    show: false,
  });
  const [listaPresenca, setListaPresenca] = useState([]);
  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  const handleMessage = useCallback(
    (parsedSocketData) => {
      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "insert"
      ) {
        const fetchcliente = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            setUrgencias((current) => [
              ...current,
              {
                atendido: parsedSocketData.documento.atendido,
                cliente: responseData.cliente,
                created_at: parsedSocketData.documento.created_at,
                id: parsedSocketData.id,
              },
            ]);
          } catch (err) {}
        };

        fetchcliente();
      }

      if (
        parsedSocketData.tabela === "urgencias" &&
        parsedSocketData.tipoDeOperacao === "delete"
      ) {
        setUrgencias((current) =>
          current.filter((u) => u.id !== parsedSocketData.id)
        );
      }

      if (
        parsedSocketData.tabela === "notificacaos" &&
        parsedSocketData.documento.user === auth.userId
      ) {
        const fetchcliente = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${parsedSocketData.documento.cliente}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            if (parsedSocketData.documento.tipo === "avaliação") {
              setAvaliacoesPorEfetuar((current) => [
                ...current,
                {
                  idCliente: responseData.cliente.id,
                  cliente: responseData.cliente.nome,
                  created_at: parsedSocketData.documento.created_at,
                  id: parsedSocketData.id,
                },
              ]);
            }
          } catch (err) {}
        };

        fetchcliente();
      }

      if (parsedSocketData.tabela === "listapresencas") {
        const fetchListaPresenca = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/listapresenca/${clinica.clinica._id}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );

            setListaPresenca(responseData.listaPresenca);
          } catch (err) {}
        };

        fetchListaPresenca();
      }
    },
    [auth, clinica.clinica._id, sendRequest]
  );

  useWebSocket(process.env.REACT_APP_WEBSOCKET_ADDRESS, handleMessage, [
    auth,
    clinica.clinica._id,
    sendRequest,
  ]);

  useEffect(() => {
    const fetchEstatisticaProcedimentos = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/dashboardadministrador/estatisticaprocedimentos/${auth.userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setEstatisticaProcedimentos(responseData.listaProcedimentos);
      } catch (err) {
        console.error("err", err);
      }
    };

    fetchEstatisticaProcedimentos();

    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/${auth.userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setUserData({
          nome: responseData.user.name,
          genero: responseData.user.genero,
        });
      } catch (err) {}
    };
    fetchUser();

    const fetchUrgencias = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setUrgencias(responseData.urgencias);
      } catch (err) {}
    };
    fetchUrgencias();

    const fetchAvaliacoesPorEfetuar = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const avalList = [];
        responseData.notificacoes.forEach((n) => {
          if (n.tipo === "avaliação" && auth.userId === n.user) {
            avalList.push({
              id: n.id,
              idCliente: n.cliente.id,
              cliente: n.cliente.nome,
              created_at: n.created_at,
            });
          }
        });
        setAvaliacoesPorEfetuar(avalList);
      } catch (err) {}
    };
    fetchAvaliacoesPorEfetuar();

    const fetchListaPresenca = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/listapresenca/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setListaPresenca(responseData.listaPresenca);
      } catch (err) {}
    };
    fetchListaPresenca();
  }, [sendRequest, auth.userId, clinica, auth.token]);

  //MODAL Urgencia
  const handleOpen = (tipo) => {
    if (tipo === "urgencia") {
      if (urgencias.length > 0) setOpenModalUrgencia(true);
    }
    if (tipo === "avaliacao") {
      if (avaliacoesPorEfetuar.length > 0)
        setOpenModalAvaliacoesPorEfetuar(true);
    }
  };

  const handleClose = () => {
    setOpenModalUrgencia(false);
    setOpenModalAvaliacoesPorEfetuar(false);
  };

  //Codigo para marcar cliente como atendido
  const handleAtendido = async (id) => {
    try {
      startLoading();
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/urgencias/atendido/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setUrgencias(
        urgencias.filter((el) => {
          return el.id !== id;
        })
      );

      toast.success("Cliente de urgência atendido com sucesso!");
      handleModalCancel();
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const atendidoConfirmation = (id, nome) => {
    setOpenModalUrgencia(false);
    setModalProps({
      show: true,
      icon: "info",
      title: `Atendimento de urgência!`,
      message: `Tem a certeza que pretende marcar o cliente ${nome} com atendido?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleAtendido.bind(null, id),
    });
  };

  const avaliacaoDispensada = (id) => {
    const tempAvaliacoes = avaliacoesPorEfetuar.filter((av) => av.id !== id);
    setAvaliacoesPorEfetuar(tempAvaliacoes);
  };

  ///////////////////////////////////////////////////

  const showSinglePage = () => {
    if (listaAtendimentoData.show)
      setListaAtendimentoData((current) => {
        return { ...current, show: false };
      });
  };

  const visualizarPdf = (lista) => {
    setListaAtendimentoData({
      show: true,
      data: lista,
      dataLista: lista[0].data_inicio_execucao,
    });
  };

  return (
    <>
      {listaAtendimentoData.show && (
        <PdfListaAtendimentos
          showPreviousPage={showSinglePage}
          listaAtendimentoData={listaAtendimentoData.data}
          medico={auth.nome}
          clinica={clinica.clinica.nome}
          dataLista={moment(listaAtendimentoData.dataLista).format(
            "DD-MM-YYYY"
          )}
        />
      )}
      {!listaAtendimentoData.show && (
        <>
          <div className="home">
            <Sidebar />
            <div className="homeContainer">
              <Navbar
                title="dashboard"
                icon={DashboardIcon}
                avaliacaoDispensada={avaliacaoDispensada}
              />
              <ModalInfo {...modalProps} />

              {openModalUrgencia && (
                <UrgenciasModal
                  handleClose={handleClose}
                  atendidoConfirmation={atendidoConfirmation}
                  urgencias={urgencias}
                  openModalUrgencia={openModalUrgencia}
                />
              )}
              {openModalAvaliacoesPorEfetuar && (
                <AvaliacoesPorEfetuarModal
                  handleClose={handleClose}
                  avaliacoesPorEfetuar={avaliacoesPorEfetuar}
                  openModalAvaliacoesPorEfetuar={openModalAvaliacoesPorEfetuar}
                />
              )}
              <div className="dashboard__top">
                {userData && estatisticaDeProcedimentos && (
                  <Chart
                    nome={userData.nome}
                    genero={userData.genero}
                    role={auth.role}
                    data={estatisticaDeProcedimentos}
                  />
                )}

                <div className="caixa dashboard__right">
                  <div className="dashboard__right__top">
                    <div className="dashboard__right__top-left">
                      <span className="box-title">Avaliações</span>
                      <div className="info-planos-tratamento">
                        <span className="big-numbers">
                          {avaliacoesPorEfetuar.length}
                        </span>
                        <span className="medium-gray-text">por efetuar</span>
                      </div>
                      <div
                        className="button-more-div"
                        onClick={handleOpen.bind(null, "avaliacao")}
                      >
                        <span className="button-more">Mais</span>
                        <span className="more-arrow"> {">"} </span>
                      </div>
                    </div>
                    {urgencias && (
                      <div className="dashboard__right__top-right">
                        <span
                          className={`box-title ${
                            urgencias.length > 0 && `urgencia`
                          }`}
                        >
                          Pacientes de Urgência
                        </span>
                        <span
                          className={`big-numbers ${
                            urgencias.length > 0 && `urgencia`
                          }`}
                        >
                          {urgencias.length}
                        </span>
                        <div
                          className="button-more-div"
                          onClick={handleOpen.bind(null, "urgencia")}
                        >
                          <span
                            className={`button-more ${
                              urgencias.length > 0 && `urgencia`
                            }`}
                          >
                            Mais
                          </span>
                          <span
                            className={`more-arrow ${
                              urgencias.length > 0 && `more-arrow--urgencia`
                            }`}
                          >
                            {">"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                  <InformacoesDaClinica
                    sendRequest={sendRequest}
                    auth={auth}
                    clinica={clinica}
                  />
                </div>
              </div>

              <div className="listContainer caixa">
                <Procedimentos
                  visualizarPdf={visualizarPdf}
                  listaPresencaProp={listaPresenca}
                />
              </div>

              <div className="dashboard__bottom caixa tasks__container">
                <Tasks
                  clinica={clinica}
                  auth={auth}
                  sendRequest={sendRequest}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HomeMedicoAdministrador;
