import { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import InputText from "../../../shared/components/inputs/InputText/InputText";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../categoriasFinanceiro/CategoriasFinanceiro.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { Popconfirm } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

function Caixas({ sendRequest, clinicaId }) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nomeCaixa, setNomeCaixa] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [idCaixa, setIdCaixa] = useState(null);
  const [caixas, setCaixas] = useState([]);
  const [mensagemDeErro, setMensagemDeErro] = useState("");
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setCaixas(responseData.caixas);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [auth.token, clinicaId, sendRequest]);

  const caixaChangeHandler = (valor) => {
    setNomeCaixa(valor);
  };

  const guardarCaixa = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("caixa", nomeCaixa);
      formData.append("clinica", clinicaId);
      formData.append("criadoPor", auth.userId);

      const caixaCriada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setCaixas((current) => [...current, caixaCriada.caixa]);

      setNomeCaixa("");
      setOpenModal(false);
      setIdCaixa(null);

      toast.success("Caixa criada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const editarCaixa = async (id) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("caixa", nomeCaixa);
      formData.append("clinica", clinicaId);
      formData.append("atualizadoPor", auth.userId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/${id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      const caixaCopy = [...caixas];

      const caixasModificadas = caixaCopy.map((cat) => {
        if (cat.id === id) {
          return {
            id: id,
            caixa: nomeCaixa,
          };
        } else {
          return cat;
        }
      });

      setCaixas([...caixasModificadas]);

      setNomeCaixa("");
      setIdCaixa(null);
      setOpenModal(false);

      toast.success("Caixa modificada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const preEditarCaixa = async (id) => {
    setIdCaixa(id);
    setNomeCaixa(caixas.filter((cat) => cat.id === id)[0].caixa);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setMensagemDeErro("");
    setIdCaixa(null);
    setNomeCaixa("");
    setOpenModal(false);
  };

  const desativarCaixa = async (id) => {
    startLoading();
    try {
      const formDataDesativar = new FormData();
      formDataDesativar.append("desativadoPor", auth.userId);
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/desativar/${id}`,
        "PATCH",
        formDataDesativar,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setCaixas((current) => current.filter((cat) => cat.id !== id));

      toast.success("Caixa desativada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const beforeSubmit = (idCaixa) => {
    let haErro = false;
    if (!nomeCaixa || nomeCaixa === "") {
      setMensagemDeErro("O campo nome da caixa não pode estar vazio");
      haErro = true;
    } else {
      setMensagemDeErro("");
    }
    if (!isSubmitting && !haErro) {
      setIsSubmitting(true);
      if (idCaixa) {
        editarCaixa(idCaixa);
      } else {
        guardarCaixa();
      }
    }
  };

  return (
    <>
      <div className="categoriasGeral__container">
        {auth.perm.includes("c-cx") && (
          <div className="categoriasGeral__container__btn-adicionar">
            <Button
              variant="contained"
              color="success"
              onClick={(e) => setOpenModal(true)}
            >
              Nova Caixa
            </Button>
          </div>
        )}

        {caixas &&
          caixas.map((caixa, index) => (
            <div
              className="categoriasFinanceiro__container__categoria"
              key={index}
            >
              <div className="categoriasFinanceiro__container__categoria--1">
                {caixa.caixa}
              </div>
              <div className="categoriasFinanceiro__container__categoria--2">
                {auth.perm.includes("d-cx") &&
                  caixa.caixa.toLowerCase() !== "clínica" && (
                    <Popconfirm
                      title={`Desativar Caixa`}
                      description={`Pretende desativar essa caixa?`}
                      icon={<ErrorIcon style={{ color: "red" }} />}
                      okText="Sim"
                      cancelText="Não"
                      onConfirm={desativarCaixa.bind(null, caixa.id)}
                      className="popConfirm--biggerPadding"
                    >
                      <span>Desativar</span>
                    </Popconfirm>
                  )}
                {auth.perm.includes("u-cx") &&
                  caixa.caixa.toLowerCase() !== "clínica" && (
                    <span onClick={preEditarCaixa.bind(null, caixa.id)}>
                      Editar
                    </span>
                  )}
              </div>
            </div>
          ))}

        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="fluxo-atendimento__modal__box adicionar-categoria__modal"
            id="modal__adicionar-categoria"
          >
            <h1 className="fluxo-atendimento__modal__titulo">Caixa</h1>
            <div className="adicionar-categoria__modal__container">
              <InputText
                className="adicionar-categoria__modal__container--span2"
                label="Nome da Caixa *"
                initialValue={nomeCaixa}
                handleChange={caixaChangeHandler}
              />
            </div>

            {mensagemDeErro && (
              <div className="erroContainer__categoria-caixa">
                {mensagemDeErro}
              </div>
            )}

            <div className="fluxo-atendimento__modal__bottom_btns">
              <span
                className="cancel-btn"
                onClick={handleModalClose}
                style={{ display: "block" }}
              >
                Fechar
              </span>

              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={
                  idCaixa
                    ? beforeSubmit.bind(null, idCaixa)
                    : beforeSubmit.bind(null, null)
                }
              >
                {idCaixa
                  ? isSubmitting
                    ? "Editando"
                    : "Editar"
                  : isSubmitting
                  ? "Guardando"
                  : "Guardar"}
              </span>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Caixas;
