//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalMotivoCancelamentoPendencias.scss";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

const motivosEncerramento = [
  "Desistência do paciente",
  "Mudança de morada",
  "Impossibilidade financeira",
  "Insatisfação com atendimento",
  "Problemas de saúde",
  "Outros",
];

function ModalMotivoCancelamentoPendencias({
  open,
  handleCloseModalCancelarPendencias,
  modalData,
  updateContaCorrente,
  contaCorrente,
  auth,
  sendRequest,
  clinicaId,
}) {
  const [motivoEncerramento, setMotivoEncerramento] = useState(
    motivosEncerramento[0]
  );
  const [erro, setErro] = useState("");
  const [descricao, setDescricao] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const motivosEncerramentoChangeHandler = (e) => {
    setMotivoEncerramento(e.target.value);
  };

  const beforeSubmitHandler = () => {
    if (motivoEncerramento === "Outros" && descricao.trim().length < 10)
      setErro(
        `Motivo de término de pendências deve ter 10 ou mais caracteres!`
      );
    else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = async () => {
    setIsSubmitting(true);
    startLoading();
    try {
      const formData = new FormData();
      formData.append("contaCorrenteId", contaCorrente._id);
      formData.append("contaId", modalData._id);
      formData.append(
        "motivoEncerramento",
        JSON.stringify({ tipo: motivoEncerramento, descricao: descricao })
      );
      formData.append("canceladoPor", auth.userId);

      const contaCorrenteRetornada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/terminarPendencias/${clinicaId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Conta suspendida com sucesso!");
      updateContaCorrente(contaCorrenteRetornada.contaCorrente);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
      handleCloseModalCancelarPendencias();
    }
    /////////////////////////////////
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModalCancelarPendencias}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Motivo de término de pendências
          </h1>

          <div className="motivoCancelamento__modal__container">
            <select
              onChange={motivosEncerramentoChangeHandler}
              className="motivoCancelamento__modal__select"
            >
              {motivosEncerramento.map((m, index) => (
                <option key={index} value={m}>
                  {m}
                </option>
              ))}
            </select>

            <textarea
              className="motivoCancelamento__modal__textarea"
              cols={4}
              rows={4}
              onChange={(e) => setDescricao(e.target.value)}
              value={descricao}
              placeholder="Descrição do motivo de término das pendências"
            />
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>

            <span
              className="cancel-btn"
              onClick={handleCloseModalCancelarPendencias}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoCancelamentoPendencias;
