import React, { createContext } from "react";
import { Toaster } from "react-hot-toast";

const ToastContext = createContext();

export const ToasterProvider = ({ children }) => {
  return (
    <ToastContext.Provider value={{}}>
      <Toaster
        position="top-center"
        duration={10000}
        toastOptions={{ style: { fontSize: "15px", textAlign: "center" } }}
      />
      {children}
    </ToastContext.Provider>
  );
};
