import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import InputNumber from "../../../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputTextArea from "../../../../../../../shared/components/inputs/InputTextArea/InputTextArea";

import "./ValorDataCaixa.scss";

function ValorDataCaixa({
  valor,
  changeValorDataCaixa,
  caixas,
  totalPorReceber,
  tab,
}) {
  const [caixa, setCaixa] = useState();
  const [valorPago, setValorPago] = useState();
  const [data, setData] = useState(moment());
  const [observacao, setObservacao] = useState("");

  useEffect(() => {
    //Caixas 0 sempre será Clinica
    if (tab === "dinheiro") setCaixa(caixas[0].id);
    else setCaixa(caixas[1]?.id ? caixas[1]?.id : caixas[0].id);
  }, [caixas, tab]);

  useEffect(() => {
    setValorPago(valor);
  }, [valor]);

  const valorChangeHandler = (value) => {
    setValorPago(value);
    changeValorDataCaixa({
      valor: value,
      data: data,
      caixa: caixa,
      observacao: observacao,
    });
  };

  const caixaChangeHandler = (e) => {
    setCaixa(e.target.value);
    changeValorDataCaixa({
      valor: valorPago,
      data: data,
      caixa: e.target.value,
      observacao: observacao,
    });
  };

  const dataChangeHandler = (value) => {
    setData(value);
    changeValorDataCaixa({
      valor: valorPago,
      data: value,
      caixa: caixa,
      observacao: observacao,
    });
  };

  const observacaoChangeHandler = (value) => {
    setObservacao(value);
    changeValorDataCaixa({
      valor: valorPago,
      data: data,
      caixa: caixa,
      observacao: value,
    });
  };

  return (
    <>
      {valorPago && (
        <>
          <div className="valorDataCaixa__fields">
            <InputNumber
              initialValue={valor}
              label="Valor Pago"
              handleChange={valorChangeHandler}
              min={1}
              max={totalPorReceber}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de recebimento*"
                inputFormat="DD-MM-YYYY"
                value={data}
                onChange={dataChangeHandler}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {caixas && caixa && (
              <div className="customInputContainer adicionar-despesa__modal__container--span2">
                <select
                  onChange={caixaChangeHandler}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}
          </div>
          <InputTextArea
            handleChange={observacaoChangeHandler}
            label="Observação"
            initialValue={observacao}
            rows="5"
            cols="30"
          />
        </>
      )}
    </>
  );
}

export default ValorDataCaixa;
