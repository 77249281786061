import { useState, useEffect } from "react";
import "../../../../shared/css/ElementoPlanoOrcamentoAvaliacao.scss";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#0c53fb",
    },
  },
};

const facesDentes = ["D", "M", "O/I", "P/L", "V"];

function ElementoOrcamentoEdit(props) {
  const [dentes, setDentes] = useState(props.dentesTratados);
  const [faces, setFaces] = useState(props.faces);
  const [showFaces, setShowFaces] = useState(true);
  const [showDentes, setShowDentes] = useState(false);
  const [erroDentes, setErroDentes] = useState(false);
  const [erroFaces, setErroFaces] = useState(false);
  const [listaDentes, setListaDentes] = useState([]);
  const [tratamento, setTratamento] = useState();

  useEffect(() => {
    if (
      !props.tratamento.aceitaFaces ||
      props.dentesTratados.includes("Arcada Inferior") ||
      props.dentesTratados.includes("Arcada Superior") ||
      props.dentesTratados.includes("Arcadas")
    ) {
      setShowFaces(false);
    }

    setListaDentes(
      props.tratamento.dentesEspecificos
        ? props.tratamento.dentesEspecificos.split(", ")
        : props.dentes
    );

    // setDentes(
    //   !props.multiDentes
    //     ? props.dentesTratados[0] || ""
    //     : props.tratamento.dentesEspecificos.split(",").length === 1
    //     ? props.tratamento.dentesEspecificos.split(",")
    //     : props.dentesTratados
    //     ? props.dentesTratados
    //     : []
    // );

    setShowDentes(props.tratamento.aceitaDentes);

    setTratamento(props.tratamento);
  }, []);

  const dentesChangeHandler = (event) => {
    const value = event.target.value;

    let erro = "";

    //Aqui não há necessidade de compara o nr de dentes com o nr de dentes do orçamento original
    //Pois o tratamento multiDentes exige o mesmo nr de dentes para ambos edição e criação de orçamento e avaliação
    if (tratamento.multiDentes && value.length !== tratamento.qtdDentes) {
      erro = `O tratamento ${tratamento.designacao} exige que selecione ${tratamento.qtdDentes} dentes. Foram selecionados ${value.length}.`;
      setErroDentes(true);
    } else {
      erro = "";
      setErroDentes(false);
    }

    if (
      value === "Arcada Inferior" ||
      value === "Arcada Superior" ||
      value === "Arcadas" ||
      !tratamento.aceitaFaces
    ) {
      setFaces([]);
      setShowFaces(false);
      props.facesChangeHandler(props.id, []);
    } else {
      setShowFaces(true);
    }

    if (
      value.includes("Arcada Inferior") ||
      value.includes("Arcada Superior") ||
      value.includes("Arcadas")
    ) {
      if (
        value.includes("Arcada Inferior") ||
        value.includes("Arcada Superior")
      ) {
        const apenasArcadas = value.filter(
          (arcadas) =>
            arcadas === "Arcada Inferior" || arcadas === "Arcada Superior"
        );
        setDentes(apenasArcadas);
        props.dentesChangeHandler(props.id, apenasArcadas);
      }
      if (event.target.value.includes("Arcadas")) {
        setDentes(["Arcadas"]);
        props.dentesChangeHandler(props.id, ["Arcadas"]);
      }
    } else {
      setDentes(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      props.dentesChangeHandler(props.id, value, erro);
    }

    setDentes(value);
    props.dentesChangeHandler(props.id, value, erro);
  };

  const facesChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    let erro = "";
    if (value.length > tratamento.qtdFaces) {
      erro = `O tratamento ${tratamento.designacao} exige que selecione no máximo ${tratamento.qtdFaces} faces. Foram selecionadas ${value.length}.`;
      setErroFaces(true);
    } else {
      erro = "";
      setErroFaces(false);
    }

    setFaces(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    props.facesChangeHandler(props.id, event.target.value, erro);
  };

  return (
    <>
      <span className="editElementoOrcamento__nomeTratamento">
        {props.tratamento.designacao}
      </span>

      {showDentes && listaDentes && !props.tratamento.multiDentes && (
        <FormControl sx={{ m: 1 }} className={erroDentes ? "erroDentes" : ""}>
          <InputLabel id="dentes-select-label">Dente</InputLabel>
          <Select
            labelId="dentes-select-label"
            id="dentes-select"
            value={dentes}
            onChange={dentesChangeHandler}
            input={<OutlinedInput label="Dente" />}
            MenuProps={MenuProps}
            style={{ color: "#0c53fb" }}
          >
            {listaDentes.map((dente) => (
              <MenuItem key={dente} value={dente}>
                {dente}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showDentes && listaDentes && props.tratamento.multiDentes && (
        <FormControl sx={{ m: 1 }} className={erroDentes ? "erroDentes" : ""}>
          <InputLabel id="demo-multiple-checkbox-label">Dentes</InputLabel>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={dentes}
            onChange={dentesChangeHandler}
            input={<OutlinedInput label="Dentes" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ color: "#0c53fb" }}
          >
            {listaDentes.map((dente) => (
              <MenuItem key={dente} value={dente}>
                <Checkbox checked={dentes.indexOf(dente) > -1} />
                <ListItemText primary={dente} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {showFaces && (
        <FormControl sx={{ m: 1 }} className={erroFaces ? "erroDentes" : ""}>
          <InputLabel id="demo-multiple-checkbox-label">Faces</InputLabel>
          <Select
            id="demo-multiple-checkbox"
            multiple
            value={faces}
            onChange={facesChangeHandler}
            input={<OutlinedInput label="Faces" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            style={{ color: "#0c53fb" }}
          >
            {facesDentes.map((face) => (
              <MenuItem key={face} value={face}>
                <Checkbox checked={faces.indexOf(face) > -1} />
                <ListItemText primary={face} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default ElementoOrcamentoEdit;
