import React from "react";
import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import { toast } from "react-hot-toast";
import "./ExecutarPlanoTratamento.scss";

import { useLoading } from "../../../shared/context/LoadingContext";

//mui icons
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";
import SettingsIcon from "@mui/icons-material/Settings";

function ExecutarPlanoTratamento() {
  const { startLoading, stopLoading } = useLoading();

  const [evolucao, setEvolucao] = useState();
  const [planoTratamento, setPlanoTratamento] = useState();
  const [procedimento, setProcedimento] = useState();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [step, setStep] = useState("1");
  const [marcacao, setMarcacao] = useState();
  const [notificacaoEmStep2, setNotificacaoEmStep2] = useState("");

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const temporary = useParams().planoTratamentoId;
  const planoTratamentoId = temporary.split("-")[0];
  const marcacaoId = temporary.split("-")[1];
  const agendaId = temporary.split("-")[2];

  //fetch planoTratamento, medicos
  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [planoTratamentoResponse, marcacaoResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/${planoTratamentoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcacao/${marcacaoId}`,
            "PATCH",
            createFormData({ agendaId }),
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process plano de tratamento
        const planoFinal = processPlanoTratamento(
          planoTratamentoResponse.planoTratamento
        );
        setPlanoTratamento(planoFinal);

        // Set marcação
        setMarcacao(marcacaoResponse.marcacao);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, planoTratamentoId, marcacaoId, agendaId, auth.token]);

  // Helper functions
  const processPlanoTratamento = (planoTratamento) => {
    const sortedProcedimentos = [...planoTratamento.procedimentos].sort(
      (a, b) => {
        if (a.procedimento_completo && !b.procedimento_completo) return 1;
        if (!a.procedimento_completo && b.procedimento_completo) return -1;
        return 0;
      }
    );

    return {
      ...planoTratamento,
      procedimentos: sortedProcedimentos,
    };
  };

  const createFormData = (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return formData;
  };
  //////////////////////////////////////////////

  const submitHandler = async () => {
    startLoading();
    try {
      let data_fim;
      let data_inicio;
      //Calcular o nr de procedimentos efetuados no plano
      const nrProcedimentosEfetuados = planoTratamento.procedimentos.filter(
        (pr) => pr.procedimento_completo
      );

      //Se o nr de procedimentos efetuados for igual ao nr de procedimento do plano
      //O plano fica completo com uma data de fim definida pela data de fim da ultima marcacao executada
      if (
        nrProcedimentosEfetuados.length ===
        planoTratamento.procedimentos.length - 1
      ) {
        data_fim = marcacao.data_fim_execucao;
      }
      //Se esse procedimento é o primeiro procedimento efetuado
      //O plano fica com uma data de inicio definida pela data de inicio dess marcacao
      if (nrProcedimentosEfetuados.length === 0) {
        data_inicio = marcacao.data_inicio_execucao;
      }

      const procedimentosParaAtualizarState = [];
      const procedimentosTrabalhados = [];
      planoTratamento.procedimentos.forEach((proc) => {
        if (proc.id === procedimento.id) {
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
            procedimento_completo: true,
            data_inicio_execucao: proc.sessoes[0]
              ? proc.sessoes[0].data_inicio_execucao
              : marcacao.data_inicio_execucao,
            data_fim_execucao: marcacao.data_fim_execucao,
            sessoes: [
              ...proc.sessoes,
              {
                data_inicio_execucao: marcacao.data_inicio_execucao,
                data_fim_execucao: marcacao.data_fim_execucao,
                evolucao: evolucao ? evolucao : "Procedimento Executado",
              },
            ],
          });
          //////
          procedimentosParaAtualizarState.push({
            ...proc,
            procedimento_completo: true,
            data_inicio_execucao: proc.sessoes[0]
              ? proc.sessoes[0].data_inicio_execucao
              : marcacao.data_inicio_execucao,
            data_fim_execucao: marcacao.data_fim_execucao,
            sessoes: [
              ...proc.sessoes,
              {
                data_inicio_execucao: marcacao.data_inicio_execucao,
                data_fim_execucao: marcacao.data_fim_execucao,
                evolucao: evolucao ? evolucao : "Procedimento Executado",
              },
            ],
          });
        } else {
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
          });
          procedimentosParaAtualizarState.push(proc);
        }
      });

      if (notificacaoEmStep2) {
        const formData = new FormData();
        formData.append("agendaId", agendaId);
        formData.append(
          "categoriaTratamento",
          procedimento.tratamento.categoria
        );

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/alterarcategoriaprocedimentonamarcacao/${marcacaoId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      //Marca a sessão como atendida
      const formData2 = new FormData();
      formData2.append("agendaId", agendaId);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcaratendido/${marcacaoId}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const formData = new FormData();
      formData.append(
        "procedimentos",
        JSON.stringify(procedimentosTrabalhados)
      );

      //Se o procedimento tem uma data de fim, o plano fica completo
      formData.append("plano_completo", data_fim ? true : false);
      formData.append("data_inicio", data_inicio ? data_inicio : null);
      formData.append("data_fim", data_fim ? data_fim : null);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/${planoTratamento.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Se o plano terminar, o orcamento fica a finalizado
      if (data_fim) {
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/finalizar/${planoTratamento.orcamento}`,
            "PATCH",
            {},
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      setPlanoTratamento((current) => {
        return {
          ...current,
          data_inicio: data_inicio,
          data_fim: data_fim ? data_fim : null,
          plano_completo: data_fim ? true : false,
          procedimentos: procedimentosParaAtualizarState,
        };
      });

      toast.success(
        `Procedimento finalizado!${
          data_fim ? "O Plano de tratamento terminado!" : ""
        }`
      );

      setEvolucao("");
      setStep("1");
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmiting(false);
      stopLoading();
    }
  };

  const handleGuardar = async () => {
    setIsSubmiting(true);
    startLoading();
    try {
      if (notificacaoEmStep2) {
        const formData = new FormData();
        formData.append("agendaId", agendaId);
        formData.append(
          "categoriaTratamento",
          procedimento.tratamento.categoria
        );
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/alterarcategoriaprocedimentonamarcacao/${marcacaoId}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }
      }

      // const formData = new FormData();
      // formData.append("evolucao", evolucao);
      // formData.append("procedimentoId", procedimento.id);

      // await sendRequest(
      //   `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/atualizarevolucao/${planoTratamento.id}`,
      //   "PATCH",
      //   formData,
      //   {
      //     Authorization: "Bearer " + auth.token,
      //   }
      // );

      //Marca a sessão como atendida
      const formData2 = new FormData();
      formData2.append("agendaId", agendaId);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcaratendido/${marcacaoId}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      //Ao clicar em guardar, é adicionada uma sessão
      const formData3 = new FormData();
      formData3.append("planoTratamentoId", planoTratamento.id);
      formData3.append("data_inicio_execucao", marcacao.data_inicio_execucao);
      formData3.append("data_fim_execucao", marcacao.data_fim_execucao);
      formData3.append(
        "evolucao",
        evolucao ? evolucao : "Procedimento executado."
      );
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/adicionarsessao/${procedimento.id}`,
          "PATCH",
          formData3,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      let tempPlano = { ...planoTratamento };

      tempPlano = {
        ...tempPlano,
        procedimentos: tempPlano.procedimentos.map((pr) =>
          pr.id !== procedimento._id
            ? pr
            : {
                ...pr,
                sessoes: [
                  ...pr.sessoes,
                  {
                    data_inicio_execucao: marcacao.data_inicio_execucao,
                    data_fim_execucao: marcacao.data_fim_execucao,
                    evolucao: evolucao,
                  },
                ],
              }
        ),
      };

      setPlanoTratamento({ ...tempPlano });

      toast.success(`Sessão do procedimento efetuada!`);
      setEvolucao("");
      setStep("1");

      // navigate("/", { replace: true });
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmiting(false);
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    setIsSubmiting(true);
    submitHandler();
  };

  const handleChooseProcedimento = (idPro) => {
    setStep("2");

    const procedimentoFiltrado = planoTratamento.procedimentos.filter(
      (p) => p.id === idPro
    )[0];
    setProcedimento(procedimentoFiltrado);
    if (
      procedimentoFiltrado.tratamento.categoria !== marcacao.categoriaTratamento
    )
      setNotificacaoEmStep2(
        "ATENÇÃO. O TRATAMENTO QUE ESCOLHEU NÃO CORRESPONDE À CATEGORIA DO TRATAMENTO DA AGENDA!"
      );
  };

  const handleVoltar = () => {
    setProcedimento(null);
    setEvolucao("");
    setNotificacaoEmStep2("");
    setStep("1");
  };

  const handleCancelarProcedimento = async (idProc) => {
    startLoading();
    try {
      //Calcular o nr de procedimentos efetuados no plano
      const nrProcedimentosEfetuados = planoTratamento.procedimentos.filter(
        (pr) => pr.procedimento_completo
      );

      const procedimentosParaAtualizarState = [];
      const procedimentosTrabalhados = [];
      planoTratamento.procedimentos.forEach((proc) => {
        if (proc.id === idProc) {
          procedimentosTrabalhados.push({
            ...proc,
            sessoes: proc.sessoes.slice(0, -1),
            tratamento: proc.tratamento.id,
            procedimento_completo: false,
            data_fim_execucao: null,
            data_inicio_execucao: null,
          });
          //////
          procedimentosParaAtualizarState.push({
            ...proc,
            sessoes: proc.sessoes.slice(0, -1),
            procedimento_completo: false,
            data_fim_execucao: null,
            data_inicio_execucao: null,
          });
        } else {
          procedimentosTrabalhados.push({
            ...proc,
            tratamento: proc.tratamento.id,
          });
          procedimentosParaAtualizarState.push(proc);
        }
      });

      const formData = new FormData();
      //Verificar backend. Indeterminado faz com que a data de fim seja null
      formData.append("data_fim", "indeterminado");
      formData.append("plano_completo", false);
      if (nrProcedimentosEfetuados === 0) {
        formData.append("data_inicio", "indeterminado");
      }
      formData.append(
        "procedimentos",
        JSON.stringify(procedimentosTrabalhados)
      );

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/${planoTratamento.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      setPlanoTratamento((current) => {
        return {
          ...current,
          data_fim: null,
          plano_completo: false,
          procedimentos: procedimentosParaAtualizarState,
        };
      });

      toast.success(`Execução do procedimento cancelado.`);
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmiting(false);
      stopLoading();
    }
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {planoTratamento && (
            <Navbar
              title="Executar procedimento"
              icon={NoteAltOutlinedIcon}
              paths={[
                {
                  nome: planoTratamento.cliente.nome,
                  link: `../../../clientes/${planoTratamento.cliente.id}-planotratamento`,
                },
                { nome: "Executar" },
              ]}
            />
          )}

          {planoTratamento && planoTratamento.status === "INTERROMPIDO" && (
            <div className="caixa executarProcedimento__notificacaoContainer">
              <span className="executarProcedimento__notificacaoContainer__titulo">
                Atenção! Esse plano de tratamento foi interrompido.
              </span>
              <div>
                <span className="executarProcedimento__notificacaoContainer__subTitulo">
                  Motivo :
                </span>
                <span className="executarProcedimento__notificacaoContainer__descricao">
                  {" "}
                  {planoTratamento.motivoEncerramento?.tipo}
                </span>
              </div>
              <div>
                <span className="executarProcedimento__notificacaoContainer__subTitulo">
                  Descrição :
                </span>
                <span className="executarProcedimento__notificacaoContainer__descricao">
                  {" "}
                  {planoTratamento.motivoEncerramento?.descricao}
                </span>
              </div>
              <div>
                <span className="executarProcedimento__notificacaoContainer__subTitulo">
                  Interrompido Por :
                </span>
                <span className="executarProcedimento__notificacaoContainer__descricao">
                  {" "}
                  {planoTratamento.motivoEncerramento.responsavel?.name + "   "}
                </span>

                <span className="executarProcedimento__notificacaoContainer__subTitulo">
                  {" "}
                  Data :
                </span>
                <span className="executarProcedimento__notificacaoContainer__descricao">
                  {" "}
                  {moment(planoTratamento.motivoEncerramento.data).format(
                    "DD-MM-YYYY"
                  )}
                </span>
              </div>
            </div>
          )}

          {step === "1" && (
            <div className="executarProcedimento__step1 caixa">
              <span className="executarProcedimento__step1__tabela-heading  executarProcedimento__step1__tabela-heading--1st">
                Tratamento
              </span>
              <span className="executarProcedimento__step1__tabela-heading">
                Dentes
              </span>
              <span className="executarProcedimento__step1__tabela-heading">
                Faces
              </span>
              <span className="executarProcedimento__step1__tabela-heading">
                Sessões
              </span>
              <span className="executarProcedimento__step1__tabela-heading">
                Status
              </span>
              <span className="executarProcedimento__step1__tabela-heading executarProcedimento__step1__tabela-heading--last">
                Ação
              </span>

              {planoTratamento &&
                planoTratamento.procedimentos.map(
                  (p, index) =>
                    !p.procedimento_completo && (
                      <React.Fragment key={index}>
                        <span className="executarProcedimento__step1__lineItem executarProcedimento__step1__lineItem--1st">
                          {p.tratamento.designacao}
                        </span>
                        <span className="executarProcedimento__step1__lineItem">
                          {p.dente}
                        </span>
                        <span className="executarProcedimento__step1__lineItem">
                          {p.faces.join(" ,")}
                        </span>

                        <span className="executarProcedimento__step1__lineItem">
                          {p.sessoes.length}
                        </span>
                        <div className="executarProcedimento__step1__procedimento__container executarProcedimento__step1__lineItem">
                          <span
                            className={`
                        executarProcedimento__step1__procedimento
                        ${
                          p.sessoes.length > 0
                            ? "executarProcedimento__step1__procedimento-emProgresso"
                            : "executarProcedimento__step1__procedimento-novo"
                        }
                      `}
                          >
                            {p.sessoes.length > 0 ? (
                              <>
                                <SettingsIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                                Em Progresso
                              </>
                            ) : (
                              <>
                                <StarBorderIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                                Novo
                              </>
                            )}
                          </span>
                        </div>

                        <div className="executarProcedimento__step1__lineItem executarProcedimento__step1__btnContainer">
                          <span
                            className="executarProcedimento__step1__btn-executar executarButton"
                            onClick={handleChooseProcedimento.bind(null, p.id)}
                          >
                            Executar
                          </span>
                        </div>
                      </React.Fragment>
                    )
                )}
              <div className="linhaCinzenta" style={{ marginTop: "50px" }}>
                Finalizados
              </div>
              {planoTratamento &&
                planoTratamento.procedimentos.map(
                  (p, index) =>
                    p.procedimento_completo && (
                      <React.Fragment key={index}>
                        <span className="executarProcedimento__step1__lineItem executarProcedimento__step1__lineItem--1st">
                          {p.tratamento.designacao}
                        </span>
                        <span className="executarProcedimento__step1__lineItem">
                          {p.dente}
                        </span>
                        <span className="executarProcedimento__step1__lineItem">
                          {p.faces.join(" ,")}
                        </span>

                        <span className="executarProcedimento__step1__lineItem">
                          {p.sessoes.length}
                        </span>
                        <div className="executarProcedimento__step1__procedimento__container executarProcedimento__step1__lineItem">
                          <span
                            className="
                        executarProcedimento__step1__procedimento executarProcedimento__step1__procedimento-completo"
                          >
                            <DoneIcon className="executarProcedimento__step1__procedimento__icon" />{" "}
                            Completo
                          </span>
                        </div>

                        <div className="executarProcedimento__step1__lineItem executarProcedimento__step1__btnContainer">
                          <span
                            className="executarProcedimento__step1__btn-executar cancelarButton"
                            onClick={handleCancelarProcedimento.bind(
                              null,
                              p.id
                            )}
                          >
                            Cancelar
                          </span>
                        </div>
                      </React.Fragment>
                    )
                )}
            </div>
          )}

          {step === "2" && (
            <div className="executarProcedimento__step2">
              {procedimento && (
                <div className="bottom-orcamento caixa">
                  {notificacaoEmStep2 && (
                    <div className="notificacaoEmStep2">
                      {notificacaoEmStep2}
                    </div>
                  )}
                  <div className="executarProcedimento__container">
                    <span className="executarProcedimento__container__item--header">
                      Tratamento
                    </span>
                    <span className="executarProcedimento__container__item--header">
                      Dentes/Região
                    </span>

                    <span className="executarProcedimento__container__item--header">
                      Faces
                    </span>

                    <span className="executarProcedimento__container__item">
                      {procedimento.tratamento.designacao}
                    </span>
                    <span className="executarProcedimento__container__item">
                      {procedimento.dente}
                    </span>

                    <span className="executarProcedimento__container__item">
                      {procedimento.faces.join(", ")}
                    </span>

                    <textarea
                      className="executarProcedimento__container__textarea"
                      cols="30"
                      rows="5"
                      value={evolucao}
                      placeholder="Escreva aqui a evolução do procedimento."
                      onChange={(e) => {
                        e.target.value
                          ? setEvolucao(e.target.value)
                          : setEvolucao("");
                      }}
                    />

                    <div className="listaEvolucoesContainer">
                      {procedimento.sessoes.map((sessao, index) => (
                        <div className="evolucoesPassadasContainer" key={index}>
                          <span>
                            Evolução da sessão nr {index + 1} - das{" "}
                            <span>
                              {moment(sessao.data_inicio_execucao).format(
                                "DD-MM-YYYY/HH:mm"
                              )}{" "}
                              às{" "}
                              {moment(sessao.data_fim_execucao).format(
                                "DD-MM-YYYY/HH:mm"
                              )}
                            </span>
                          </span>
                          <textarea
                            defaultValue={sessao.evolucao}
                            readOnly
                            cols="30"
                            rows="5"
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  {step === "2" && (
                    <div className="botoes">
                      <button className="cancel-btn" onClick={handleVoltar}>
                        Voltar
                      </button>

                      <button
                        className="blue-button"
                        onClick={handleGuardar}
                        disabled={isSubmiting}
                      >
                        {isSubmiting ? "Guardando" : "Guardar"}
                      </button>

                      <button
                        disabled={isSubmiting}
                        className="blue-button"
                        onClick={preSubmitHandler}
                      >
                        {isSubmiting ? "Finalizando" : "Finalizar"}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ExecutarPlanoTratamento;
