import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"; //gerar Orcamento
import toast from "react-hot-toast";

import { useLoading } from "../../../shared/context/LoadingContext";

import "../../../shared/css/PlanoAvaliacaoOrcamento.scss";
import "../../../style/formError.scss";
import "./EditOrcamento.scss";
import ElementoOrcamentoEdit from "../components/Elemento/ElementoOrcamentoEdit";

const DENTES = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "51",
  "52",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "71",
  "72",
  "73",
  "74",
  "75",
  "81",
  "82",
  "83",
  "84",
  "85",
  "Arcada Superior",
  "Arcada Inferior",
  "Arcadas",
];

function EditOrcamento() {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orcamento, setOrcamento] = useState();
  const [avaliacao, setAvaliacao] = useState();
  const [serviceList, setServiceList] = useState();

  const { sendRequest } = useHttpClient();
  const orcamentoId = useParams().orcamentoId;
  const auth = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrcamento = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/${orcamentoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          const responseData2 = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/${responseData.orcamento.avaliacao._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setAvaliacao(responseData2.avaliacao);
          setOrcamento(responseData.orcamento);

          const tempServiceList = responseData.orcamento.procedimentos.map(
            (pr) => ({
              ...pr,
              dente: pr.dente
                ? pr.dente.trim().replace(/,\s+/g, ",").split(",")
                : [],
              faces: pr.faces,
              tratamento: pr.tratamento,
              erro: "",
            })
          );

          setServiceList(tempServiceList);
        } catch (err) {
          console.error("err", err);
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchOrcamento();
  }, [sendRequest, orcamentoId, auth.token]);

  const submitHandler = async () => {
    startLoading();
    try {
      //////////////Orcamento///////////////
      ////////////////////////////////////
      let erroServiceList = "";
      serviceList.forEach((service) => {
        if (service.erro) {
          erroServiceList = service.erro;
        }
      });
      if (!erroServiceList) {
        const procedimentosOrcamento = [];
        const procedimentosAvaliacao = [];

        serviceList.forEach((service) => {
          const forBoth = {
            tratamento: service.tratamento._id,
            dente: service.tratamento.multiDentes
              ? service.dente.join(", ")
              : service.dente[0],
            faces: service.faces,
          };

          procedimentosOrcamento.push({
            ...forBoth,
            id: service.id,
            _id: service._id,
            quantidade: service.quantidade,
            pr_total: service.pr_total,
            iva: service.iva,
          });

          procedimentosAvaliacao.push({
            ...forBoth,
            _id: service.id,
            orcamentado: true,
          });
        });

        //Acrescentar os outros procedimentos na array da avaliacao que não
        //estão presentes nesse orçamento
        avaliacao.procedimentos.forEach((pa) => {
          if (!procedimentosOrcamento.some((po) => po.id === pa._id)) {
            procedimentosAvaliacao.push({
              ...pa,
              tratamento: pa.tratamento._id,
            });
          }
        });

        try {
          const formData = new FormData();

          formData.append(
            "procedimentos",
            JSON.stringify(procedimentosOrcamento)
          );

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/editarprocedimentos/${orcamento._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        try {
          //Atualizando os itens orcamentos na avaliacao
          const formData2 = new FormData();
          formData2.append(
            "procedimentos",
            JSON.stringify(procedimentosAvaliacao)
          );
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/editarprocedimentos/${avaliacao.id}`,
            "PATCH",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        toast.success("Orçamento e Avaliação editados com sucesso!");
        navigate(`../../../clientes/${orcamento.cliente.id}-orcamento`);
      } else {
        toast.error(erroServiceList);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  const dentesChangeHandler = (id, dentes, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].dente = list[indice].tratamento.multiDentes
      ? dentes
      : [dentes.toString()];
    list[indice].erro = erro;
    setServiceList(list);
  };

  const facesChangeHandler = (id, faces, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].faces = faces;
    list[indice].erro = erro;
    setServiceList(list);
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {orcamento && (
            <Navbar
              title="Orçamento"
              icon={ArticleOutlinedIcon}
              paths={[
                {
                  nome: orcamento.cliente.nome,
                  link: `../../../clientes/${orcamento.cliente.id}-orcamento`,
                },
                { nome: "Editar" },
              ]}
            />
          )}

          {serviceList && orcamento && (
            <div className="bottom-orcamento caixa">
              <div className="editorcamento__item editorcamento__item--headerContainer">
                <span
                  className="editorcamento__item--header"
                  style={{ textAlign: "left" }}
                >
                  Tratamento
                </span>
                <span className="editorcamento__item--header">Dentes</span>
                <span className="editorcamento__item--header">Faces</span>
              </div>

              {serviceList.map((singleService, i) => (
                <div key={i} className="editorcamento__item">
                  <ElementoOrcamentoEdit
                    //single service data
                    dentesTratados={singleService.dente}
                    faces={singleService.faces}
                    tratamento={singleService.tratamento}
                    nrDentes={singleService.nrDentes}
                    dentesChangeHandler={dentesChangeHandler}
                    facesChangeHandler={facesChangeHandler}
                    //outros
                    id={singleService.id}
                    show={singleService.show}
                    dentes={DENTES}
                  />
                </div>
              ))}

              <div className="botoes">
                <button
                  className="cancel-btn"
                  onClick={() =>
                    navigate(
                      `../../../clientes/${orcamento.cliente.id}-orcamento`
                    )
                  }
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={submitHandler.bind(null)}
                >
                  {isSubmitting ? "Editando..." : "Guardar"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditOrcamento;
