//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalMotivoCancelamento.scss";

const motivosCancelamento = [
  { cod: 2, description: "Retificação - Art.º 65, n.º 2 CIVA" },
  { cod: 3, description: "Retificação - Art.º 65, n.º 3 CIVA" },
  { cod: 6, description: "Retificação - Art.º 65, n.º 6 CIVA" },
  { cod: 7, description: "Retificação - Art.º 65, n.º 7 CIVA" },
  { cod: 8, description: "Retificação - Art.º 65, n.º 8 CIVA" },
  { cod: 9, description: "Retificação - Art.º 65, n.º 9 CIVA" },
  { cod: 0, description: "Outro" },
];

function ModalMotivoCancelamento({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimento,
}) {
  const [motivoCancelamento, setMotivoCancelamento] = useState(
    motivosCancelamento[0]
  );
  const [erro, setErro] = useState("");
  const [outro, setOutro] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const motivosCancelamentoChangeHandler = (e) => {
    const selectedMotivo = motivosCancelamento.find(
      (m) => m.cod === parseInt(e.target.value)
    );
    setMotivoCancelamento(selectedMotivo);
  };

  const beforeSubmitHandler = () => {
    if (
      motivoCancelamento.cod === 0 &&
      motivoCancelamento.description.trim().length < 10
    )
      setErro(
        `Motivo de emissão da ${modalData.tipoDocumento} teve ter 10 ou mais caracteres!`
      );
    else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);

    if (modalData.idContaCorrente) {
      if (motivoCancelamento.cod === 0) {
        handleCancelarRecebimento(
          modalData.id,
          modalData.idConta,
          modalData.idContaCorrente,
          {
            cod: motivoCancelamento.cod,
            description: outro.trim(),
          },
          modalData.tipoDocumento
        );
      } else {
        handleCancelarRecebimento(
          modalData.id,
          modalData.idConta,
          modalData.idContaCorrente,
          motivoCancelamento,
          modalData.tipoDocumento
        );
      }
    } else {
      if (motivoCancelamento.cod === 0) {
        handleCancelarRecebimento(
          modalData.id,
          modalData.idConta,
          {
            cod: motivoCancelamento.cod,
            description: outro.trim(),
          },
          modalData.tipoDocumento
        );
      } else {
        handleCancelarRecebimento(
          modalData.id,
          modalData.idConta,
          motivoCancelamento,
          modalData.tipoDocumento
        );
      }
    }
    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Motivo emissão de {modalData.tipoDocumento}
          </h1>

          <div className="motivoCancelamento__modal__container">
            <select
              onChange={motivosCancelamentoChangeHandler}
              className="motivoCancelamento__modal__select"
            >
              {motivosCancelamento.map((m) => (
                <option key={m.cod} value={m.cod}>
                  {m.description}
                </option>
              ))}
            </select>

            {motivoCancelamento.cod === 0 && (
              <textarea
                className="motivoCancelamento__modal__textarea"
                cols={4}
                rows={4}
                onChange={(e) => setOutro(e.target.value)}
                value={outro}
                placeholder={`Descrição do motivo de emissão da ${modalData.tipoDocumento}`}
              />
            )}
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>

          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>

            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoCancelamento;
