import { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../../shared/context/auth-context";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";

//Mui icons
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

import "../Anamnese.scss";
import "../../../../../shared/css/Checkbox.scss";
import { useEffect } from "react";

function EditAnamnese() {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [showAlergiaInput, setShowAlergiaInput] = useState(false);
  const [showDoencas, setShowDoencas] = useState(false);
  const { sendRequest } = useHttpClient();
  const [anamneseData, setAnamneseData] = useState();
  const [cuidadosMedicos, setCuidadosMedicos] = useState();
  const [gravidez, setGravidez] = useState();
  const [hospitalizado, setHospitalizado] = useState();
  const [showMedicamentos, setShowMedicamentos] = useState();
  const [medicamentos, setMedicamentos] = useState("");
  const [alergias, setAlergias] = useState("");
  const [doencas, setDoencas] = useState("");
  const [estadoGeral, setEstadoGeral] = useState("");

  const tempinfos = useParams().infos.split("-");
  const anamneseId = tempinfos[0];
  const clienteId = tempinfos[1];
  const nomeCliente = `${tempinfos[2]} ${tempinfos[3]}`;
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  //REFS

  const handleCancel = () => {
    navigate(`/clientes/${clienteId}`);
  };

  useEffect(() => {
    const fetchAnamnese = async () => {
      startLoading();
      try {
        let response;
        try {
          response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/anamneses/${anamneseId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        setCuidadosMedicos(response.anamnese.sobCuidadosMedicos);
        setHospitalizado(response.anamnese.foiHospitalizado);
        response.anamnese.medicamentos &&
          setMedicamentos(response.anamnese.medicamentos);
        response.anamnese.alergias && setAlergias(response.anamnese.alergias);
        response.anamnese.doencas && setDoencas(response.anamnese.doencas);
        setEstadoGeral(response.anamnese.estadoGeralSaude);
        setGravidez(response.anamnese.gravidez);
        setAnamneseData(response.anamnese);

        if (response.anamnese.medicamentos) setShowMedicamentos(true);
        else setShowMedicamentos(false);

        if (response.anamnese.alergias) setShowAlergiaInput(true);
        else setShowAlergiaInput(false);

        if (response.anamnese.doencas) setShowDoencas(true);
        else setShowDoencas(false);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchAnamnese();
  }, [sendRequest, anamneseId, auth.token]);

  const onSubmit = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("atualizadoPor", auth.userId);
      formData.append("estadoGeralSaude", estadoGeral);
      formData.append("sobCuidadosMedicos", cuidadosMedicos);
      if (typeof gravidez === "boolean") formData.append("gravidez", gravidez);
      formData.append("foiHospitalizado", hospitalizado);
      if (showDoencas) formData.append("doencas", doencas);
      if (showAlergiaInput) formData.append("alergias", alergias);
      if (showMedicamentos) formData.append("medicamentos", medicamentos);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/anamneses/${anamneseId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Anamnese editada com sucesso!");
        handleCancel();
      } catch (err) {
        console.error("err", err);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmiting(false);
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    setIsSubmiting(true);
    onSubmit();
  };

  return (
    <>
      <div className="new-anamnese">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Anamnese"
            icon={ArticleOutlinedIcon}
            paths={[
              {
                nome: nomeCliente,
                link: `../../../clientes/${clienteId}`,
              },
              { nome: "Editar" },
            ]}
          />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/medica-ficha.png" alt="" />
            </div>
            <div className="bottom-right">
              <div className="form-input takes1-7">
                <textarea
                  rows="4"
                  cols="50"
                  type="textarea"
                  placeholder="Estado geral de saúde"
                  value={estadoGeral}
                  onChange={(e) => setEstadoGeral(e.target.value)}
                  style={{ resize: "none" }}
                />
              </div>

              <div className="bottom-right-with-grid">
                {typeof cuidadosMedicos === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">
                        Sob cuidados médicos?
                      </span>
                      <input
                        type="checkbox"
                        id="cuidadosMedicos"
                        checked={cuidadosMedicos}
                        onChange={(e) => setCuidadosMedicos(e.target.checked)}
                      />
                      <label htmlFor="cuidadosMedicos" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {anamneseData && typeof anamneseData.gravidez === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">Gravidez?</span>
                      <input
                        type="checkbox"
                        id="showGravidez"
                        checked={gravidez}
                        onChange={(e) => setGravidez(e.target.checked)}
                      />
                      <label htmlFor="showGravidez" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {typeof hospitalizado === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">Foi hospitalizado?</span>
                      <input
                        type="checkbox"
                        id="hospitalizado"
                        checked={hospitalizado}
                        onChange={(e) => setHospitalizado(e.target.checked)}
                      />
                      <label htmlFor="hospitalizado" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {typeof showMedicamentos === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">
                        Tomando medicamentos?
                      </span>
                      <input
                        type="checkbox"
                        id="showMedicamentos"
                        checked={showMedicamentos}
                        onChange={(e) => setShowMedicamentos(e.target.checked)}
                      />
                      <label htmlFor="showMedicamentos" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {typeof showAlergiaInput === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">Alergias?</span>
                      <input
                        type="checkbox"
                        id="showAlergia"
                        checked={showAlergiaInput}
                        onChange={(e) => setShowAlergiaInput(e.target.checked)}
                      />
                      <label htmlFor="showAlergia" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {typeof showDoencas === "boolean" && (
                  <div className="formGroup two-rows">
                    <div className="checkbox-wrapper-51">
                      <span className="checkbox-text">Teve Doença grave?</span>
                      <input
                        type="checkbox"
                        id="showDoencaGrave"
                        checked={showDoencas}
                        onChange={(e) => setShowDoencas(e.target.checked)}
                      />
                      <label htmlFor="showDoencaGrave" className="toggle">
                        <span>
                          <svg width="10px" height="10px" viewBox="0 0 10 10">
                            <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                          </svg>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {showDoencas && (
                  <div className="form-input two-rows row-3">
                    <input
                      id="doencas"
                      name="doencas"
                      type="text"
                      placeholder="1333333"
                      value={doencas}
                      onChange={(e) => setDoencas(e.target.value)}
                    />
                    <label className="label" htmlFor="doencas">
                      Doenças
                    </label>
                  </div>
                )}

                {showAlergiaInput && (
                  <div className="form-input two-rows row-3">
                    <input
                      id="alergias"
                      name="alergias"
                      type="text"
                      placeholder="1333333"
                      value={alergias}
                      onChange={(e) => setEstadoGeral(e.target.value)}
                    />
                    <label className="label" htmlFor="alergias">
                      Alergias
                    </label>
                  </div>
                )}

                {showMedicamentos && (
                  <div className="form-input two-rows row-3">
                    <input
                      id="medicamentos"
                      name="medicamentos"
                      type="text"
                      placeholder="1333333"
                      value={medicamentos}
                      onChange={(e) => setMedicamentos(e.target.value)}
                    />
                    <label className="label" htmlFor="medicamentos">
                      Medicamentos
                    </label>
                  </div>
                )}

                <div className="botoes">
                  <button
                    type="submit"
                    className="blue-button"
                    disabled={isSubmitting}
                    onClick={preSubmitHandler}
                  >
                    Guardar
                  </button>
                  <button className="cancel-btn botao" onClick={handleCancel}>
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAnamnese;
