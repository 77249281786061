import { useState, useEffect, useCallback } from "react";
import ListAvaliacao from "./Lists/ListAvaliacao/ListAvaliacao";
import moment from "moment/moment";
import { useContext } from "react";
import { AuthContext } from "../../../../shared/context/auth-context";
import ModalVerAvaliacoes from "../ModalVerAvaliacoes";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./AvaliacoesComponent.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";

function AvaliacoesComponent({ clienteId, sendRequest, token }) {
  const { startLoading, stopLoading } = useLoading();
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [openModalAvaliacao, setOpenModalAvaliacao] = useState(false);
  const [procedimentosAvaliacao, setProcedimentosAvaliacao] = useState();
  const [observacaoAvaliaco, setObservacaoAvaliacao] = useState("");
  const auth = useContext(AuthContext);

  //Modal Code
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleOpenModal = useCallback(
    (id) => {
      const avaliacaoFiltrada = avaliacoes.filter((av) => av.id === id)[0];
      setModalData(avaliacaoFiltrada);
      setOpenModal(true);
    },
    [avaliacoes]
  );

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData([]);
  };

  const handleCloseModalAvaliacao = () => {
    setOpenModalAvaliacao(false);
    setProcedimentosAvaliacao([]);
    setObservacaoAvaliacao("");
  };
  ///////////////////////////////////////

  useEffect(() => {
    const fetchAvaliacoesByCliente = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/cliente/comtratamento/${clienteId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const temp = responseData.avaliacoes.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        const avaliacoes_com_data_avaliacao = temp.map((avaliacao) => {
          const data_avaliacao = moment(avaliacao.created_at).format(
            "DD-MM-YYYY"
          );
          return (avaliacao = {
            ...avaliacao,
            data_avaliacao: data_avaliacao,
          });
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let tempAvaliacoes = [];

        if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
          tempAvaliacoes = avaliacoes_com_data_avaliacao.filter(
            (av) => av.medico_responsavel.id === auth.userId
          );
        } else if (
          auth.role === "Secretária(o)" ||
          auth.role === "Assistente"
        ) {
          avaliacoes_com_data_avaliacao.forEach((av) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === av.medico_responsavel.id)
                tempAvaliacoes.push(av);
            });
          });
        } else if (auth.role === "Administrador Não Médico") {
          tempAvaliacoes = [...avaliacoes_com_data_avaliacao];
        }
        setAvaliacoes(tempAvaliacoes);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchAvaliacoesByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleDesativar = useCallback(
    async (id) => {
      startLoading();
      try {
        const formDataAvaliacao = new FormData();
        formDataAvaliacao.append("desativadoPor", auth.userId);
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/desativar/${id}`,
          "PATCH",
          formDataAvaliacao,
          {
            Authorization: "Bearer " + token,
          }
        );
        let tempUpdatedAvaliacoes = avaliacoes.filter((el) => el.id !== id);
        setAvaliacoes(tempUpdatedAvaliacoes);

        toast.success("Desativação de Avaliacao efetuada com sucesso!");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [avaliacoes, token, sendRequest]
  );

  const handleFecharAbrir = useCallback(
    async (id) => {
      startLoading();
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes/fecharabrir/${id}`,
          "PATCH",
          {},
          {
            Authorization: "Bearer " + token,
          }
        );
        let avalSelecionada;
        let tempUpdatedAvaliacoes = avaliacoes.map((el) => {
          if (el.id === id) {
            avalSelecionada = { ...el };
            return {
              ...el,
              fechado:
                el.fechado === undefined || el.fechado === false ? true : false,
            };
          } else {
            return el;
          }
        });
        setAvaliacoes(tempUpdatedAvaliacoes);
        toast.success(
          avalSelecionada.fechado === undefined ||
            avalSelecionada.fechado === false
            ? "Avaliação fechada com sucesso!"
            : "Avaliação aberta com sucesso!"
        );
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    },
    [avaliacoes, token, sendRequest]
  );

  const handleOpenModalAvaliacao = (idAvaliacao) => {
    const tempAvaliacao = avaliacoes.filter((av) => av.id === idAvaliacao)[0];
    setProcedimentosAvaliacao(tempAvaliacao.procedimentos);
    setObservacaoAvaliacao(tempAvaliacao.observacoes);
    setOpenModalAvaliacao(true);
  };

  return (
    <>
      <ModalVerAvaliacoes
        openModal={openModalAvaliacao}
        handleCloseModal={handleCloseModalAvaliacao}
        procedimentos={procedimentosAvaliacao}
        observacao={observacaoAvaliaco}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box avaliacoes__modal">
          <h1 className="fluxo-atendimento__modal__titulo">Avaliação</h1>
          <span className="fluxo-atendimento__modal__designacao">
            Tratamento
          </span>
          <span className="fluxo-atendimento__modal__designacao">
            Dentes/Região
          </span>
          <span className="fluxo-atendimento__modal__designacao">Faces</span>
          {modalData.procedimentos &&
            modalData.procedimentos.map((proc) => (
              <>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.tratamento.designacao}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.dente}
                </span>
                <span className="fluxo-atendimento__modal__cell">
                  {proc.faces.join(", ")}
                </span>
              </>
            ))}
          {modalData.observacoes && (
            <>
              <span className="fluxo-atendimento__modal__observacao-titulo">
                Observação
              </span>
              <textarea
                className="fluxo-atendimento__modal__textarea"
                cols="30"
                rows="4"
                readOnly
              >
                {modalData.observacoes}
              </textarea>
            </>
          )}
          <span className="cancel-btn" onClick={() => setOpenModal(false)}>
            Fechar
          </span>
        </Box>
      </Modal>

      {avaliacoes.length !== 0 && (
        <>
          <ListAvaliacao
            data={avaliacoes}
            handleOpenModal={handleOpenModal}
            handleDesativar={handleDesativar}
            handleOpenModalAvaliacao={handleOpenModalAvaliacao}
            handleFecharAbrir={handleFecharAbrir}
          />
        </>
      )}
      {avaliacoes.length === 0 && (
        <div className="no_data_div">Nenhuma Avaliação encontrada.</div>
      )}
    </>
  );
}

export default AvaliacoesComponent;
