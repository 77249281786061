import { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "./PdfOrcamentoStyle.scss";
import moment from "moment/moment";

function PdfOrcamentoParticular({ orcamentoData, showSingleP }) {
  let subTotal = 0;
  orcamentoData.serviceList.forEach((el) => {
    subTotal += el.tratamento.preco_particular;
  });

  const total = subTotal - orcamentoData.desconto;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Orçamento-${orcamentoData.cliente.nome}_${moment(
      new Date()
    ).format("DD-MM-YYYY")}`,
    onAfterPrint: () => {
      showSingleP();
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePrint();
  }, [handlePrint]);

  orcamentoData.serviceList.forEach((sl) => {
    if (!sl.faces) {
      sl = { ...sl, faces: "" };
    }
  });

  return (
    <>
      <div ref={componentRef} className="pdf-orcamento-wrapper">
        <div className="pdf-orcamento-cabecalho">
          <div className="pdf-orcamento-cabecalho-left">
            <img
              src={`${process.env.REACT_APP_BACKEND_LINK}/${orcamentoData.clinica.logotipo}`}
              alt=""
            />
          </div>

          <div className="pdf-orcamento-cabecalho-right">
            <span>{orcamentoData.clinica.nome}</span>
            <span>
              {orcamentoData.medico_responsavel.genero === "masculino"
                ? "Dr. "
                : "Dra. "}
              {orcamentoData.medico_responsavel.name}/
              {orcamentoData.medico_responsavel.profissao}
            </span>
            <span>Telef: {orcamentoData.clinica.contacto}</span>
            <span>{orcamentoData.clinica.endereco}</span>
            <span>NIF: {orcamentoData.clinica.nif}</span>
          </div>
        </div>
        <div className="pdf-orcamento-dadosEOdontograma">
          <div className="pdf-orcamento-outrosDados">
            <h1 className="pdf-orcamento-outrosDados__titulo">
              Prescrição/Orçamento Estomatológico
            </h1>
            <hr className="pdf-orcamento-outrosDados__line" />
            <div className="pdf-orcamento-outrosDados__dadosDuplos">
              <div className="pdf-orcamento-outrosDados__dadosSimples">
                <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                  Nº:{" "}
                </span>
                <span>{`${
                  orcamentoData.numero
                }/${new Date().getFullYear()}`}</span>
              </div>
              <div className="pdf-orcamento-outrosDados__dadosSimples">
                <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                  DATA:{" "}
                </span>
                <span>{moment().format("DD/MM/YYYY")}</span>
              </div>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                NOME DO PACIENTE:{" "}
              </span>
              <span>{orcamentoData.cliente.nome}</span>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                NIF:{" "}
              </span>
              <span>
                {orcamentoData.cliente.nif ? orcamentoData.cliente.nif : ""}
              </span>
            </div>
            <div className="pdf-orcamento-outrosDados__dadosSimples">
              <span className="pdf-orcamento-outrosDados__cabecalhoBold">
                CATEGORIA DE BENEFICIÁRIO:{" "}
              </span>
              <span>Particular</span>
            </div>
            <hr className="pdf-orcamento-outrosDados__line" />
          </div>
          <div className="odontograma-container">
            <img src="/images/odontograma.png" alt="" />
          </div>
        </div>
        <div className="divTabela">
          <table>
            <tbody>
              <tr className="headingLine">
                <th className="pdf-orc-left">Designação</th>
                <th>Qtd.</th>
                <th className="pdf-orc-center">Dentes</th>
                <th>Pr. Unit.</th>
              </tr>
              {orcamentoData.serviceList.map((el, i) => (
                <tr
                  className={`${
                    i === 0 ? "primeiraLinha normalLine" : "normalLine"
                  }`}
                  key={i}
                >
                  <td className="pdf-orc-left">{el.tratamento.designacao}</td>
                  <td>1</td>
                  <td className="pdf-orc-center">{el.dente}</td>

                  <td>
                    {Intl.NumberFormat("pt-BR").format(
                      el.tratamento.preco_particular
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="orcamento-rodape orcamento-rodape--particular">
          <hr className="orcamento-rodape__linha" />
          <div className="orcamento-rodape--particular--left"></div>
          <div className="orcamento-rodape--particular--right">
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                SubTotal Orçamento
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(subTotal)}
              </span>
            </div>
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                Desconto
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(orcamentoData.desconto)}
              </span>
            </div>
            <div className="orcamento-rodape__div">
              <span className="orcamento-rodape--right__lineTitle">
                Total Orçamento
              </span>
              <span className="valor_65px">
                {Intl.NumberFormat("pt-BR").format(total)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PdfOrcamentoParticular;
