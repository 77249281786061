import { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./NewTaksModal.scss";
import moment from "moment";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
function ModalViewTask({ open, handleCloseModal, auth, sendRequest, taskId }) {
  const { startLoading, stopLoading } = useLoading();
  const [dadosTask, setDadosTask] = useState();

  useEffect(() => {
    const fetchTask = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/tasks/${taskId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setDadosTask(responseData.task);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchTask();
  }, [taskId, sendRequest, auth.token]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">Ver Tarefa</h1>
        {dadosTask && (
          <div className="newTaskContainer">
            <div
              className={`customInputContainer ${
                auth.perm.includes("c-tar") ? "takes4" : "takes5"
              }`}
            >
              <input
                type="text"
                className="customInput"
                readOnly
                value={dadosTask.titulo}
              />
              <label className="customInputLabel">Título*</label>
            </div>

            {auth.perm.includes("c-tar") && (
              <div className="customInputContainer takes2">
                <input
                  type="text"
                  className="customInput"
                  readOnly
                  value={dadosTask.delegatario.name}
                />
                <label className="customInputLabel">Delegatário*</label>
              </div>
            )}

            <div className="customInputContainer takes2">
              <input
                type="text"
                className="customInput"
                readOnly
                value={moment(dadosTask.data).format("DD-MM-YYYY")}
              />
              <label className="customInputLabel">Data*</label>
            </div>

            <div className="customInputContainer takesAll">
              <textarea
                rows={5}
                readOnly
                className="customInputTextarea"
                value={dadosTask.descricao}
              />
              <label className="customInputLabelSelect">Descrição*</label>
            </div>
          </div>
        )}

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span className="cancel-btn" onClick={handleCloseModal}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default memo(ModalViewTask);
