import React, { Suspense, lazy } from "react";

//Dashboard
import HomeMedicoAdministrador from "./pages/home/medicoAdministrador/HomeMedicoAdministrador";
import HomeDentista from "./pages/home/dentista/HomeDentista";
import HomeSecretaria from "./pages/home/secretaria/HomeSecretaria";
import HomeAdministrativo from "./pages/home/administrativo/HomeAdministrativo";
import HomeAssistente from "./pages/home/assistente/HomeAssistente";

// CLientes
import New from "./pages/clientes/new/New";
import Clientes from "./pages/clientes/clientes/Clientes";
import Single from "./pages/clientes/single/Single";
import Edit from "./pages/clientes/edit/Edit";

// Funcionarios
import NewFuncionario from "./pages/funcionarios/new/New";
import Funcionarios from "./pages/funcionarios/funcionarios/Funcionarios";
import EditFuncionario from "./pages/funcionarios/edit/Edit";

// Anamnese
import NewAnamnese from "./pages/clientes/components/anamnese/new/NewAnamnese";
import EditAnamnese from "./pages/clientes/components/anamnese/edit/EditAnamnese";

// Tratamentos
import NewTratamento from "./pages/tratamentos/new/NewTratamento";
import Tratamentos from "./pages/tratamentos/tratamentos/Tratamentos";
import EditTratamento from "./pages/tratamentos/edit/EditTratamento";

// Plano de Tratamento
import ExecutarPlanoTratamento from "./pages/planoTratamento/executar/ExecutarPlanoTratamento";

// Avaliacao
import NewAvaliacao from "./pages/avaliacoes/new/NewAvaliacao";
import EditAvaliacao from "./pages/avaliacoes/edit/EditAvaliacao";

// Orcamentos
import NewOrcamento from "./pages/orcamentos/new/NewOrcamento";
import EditOrcamento from "./pages/orcamentos/edit/EditOrcamento";

// Agendamentos
import Agendamentos from "./pages/agendamentos/agendamentos/Agendamentos";

// Configuracoes
import Configuracoes from "./pages/configuracoes/Configuracoes";

//Financeiro
import Financeiro from "./pages/financeiro/Financeiro";
import Relatorios from "./pages/relatorios/Relatorios";

//USERS
import Users from "./pages/users/Users";
// import EditUser from "./pages/users/edit/EditUser";
import SingleUser from "./pages/users/single/SingleUser";
// import NewUser from "./pages/users/new/NewUser";

//Prescricoes
import NewPrescricao from "./pages/prescricoes/new/NewPrescricao";

//Prescricoes
import Stock from "./pages/stock/Stock";

////////////////////////////////
import "./style/global.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Auth from "./pages/users/auth/Auth";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

import PageNotFound from "./pages/pageNotFound/PageNotFound";

import { ClinicaContext } from "./shared/context/clinica-context";
import HomeAdministradorNaoMedico from "./pages/home/administradorNaoMedico/HomeAdministradorNaoMedico";

import NewImpresso from "./pages/impressos/new/NewImpresso";

import { LoadingProvider } from "./shared/context/LoadingContext";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import AgendamentosViewOnly from "./pages/agendamentos/agendamentos/AgendamentosViewOnly";

// Lazy loaded components
//Funcionarios lazy loaded
// const Funcionarios = lazy(() =>
//   import("./pages/funcionarios/funcionarios/Funcionarios")
// );
// const NewFuncionario = lazy(() => import("./pages/funcionarios/new/New"));
// const EditFuncionario = lazy(() => import("./pages/funcionarios/edit/Edit"));
// const VerFuncionario = lazy(() => import("./pages/funcionarios/ver/Ver"));

// //Users Lazy Loaded
// const Users = lazy(() => import("./pages/users/Users"));
// const EditUser = lazy(() => import("./pages/users/edit/EditUser"));
// const SingleUser = lazy(() => import("./pages/users/single/SingleUser"));
// const NewUser = lazy(() => import("./pages/users/new/NewUser"));

// //Anamnese Lazy Loaded
// const NewAnamnese = lazy(() =>
//   import("./pages/clientes/components/anamnese/new/NewAnamnese")
// );
// const EditAnamnese = lazy(() =>
//   import("./pages/clientes/components/anamnese/edit/EditAnamnese")
// );

// //Tratamentos Lazy Loaded
// const NewTratamento = lazy(() =>
//   import("./pages/tratamentos/new/NewTratamento")
// );
// const Tratamentos = lazy(() =>
//   import("./pages/tratamentos/tratamentos/Tratamentos")
// );
// const EditTratamento = lazy(() =>
//   import("./pages/tratamentos/edit/EditTratamento")
// );

// // Clincias Lazy Loaded
// const Clinicas = lazy(() => import("./pages/clinicas/clinicas/Clinicas"));
// const SingleClinica = lazy(() =>
//   import("./pages/clinicas/single/SingleClinica")
// );
// const EditClinica = lazy(() => import("./pages/clinicas/edit/EditClinica"));
// const NewClinica = lazy(() => import("./pages/clinicas/new/NewClinica"));

function App() {
  const {
    token,
    login,
    logout,
    userId,
    image,
    userRole,
    clinica,
    usuariosAssociados,
    secretariasDoMedico,
    assistentesDoMedico,
    permissoes,
    nome,
  } = useAuth();
  let routes;
  if (permissoes && token) {
    routes = (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/">
            {userRole === "Médico/Administrador" && (
              <Route index element={<HomeMedicoAdministrador />} />
            )}
            {userRole === "Administrador Não Médico" && (
              <Route index element={<HomeAdministradorNaoMedico />} />
            )}
            {userRole === "Dentista" && (
              <Route index element={<HomeDentista />} />
            )}
            {userRole === "Secretária(o)" && (
              <Route index element={<HomeSecretaria />} />
            )}
            {userRole === "Assistente" && (
              <Route index element={<HomeAssistente />} />
            )}
            {userRole === "Administrativo" && (
              <Route index element={<HomeAdministrativo />} />
            )}
            <Route path="auth" element={<Auth />} />
            {/* Rotas para Clientes */}
            {permissoes.includes("r-paCli") && (
              <Route path="clientes">
                <Route index element={<Clientes />} />

                {permissoes.includes("r-cli") && (
                  <Route path=":clienteId">
                    <Route index element={<Single />} />
                    {permissoes.includes("u-cli") && (
                      <Route path="edit" element={<Edit />} />
                    )}
                  </Route>
                )}
                {permissoes.includes("c-cli") && (
                  <Route
                    path="new"
                    element={<New title="Adicionar Novo Cliente" />}
                  />
                )}
              </Route>
            )}
            {/* Rotas para Funcionarios */}
            {permissoes.includes("r-paFun") && (
              <Route path="funcionarios">
                <Route index element={<Funcionarios />} />

                <Route path=":funcionarioId">
                  {/* <Route index element={<SingleFuncionario />} /> */}
                  {permissoes.includes("u-func") && (
                    <Route path="edit" element={<EditFuncionario />} />
                  )}
                </Route>
                {permissoes.includes("c-func") && (
                  <Route
                    path="new"
                    element={
                      <NewFuncionario title="Adicionar Novo Funcionario" />
                    }
                  />
                )}
              </Route>
            )}
            {/* Rotas para Financeiro */}
            {permissoes.includes("r-paFi") && (
              <Route path="financeiro">
                <Route index element={<Financeiro />} />
              </Route>
            )}

            <Route path="relatorios">
              <Route index element={<Relatorios />} />
            </Route>

            {/* Rotas para Anamnese */}
            <Route path="anamnese">
              {permissoes.includes("c-anam") &&
                (userRole === "Dentista" ||
                  userRole === "Médico/Administrador") && (
                  <Route path=":clienteId">
                    <Route
                      path="new"
                      element={<NewAnamnese title="Criar Anamnese" />}
                    />
                  </Route>
                )}
              {permissoes.includes("u-anam") &&
                (userRole === "Dentista" ||
                  userRole === "Médico/Administrador") && (
                  <Route path=":infos">
                    <Route
                      path="edit"
                      element={<EditAnamnese title="Editar Anamnese" />}
                    />
                  </Route>
                )}
            </Route>
            {/* Rotas para Tratamentos */}
            {permissoes.includes("r-paTra") && (
              <Route path="tratamentos">
                <Route index element={<Tratamentos />} />

                <Route path=":tratamentoId">
                  {permissoes.includes("u-tr") && (
                    <Route path="edit" element={<EditTratamento />} />
                  )}
                </Route>
                {permissoes.includes("c-tr") && (
                  <Route
                    path="new"
                    element={
                      <NewTratamento title="Adicionar Novo Tratamento" />
                    }
                  />
                )}
              </Route>
            )}
            {/* Rotas para Plano de Tratamento */}
            <Route path="planotratamento">
              {(userRole === "Dentista" ||
                userRole === "Médico/Administrador") && (
                <Route path="executar">
                  <Route
                    path=":planoTratamentoId"
                    element={<ExecutarPlanoTratamento />}
                  />
                </Route>
              )}
            </Route>
            {/* Rotas para Avaliacoes */}
            <Route path="avaliacao">
              {permissoes.includes("c-aval") &&
                (userRole === "Dentista" ||
                  userRole === "Médico/Administrador" ||
                  userRole === "Secretária(o)" ||
                  userRole === "Assistente") && (
                  <Route path="new">
                    <Route path=":clienteId" element={<NewAvaliacao />} />
                  </Route>
                )}
              {permissoes.includes("u-aval") &&
                (userRole === "Dentista" ||
                  userRole === "Médico/Administrador" ||
                  userRole === "Secretária(o)" ||
                  userRole === "Assistente") && (
                  <Route path="edit">
                    <Route path=":avaliacaoId" element={<EditAvaliacao />} />
                  </Route>
                )}
            </Route>
            {/* Rotas para Orçamentos */}
            <Route path="orcamentos">
              {permissoes.includes("c-orc") &&
                (userRole === "Dentista" ||
                  userRole === "Médico/Administrador" ||
                  userRole === "Secretária(o)" ||
                  userRole === "Assistente") && (
                  <>
                    <Route path="new">
                      <Route path=":avaliacaoId" element={<NewOrcamento />} />
                    </Route>
                    <Route path="edit">
                      <Route path=":orcamentoId" element={<EditOrcamento />} />
                    </Route>
                  </>
                )}
            </Route>
            {/* Rotas para Agendamentos */}
            {permissoes.includes("r-paAg") &&
              (userRole === "Médico/Administrador" ||
                userRole === "Dentista" ||
                userRole === "Secretária(o)") && (
                <Route path="agendamentos">
                  <Route path=":medicoId" element={<Agendamentos />} />
                </Route>
              )}

            {/* Agendamentos viewOnly */}
            {permissoes.includes("r-paAg") &&
              userRole === "Administrador Não Médico" && (
                <Route path="agendamentosviewonly">
                  <Route path=":medicoId" element={<AgendamentosViewOnly />} />
                </Route>
              )}

            {/* Rotas para Clinica */}
            {permissoes.includes("r-paClin") &&
              (userRole === "Administrador Não Médico" ||
                userRole === "Médico/Administrador") && (
                <Route path="configuracoes">
                  <Route path=":aba" element={<Configuracoes />} />
                  {/* <Route index element={<Configuracoes />} /> */}
                </Route>
              )}
            {/* Rotas para Prescricoes */}
            {permissoes.includes("c-pres") && (
              <Route path="prescricao">
                <Route path=":clienteId">
                  <Route index element={<NewPrescricao />} />
                </Route>
              </Route>
            )}
            {/* Rotas para Impressos */}
            <Route path="impresso">
              <Route path=":clienteId">
                <Route index element={<NewImpresso />} />
              </Route>
            </Route>
            {/* Rotas para Stocks */}
            {permissoes.includes("r-paSt") && (
              <Route path="stock">
                <Route index element={<Stock />} />
              </Route>
            )}
            {/* Rotas para Usuarios */}
            <Route path="users">
              {(userRole === "Médico/Administrador" ||
                userRole === "Administrador Não Médico") && (
                <Route index element={<Users />} />
              )}
              <Route path=":userId">
                <Route path="perfil" element={<SingleUser title="Usuário" />} />
                {/* {(userRole === "Médico/Administrador" ||
                  userRole === "Administrador Não Médico") && (
                  <Route
                    path="edit"
                    element={<EditUser title="Editar Usuário" />}
                  />
                )} */}
              </Route>
              {/* {(userRole === "Médico/Administrador" ||
                userRole === "Administrador Não Médico") && (
                <Route
                  path="new"
                  element={<NewUser title="Adicionar Novo Usuário" />}
                />
              )} */}
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    );
  } else {
    routes = (
      <Routes>
        <Route path="*" element={<Auth />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        image: image,
        login: login,
        logout: logout,
        role: userRole,
        usuariosAssociados: usuariosAssociados,
        secretariasDoMedico: secretariasDoMedico,
        assistentesDoMedico: assistentesDoMedico,
        perm: permissoes,
        nome: nome,
      }}
    >
      <ClinicaContext.Provider
        value={{
          clinica,
        }}
      >
        <LoadingProvider>
          <div className="app">
            <BrowserRouter>{routes}</BrowserRouter>
            <LoadingSpinner />
          </div>
        </LoadingProvider>
      </ClinicaContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
