import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./ModalAdicionarEvolucao.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";

function ModalAdicionarEvolucao({
  handleCloseModalEvolucao,
  open,
  dados,
  auth,
  sendRequest,
  handleModalSubmitSuccess,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const evolucaoRef = useRef(null);

  const submitHandler = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append(
        "evolucao",
        evolucaoRef.current.value
          ? evolucaoRef.current.value
          : `O procedimento ${dados.categoriaTratamento.categoria} foi executado com sucesso`
      );
      formData.append("data_inicio_execucao", dados.data_inicio_execucao);
      formData.append("data_fim_execucao", dados.data_fim_execucao);
      formData.append("medico", auth.userId);

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/adicionarevolucao/${dados.idCliente}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      const formData2 = new FormData();
      formData2.append("agendaId", dados.agendaId);
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/marcaratendido/${dados.id}`,
          "PATCH",
          formData2,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      toast.success("Evolução adicionada com sucesso");
      handleModalSubmitSuccess(dados.id);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const preSubmitHandler = () => {
    setIsSubmitting(true);
    submitHandler();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalEvolucao}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">
          Adicionar Evolução - {dados.nome}
        </h1>

        <div className="adicionarEvolucaoContainer">
          <textarea
            className="executarProcedimento__container__textarea"
            placeholder="Escreva aqui a evolução do procedimento."
            cols="30"
            rows="5"
            ref={evolucaoRef}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmitHandler}
            style={{ marginRight: "10px" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>

          <span className="cancel-btn" onClick={handleCloseModalEvolucao}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalAdicionarEvolucao;
