import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../../../shared/hooks/http-hook";
import moment from "moment";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./RelatoriosFInanceiro.scss";

function RelatoriosFinanceiro({ clinicaId, auth, dadosMapa }) {
  const { startLoading, stopLoading } = useLoading();
  const { sendRequest } = useHttpClient();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);
  const [dadosClinica, setDadosClinica] = useState();
  const [dataInicial, setDataInicial] = useState(moment().startOf("day"));
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [dataSelect, setDataSelect] = useState("Este mês");

  useEffect(() => {
    const fetchContasCorrentesEntradaDadosClinica = async () => {
      startLoading();
      try {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/${clinicaId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          const responseData3 = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/${clinicaId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          if (auth.perm.includes("r-rec")) {
            setContasCorrentesAbsolut(responseData.contasCorrentes);
          } else {
            setContasCorrentesAbsolut([]);
          }

          setEntradasAbsolut(responseData3.entradas);
        } catch (err) {
          console.error("err", err);
        }

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinicaId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setDadosClinica(responseData.clinica);
        } catch (err) {
          console.error("err", err);
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchContasCorrentesEntradaDadosClinica();
  }, [auth.perm, auth.token, clinicaId, sendRequest]);

  const exportarMapaHandler = () => {
    const dadosFiltrados = [];
    //Parcelas
    contasCorrentesAbsolut.forEach((c) => {
      c.contas.forEach((conta) => {
        conta.parcelas.forEach((p) => {
          if (
            p.documentoEletronico &&
            p.documentoEletronico.succeeded === true
          ) {
            //O documento eletronico
            if (
              moment(p.documentoEletronico?.created_at).isBetween(
                dataInicial,
                dataFinal,
                undefined,
                "[]"
              )
            ) {
              dadosFiltrados.push({
                nifEntidade: c.cliente.nif ? c.cliente.nif : "",
                entidade: c.cliente.nomeFiscal
                  ? c.cliente.nomeFiscal
                  : c.cliente.nome,
                serie: p.documentoEletronico.serie,
                tipoDocumento: p.documentoEletronico.tipoDocumento,
                nrDocumento: p.documentoEletronico.nrDocumento,
                data: moment(p.documentoEletronico.authorizedDateTime).format(
                  "DD/MM/YYYY"
                ),
                valor: p.quantiaPaga,
              });
            }

            //Adicionar tb os cancelamentos se esses existirem e estiverem no date range
            if (
              p.cancelamentosDocumentosEletronicos &&
              p.cancelamentosDocumentosEletronicos?.length > 0
            ) {
              console.log("p", p);
              p.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
                if (
                  moment(
                    cancelamento?.documentoCancelamento?.created_at
                  ).isBetween(dataInicial, dataFinal, undefined, "[]")
                ) {
                  //Se o cancelamento pertencer ao alcance da data, são adicionados os documentos de cancelamento
                  //e o documento cancelado à lista, mesmo que o documento cancelado não pertencer à data
                  //Para evitar descrepancias no valor final do mapa de vendas
                  dadosFiltrados.push({
                    nifEntidade: c.cliente.nif ? c.cliente.nif : "",
                    entidade: c.cliente.nomeFiscal
                      ? c.cliente.nomeFiscal
                      : c.cliente.nome,
                    serie: cancelamento.documentoCancelamento.serie,
                    tipoDocumento:
                      cancelamento.documentoCancelamento.tipoDocumento,
                    nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
                    data: moment(
                      cancelamento.documentoCancelamento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documentoCancelamento.valor
                      ? -cancelamento.documentoCancelamento.valor
                      : 0,
                  });

                  dadosFiltrados.push({
                    nifEntidade: c.cliente.nif ? c.cliente.nif : "",
                    entidade: c.cliente.nomeFiscal
                      ? c.cliente.nomeFiscal
                      : c.cliente.nome,
                    serie: cancelamento.documento.serie,
                    tipoDocumento: cancelamento.documento.tipoDocumento,
                    nrDocumento: cancelamento.documento.nrDocumento,
                    data: moment(
                      cancelamento.documento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documento.valor
                      ? cancelamento.documento.valor
                      : 0,
                  });
                }
              });
            }
          }
        });
      });
    });

    //Entradas
    entradasAbsolut.forEach((e) => {
      if (e.documentoEletronico && e.documentoEletronico.succeeded === true) {
        if (
          moment(e.documentoEletronico.created_at).isBetween(
            dataInicial,
            dataFinal,
            undefined,
            "[]"
          )
        ) {
          if (e.tipo === "reembolso") {
            dadosFiltrados.push({
              nifEntidade: e.cliente.nif ? e.cliente.nif : "",
              entidade: e.cliente.nomeFiscal
                ? e.cliente.nomeFiscal
                : e.cliente.nome,
              serie: e.documentoEletronico.serie,
              tipoDocumento: e.documentoEletronico.tipoDocumento,
              nrDocumento: e.documentoEletronico.nrDocumento,
              data: moment(e.documentoEletronico.authorizedDateTime).format(
                "DD/MM/YYYY"
              ),
              valor: e.valor,
            });

            //Adicionar tb os cancelamentos se esses existirem e estiverem no date range
            if (
              e.cancelamentosDocumentosEletronicos &&
              e.cancelamentosDocumentosEletronicos?.length > 0
            ) {
              e.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
                if (
                  moment(
                    cancelamento?.documentoCancelamento.created_at
                  ).isBetween(dataInicial, dataFinal, undefined, "[]")
                ) {
                  //Se o cancelamento pertencer ao alcance da data, são adicionados os documentos de cancelamento
                  //e o documento cancelado à lista, mesmo que o documento cancelado não pertencer à data
                  //Para evitar descrepancias no valor final do mapa de vendas
                  dadosFiltrados.push({
                    nifEntidade: e.cliente.nif ? e.cliente.nif : "",
                    entidade: e.cliente.nomeFiscal
                      ? e.cliente.nomeFiscal
                      : e.cliente.nome,
                    serie: cancelamento.documentoCancelamento.serie,
                    tipoDocumento:
                      cancelamento.documentoCancelamento.tipoDocumento,
                    nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
                    data: moment(
                      cancelamento.documentoCancelamento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documentoCancelamento.valor
                      ? -cancelamento.documentoCancelamento.valor
                      : 0,
                  });

                  dadosFiltrados.push({
                    nifEntidade: e.cliente.nif ? e.cliente.nif : "",
                    entidade: e.cliente.nomeFiscal
                      ? e.cliente.nomeFiscal
                      : e.cliente.nome,
                    serie: cancelamento.documento.serie,
                    tipoDocumento: cancelamento.documento.tipoDocumento,
                    nrDocumento: cancelamento.documento.nrDocumento,
                    data: moment(
                      cancelamento.documento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documento.valor
                      ? cancelamento.documento.valor
                      : 0,
                  });
                }
              });
            }
          } else {
            //Para entradas de clientes fornecedores temos 2 tipos de cancelamentos
            //Cancelamentos de recibos e cancelamentos de FTEs.
            dadosFiltrados.push({
              nifEntidade: e.clienteFornecedor.nif
                ? e.clienteFornecedor.nif
                : "",
              entidade: e.clienteFornecedor.nome
                ? e.clienteFornecedor.nome
                : "",
              serie: e.documentoEletronico.serie,
              tipoDocumento: e.documentoEletronico.tipoDocumento,
              nrDocumento: e.documentoEletronico.nrDocumento,
              data: moment(e.documentoEletronico.authorizedDateTime).format(
                "DD/MM/YYYY"
              ),
              valor: e.valor,
            });

            if (
              e.cancelamentosDocumentosEletronicos &&
              e.cancelamentosDocumentosEletronicos?.length > 0
            ) {
              e.cancelamentosDocumentosEletronicos.forEach((cancelamento) => {
                if (
                  moment(
                    cancelamento?.documentoCancelamento.created_at
                  ).isBetween(dataInicial, dataFinal, undefined, "[]")
                ) {
                  //Cancelamentos de FTES
                  dadosFiltrados.push({
                    nifEntidade: e.clienteFornecedor.nif
                      ? e.clienteFornecedor.nif
                      : "",
                    entidade: e.clienteFornecedor.nome
                      ? e.clienteFornecedor.nome
                      : "",
                    serie: cancelamento.serie,
                    tipoDocumento:
                      cancelamento.documentoCancelamento.tipoDocumento,
                    nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
                    data: moment(
                      cancelamento.documentoCancelamento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documentoCancelamento.valor
                      ? -cancelamento.documentoCancelamento.valor
                      : 0,
                  });

                  dadosFiltrados.push({
                    nifEntidade: e.clienteFornecedor.nif
                      ? e.clienteFornecedor.nif
                      : "",
                    entidade: e.clienteFornecedor.nome
                      ? e.clienteFornecedor.nome
                      : "",
                    serie: cancelamento.serie,
                    tipoDocumento: cancelamento.documento.tipoDocumento,
                    nrDocumento: cancelamento.documento.nrDocumento,
                    data: moment(
                      cancelamento.documento.authorizedDateTime
                    ).format("DD/MM/YYYY"),
                    valor: cancelamento.documento.valor
                      ? cancelamento.documento.valor
                      : 0,
                  });
                }
              });
            }

            //Cancelamentos de RCEs
            // if (
            //   e.cancelamentosRecibosEletronicos &&
            //   e.cancelamentosRecibosEletronicos?.length > 0
            // ) {
            //   e.cancelamentosRecibosEletronicos.forEach((cancelamento) => {
            //     if (
            //       moment(
            //         cancelamento?.documentoCancelamento.created_at
            //       ).isBetween(dataInicial, dataFinal, undefined, "[]")
            //     ) {
            //       //Se o cancelamento pertencer ao alcance da data, são adicionados os documentos de cancelamento
            //       //e o documento cancelado à lista, mesmo que o documento cancelado não pertencer à data
            //       //Para evitar descrepancias no valor final do mapa de vendas
            //       dadosFiltrados.push({
            //         nifEntidade: e.clienteFornecedor.nif
            //           ? e.clienteFornecedor.nif
            //           : "",
            //         entidade: e.clienteFornecedor.nome
            //           ? e.clienteFornecedor.nome
            //           : "",
            //         serie: cancelamento.serie,
            //         tipoDocumento:
            //           cancelamento.documentoCancelamento.tipoDocumento,
            //         nrDocumento: cancelamento.documentoCancelamento.nrDocumento,
            //         data: moment(
            //           cancelamento.documentoCancelamento.authorizedDateTime
            //         ).format("DD/MM/YYYY"),
            //         valor: cancelamento.documento.valor
            //           ? cancelamento.documento.valor
            //           : 0,
            //       });

            //       dadosFiltrados.push({
            //         nifEntidade: e.clienteFornecedor.nif
            //           ? e.clienteFornecedor.nif
            //           : "",
            //         entidade: e.clienteFornecedor.nome
            //           ? e.clienteFornecedor.nome
            //           : "",
            //         serie: cancelamento.serie,
            //         tipoDocumento: cancelamento.documento.tipoDocumento,
            //         nrDocumento: cancelamento.documento.nrDocumento,
            //         data: moment(
            //           cancelamento.documento.authorizedDateTime
            //         ).format("DD/MM/YYYY"),
            //         valor: cancelamento.documento.valor
            //           ? cancelamento.documento.valor
            //           : 0,
            //       });
            //     }
            //   });
            // }
          }
        }
      }

      //Recibos eletronicos
      // if (
      //   e.faturaEReciboEletronicos &&
      //   e.faturaEReciboEletronicos?.rce?.succeeded === true
      // ) {
      //   if (
      //     moment(e.faturaEReciboEletronicos.rce.created_at).isBetween(
      //       dataInicial,
      //       dataFinal,
      //       undefined,
      //       "[]"
      //     )
      //   ) {
      //     dadosFiltrados.push({
      //       nifEntidade: e.clienteFornecedor.nif ? e.clienteFornecedor.nif : "",
      //       entidade: e.clienteFornecedor.nome ? e.clienteFornecedor.nome : "",
      //       serie: e.documentoEletronico.serie,
      //       tipoDocumento: e.faturaEReciboEletronicos.rce.tipoDocumento,
      //       nrDocumento: e.faturaEReciboEletronicos.rce.nrDocumento,
      //       data: moment(
      //         e.faturaEReciboEletronicos.rce.authorizedDateTime
      //       ).format("DD/MM/YYYY"),
      //       valor: e.valor,
      //     });
      //   }
      // }
    });

    if (dadosFiltrados.length === 0) {
      toast.error("Sem dados para gerar o mapa!");
    } else {
      //Enviar dados de entrada
      dadosMapa(
        dadosFiltrados,
        dadosClinica,
        moment(dataInicial).format("DD-MM-YYYY"),
        moment(dataFinal).format("DD-MM-YYYY")
      );
    }
  };

  let startDate, endDate;

  const handleChangeDataSelect = (e) => {
    const value = e.target.value;

    if (e.target.value === "Hoje") {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day");
    } else if (e.target.value === "Esta semana") {
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
    } else if (e.target.value === "Este mês") {
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
    } else if (e.target.value === "Mês passado") {
      startDate = moment().subtract(1, "month").startOf("month");
      endDate = moment().subtract(1, "month").endOf("month");
    } else {
      startDate = dataInicial;
      endDate = dataFinal;
    }

    setDataInicial(startDate);
    setDataFinal(endDate);
    setDataSelect(value);
  };

  return (
    <div>
      <div className="relatorio__filtros__container">
        <FormControl className="relatorio__filtros__container__selectContainer">
          <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dataSelect}
            label="Periodo"
            onChange={handleChangeDataSelect}
          >
            <MenuItem value="Hoje">Hoje</MenuItem>
            <MenuItem value="Esta semana">Esta Semana</MenuItem>
            <MenuItem value="Este mês">Este mês</MenuItem>
            <MenuItem value="Mês passado">Mês passado</MenuItem>
            <MenuItem value="Escolher periodo">Escolher periodo</MenuItem>
          </Select>
        </FormControl>

        {dataSelect === "Escolher periodo" && (
          <div className="relatorio__datasContainer">
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Inicial*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataInicial(value)}
                  value={dataInicial}
                />
              </LocalizationProvider>
            </div>
            <div className="relatorio__datasContainer__datePickerContainer">
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-despesa__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data Final*"
                  inputFormat="DD-MM-YYYY"
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(value) => setDataFinal(value)}
                  value={dataFinal}
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
      </div>
      <div>
        <button
          onClick={exportarMapaHandler}
          className="relatorio__filtros__gerarDocButton"
        >
          Mapa de Venda
        </button>
      </div>
    </div>
  );
}

export default RelatoriosFinanceiro;
