import React, { useEffect, useState, memo } from "react";

import "./CredenciaisExpirando.scss";
import ModalCredenciais from "../modalCredenciais/ModalCredenciais";
import moment from "moment";

function CredenciaisExpirando({ sendRequest, clinica, auth }) {
  const [orcamentosCredenciais, setOrcamentosCredenciais] = useState();
  const [orcamentosCredenciaisExpirados, setOrcamentosCredenciaisExpirados] =
    useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchClinica = async () => {
      try {
        const orcamentoCred = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/credenciaispertosdeexpirar/${clinica.clinica._id}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        const orcamentosExpirados = orcamentoCred.orcamentos.filter(
          (orcamento) =>
            moment() > moment(orcamento.dataExpiracaoCredencial).endOf("day")
        );

        setOrcamentosCredenciaisExpirados(orcamentosExpirados);
        setOrcamentosCredenciais(orcamentoCred.orcamentos);
      } catch (err) {}
    };
    fetchClinica();
  }, [auth.token, clinica.clinica._id, sendRequest]);

  return (
    <>
      <div>
        <span className="box-title">Credenciais Expirando</span>
        {orcamentosCredenciais && (
          <div className="credenciais__container">
            <div className="credenciais-info">
              <div className="credenciais-info__item">
                <span className="credenciais-info__item__number">
                  {orcamentosCredenciais.length}
                </span>
                <span className="medium-gray-text">próximos de expirar</span>
              </div>

              <div className="credenciais-info__item">
                <span
                  className={`credenciais-info__item__number ${
                    orcamentosCredenciaisExpirados.length > 0 &&
                    "credenciais-info__item__number--danger"
                  }`}
                >
                  {orcamentosCredenciaisExpirados.length}
                </span>
                <span className="medium-gray-text">expirados</span>
              </div>
            </div>
            <div
              className="button-more-div"
              onClick={() => {
                setOpen(true);
              }}
            >
              <span className="button-more">Mais</span>
              <span className="more-arrow"> {">"} </span>
            </div>
          </div>
        )}
      </div>
      {open && (
        <ModalCredenciais
          orcamentos={orcamentosCredenciais}
          handleClose={() => {
            setOpen(false);
          }}
          openModal={open}
        />
      )}
    </>
  );
}

export default memo(CredenciaisExpirando);
