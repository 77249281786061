import { useState, useEffect, useContext } from "react";
import moment from "moment";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalVerReceita.scss";
import { useHttpClient } from "../../../../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../../../../shared/context/auth-context";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
function ModalVerReceita({
  open,
  handleCloseModalVerReceita,
  nomeCliente,
  dadosDaReceita,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [caixa, setCaixa] = useState();
  const [openModal, setOpenModal] = useState(open);
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    const fetchCaixas = async () => {
      startLoading();
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/${dadosDaReceita.caixa}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setCaixa(responseData.caixa);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchCaixas();
  }, [auth.token, dadosDaReceita.caixa, sendRequest]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalVerReceita}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box ver-dados-receita__modal"
          id="modal__ver-receita"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Ver receita de {nomeCliente}
          </h1>

          <div className="dadosDaReceita">
            <div className="dadosDaReceita__elemento">
              <span className="dadosDaReceita__elemento__titulo">
                Metodo de Pagamento:{" "}
              </span>
              <span className="dadosDaReceita__elemento__valor">
                {dadosDaReceita.metodoPagamento}
              </span>
            </div>
            <div className="dadosDaReceita__elemento">
              <span className="dadosDaReceita__elemento__titulo">
                Valor Pago:
              </span>
              <span className="dadosDaReceita__elemento__valor">
                {dadosDaReceita.valorPago.toLocaleString("pt-BR")}$00
              </span>
            </div>
            <div className="dadosDaReceita__elemento">
              <span className="dadosDaReceita__elemento__titulo">
                Data de Recebimento:{" "}
              </span>
              <span className="dadosDaReceita__elemento__valor">
                {moment(dadosDaReceita.dataRecebimento).format("DD-MM-YYYY")}
              </span>
            </div>
            <div className="dadosDaReceita__elemento">
              <span className="dadosDaReceita__elemento__titulo">Caixa:</span>
              <span className="dadosDaReceita__elemento__valor">
                {caixa && caixa.caixa}
              </span>
            </div>
            {dadosDaReceita.cartao && (
              <div className="dadosDaReceita__elemento">
                <span className="dadosDaReceita__elemento__titulo">
                  Cartão:
                </span>
                <span className="dadosDaReceita__elemento__valor">
                  {dadosDaReceita.cartao}
                </span>
              </div>
            )}
            {dadosDaReceita.metodoPagamento === "Cheque" && (
              <>
                <div className="dadosDaReceita__elemento">
                  <span className="dadosDaReceita__elemento__titulo">
                    Número de Cheque:
                  </span>
                  <span className="dadosDaReceita__elemento__valor">
                    {dadosDaReceita.nrCheque}
                  </span>
                </div>
                <div className="dadosDaReceita__elemento">
                  <span className="dadosDaReceita__elemento__titulo">
                    Data do cheque:{" "}
                  </span>
                  <span className="dadosDaReceita__elemento__valor">
                    {moment(dadosDaReceita.dataCheque).format("DD-MM-YYYY")}
                  </span>
                </div>
                <div
                  className="dadosDaReceita__elemento"
                  style={{ borderBottom: "none" }}
                >
                  <span className="dadosDaReceita__elemento__titulo">
                    Conta do Cliente:
                  </span>
                  <span className="dadosDaReceita__elemento__valor">
                    {dadosDaReceita.contaCliente}
                  </span>
                </div>
                <div
                  className="dadosDaReceita__elemento"
                  style={{ borderBottom: "none" }}
                >
                  <span className="dadosDaReceita__elemento__titulo">
                    Banco:
                  </span>
                  <span className="dadosDaReceita__elemento__valor">
                    {dadosDaReceita.banco}
                  </span>
                </div>
              </>
            )}

            <div className="dadosDaReceita__elemento">
              <span className="dadosDaReceita__elemento__titulo">
                Recebido Por:
              </span>
              <span className="dadosDaReceita__elemento__valor">
                {dadosDaReceita.recebidoPor?.name
                  ? dadosDaReceita.recebidoPor.name
                  : ""}
              </span>
            </div>
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalVerReceita}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalVerReceita;
