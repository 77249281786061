import React, { useState } from "react";
import "./ModalStockCadastrar.scss";
import uuid from "react-uuid";
//MUI
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

const UNIDADES_MEDIDA = [
  { value: "EA", label: "Unidade" },
  { value: "KGM", label: "Kilograma (kg)" },
  { value: "MTR", label: "Metro (m)" },
  { value: "LTR", label: "Litro (l)" },
  { value: "MTQ", label: "Metro Cúbico (m³)" },
  { value: "MTK", label: "Metro Quadrado (m²)" },
  { value: "BX", label: "Caixa" },
];

function ModalStockCadastrar({
  handleSubmitEntradaStock,
  handleCloseModalAdicionarStock,
  categoriasStock,
}) {
  const [dadosProdutos, setDadosProdutos] = useState([
    {
      id: 0,
      nome: "",
      categoriaStock: "",
      quantidade: 1,
      quantidadeIdeal: 1,
      lote: "",
      validade: null,
      unidadeMedida: "EA",
    },
  ]);

  const [fixarCategoria, setFixarCategoria] = useState(false);
  const [validationError, setValidationError] = useState("");

  const renderCategoryOptions = (categories, level = 0) => {
    return categories.flatMap((category) => [
      <MenuItem
        key={category.id}
        value={category.id}
        style={{ paddingLeft: `${level * 20}px` }}
      >
        {category.nome}
      </MenuItem>,
      ...(category.subcategorias
        ? renderCategoryOptions(category.subcategorias, level + 1)
        : []),
    ]);
  };

  const handleInputChange = (id, field, value) => {
    setDadosProdutos((current) =>
      current.map((prod) =>
        prod.id === id ? { ...prod, [field]: value } : prod
      )
    );
  };

  const handleDelete = (id) => {
    setDadosProdutos((current) =>
      current.filter((produto) => produto.id !== id)
    );
  };

  const handleAdicionar = () => {
    setDadosProdutos((current) => [
      ...current,
      {
        id: uuid(),
        nome: "",
        categoriaStock: fixarCategoria
          ? current[current.length - 1].categoriaStock
          : "",
        quantidade: 1,
        quantidadeIdeal: 1,
        lote: "",
        validade: null,
        unidadeMedida: fixarCategoria
          ? current[current.length - 1].unidadeMedida
          : "EA",
      },
    ]);
  };

  const preHandleSubmitEntradaStock = () => {
    let mensagemDeErro = "";
    dadosProdutos.forEach((dado) => {
      if (
        !dado.nome ||
        dado.nome.trim() === "" ||
        !dado.quantidade ||
        dado.quantidade.trim() === "" ||
        !dado.quantidadeIdeal ||
        dado.quantidadeIdeal.trim() === "" ||
        !dado.categoriaStock ||
        dado.categoriaStock.trim() === "" ||
        !dado.lote ||
        dado.lote.trim() === ""
      ) {
        mensagemDeErro =
          "Por favor, preencha todos os campos obrigatórios para cada produto.";
        return;
      } else if (dado.validade) {
        if (!moment.isMoment(dado.validade) || !dado.validade.isValid()) {
          mensagemDeErro = "Introduza a data de validade corretamente";
          return;
        }
      }
    });

    if (mensagemDeErro) {
      setValidationError(mensagemDeErro);
    } else {
      setValidationError("");
      handleSubmitEntradaStock(dadosProdutos);
    }
  };

  return (
    <div className="stockCadastrar__container">
      {dadosProdutos.map((produto, index) => (
        <div className="stockCadastrar__container__row" key={index}>
          <TextField
            label="Nome do Produto"
            value={produto.nome}
            onChange={(e) =>
              handleInputChange(produto.id, "nome", e.target.value)
            }
            fullWidth
            required
          />

          <TextField
            select
            label="Medida"
            value={produto.unidadeMedida}
            onChange={(e) =>
              handleInputChange(produto.id, "unidadeMedida", e.target.value)
            }
            fullWidth
          >
            {UNIDADES_MEDIDA.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Categoria Stock"
            value={produto.categoriaStock}
            onChange={(e) =>
              handleInputChange(produto.id, "categoriaStock", e.target.value)
            }
            fullWidth
            required
          >
            {renderCategoryOptions(categoriasStock)}
          </TextField>

          <TextField
            label="Qtd"
            type="number"
            value={produto.quantidade}
            onChange={(e) =>
              handleInputChange(produto.id, "quantidade", e.target.value)
            }
            fullWidth
            required
            inputProps={{ min: 1 }}
          />

          <TextField
            label="Qtd Ideal"
            type="number"
            value={produto.quantidadeIdeal}
            onChange={(e) =>
              handleInputChange(produto.id, "quantidadeIdeal", e.target.value)
            }
            fullWidth
            required
            inputProps={{ min: 1 }}
          />

          <TextField
            label="Lote"
            value={produto.lote}
            onChange={(e) =>
              handleInputChange(produto.id, "lote", e.target.value)
            }
            fullWidth
            required
          />

          <LocalizationProvider
            dateAdapter={AdapterMoment}
            className="adicionar-entrada__modal__container--span1"
          >
            <DesktopDatePicker
              className="valorDataCaixa__fields--field"
              label="Validade"
              inputFormat="DD-MM-YYYY"
              value={produto.validade}
              onChange={(date) =>
                handleInputChange(produto.id, "validade", date)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          {produto.id !== 0 && (
            <DeleteIcon
              className="stockCadastrar__container__delete-btn"
              onClick={() => handleDelete(produto.id)}
            />
          )}
        </div>
      ))}

      <div className="stockCadastrar__container__adicionar-btn__container">
        <div className="stockCadastrar__container__adicionar-btn__container__fixarCategoria">
          <input
            id="fixarCategoriaCheckbox"
            type="checkbox"
            className="checkbox"
            checked={fixarCategoria}
            onChange={(e) => setFixarCategoria(e.target.checked)}
          />
          <label htmlFor="fixarCategoriaCheckbox">Fixar Categoria</label>
        </div>
        <span
          className="stockCadastrar__container__adicionar-btn"
          onClick={handleAdicionar}
        >
          Adicionar outro produto
        </span>
      </div>

      {validationError && (
        <div className="modal__validationErrors__container">
          {validationError}
        </div>
      )}
      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalAdicionarStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span className="blue-button" onClick={preHandleSubmitEntradaStock}>
          Adicionar ao Stock
        </span>
      </div>
    </div>
  );
}

export default ModalStockCadastrar;
