import { React, useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";

import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../shared/context/clinica-context";
import { localeText } from "../../../../shared/util/datatableTranslations";

//Mui icons
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon //Edit icon
//ver
//Delete icon
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "../../../../shared/css/Datatable.scss";
import toast from "react-hot-toast";
import { useLoading } from "../../../../shared/context/LoadingContext";
//Antd
import { Popover, Space } from "antd";
import { Popconfirm } from "antd";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function Datatable() {
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const [open, setOpen] = useState(false);
  const [fotoFuncionario, setFotoFuncionario] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFuncionarios = async () => {
      startLoading();
      try {
        let responseData;

        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        responseData.funcionarios.forEach((funcionario) => {
          let temp = funcionario.data_nasc.split("T")[0].split("-");
          funcionario.data_nasc = temp[2] + "-" + temp[1] + "-" + temp[0];
        });
        setData(responseData.funcionarios);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchFuncionarios();
  }, [sendRequest, clinica, auth.token]);

  const handleDesativar = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/funcionarios/ativardesativar/${id}`,
        "PATCH",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setData(
        data.filter((el) => {
          return el.id !== id;
        })
      );

      toast.success("Funcionario desativado com sucesso!");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const handleOpen = (foto) => {
    setOpen(true);
    setFotoFuncionario(foto);
  };

  const handleClose = () => setOpen(false);

  const funcionarioColumns = [
    {
      field: "funcionario",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={
                params.row.foto
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${params.row.foto}`
                  : "/images/fallBackPicture.png"
              }
              alt="avatar"
              onClick={handleOpen.bind(
                null,
                params.row.foto
                  ? `${process.env.REACT_APP_BACKEND_LINK}/${params.row.foto}`
                  : "/images/fallBackPicture.png"
              )}
            />
            {params.row.funcionarioname}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 7,
      renderCell: (params) => {
        return (
          <span className="nomeDataTable">
            {auth.perm.includes("r-func") && (
              <Link to={`/funcionarios/${params.row.id}/edit`}>
                {params.row.nome}
              </Link>
            )}
            {!auth.perm.includes("r-func") && params.row.nome}
          </span>
        );
      },
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 4,
    },
    {
      field: "contacto2",
      headerName: "Contacto 2",
      flex: 4,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 7,
    },
    {
      field: "data_nasc",
      headerName: "Dt. Nasc.",
      flex: 3,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 3,
      renderCell: (params) => {
        const content = (
          <div className="dots__menu__popup">
            {auth.perm.includes("u-func") && (
              <Link
                to={`/funcionarios/${params.row.id}/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className="popOverMenu--option">
                  <span>Editar</span>
                  <EditIcon className="popOverMenu--option__icon" />
                </div>
              </Link>
            )}

            {auth.perm.includes("d-func") && (
              <Popconfirm
                title={`Excluir Cliente`}
                description={`Pretende desativar o funcionario ${params.row.nome}?`}
                icon={<ErrorIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={handleDesativar.bind(null, params.row.id)}
                // onCancel={}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );
        return (
          <div className="cellAction">
            <Space wrap>
              <Popover content={content} trigger="click" placement="bottom">
                <div className="dots__menu">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </Popover>
            </Space>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {data && (
        <>
          <div className="datatable caixa">
            {auth.perm.includes("c-func") && (
              <div className="datatable__add-button-div">
                <span
                  className="blue-button"
                  onClick={() => navigate("/funcionarios/new")}
                >
                  Adicionar Funcionário
                </span>
              </div>
            )}
            <DataGrid
              autoWidth
              className="datagrid"
              rows={data}
              columns={funcionarioColumns.concat(actionColumn)}
              autoPageSize
              localeText={localeText}
            />
          </div>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={fotoFuncionario} alt="" className="fotoModal" />
        </Box>
      </Modal>
    </>
  );
}

export default Datatable;
