import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../ModalAdicionarEvolucao/ModalAdicionarEvolucao.scss";
import toast from "react-hot-toast";
import moment from "moment";
//Mui Select data
//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useLoading } from "../../../../../../shared/context/LoadingContext";
function ModalEditarEvolucao({
  handleCloseModalEvolucao,
  open,
  auth,
  sendRequest,
  handleModalEditarSuccess,
  cliente,
  dadosEvolucao,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataInicioExecucao, setDataInicioExecucao] = useState(
    dadosEvolucao.data_inicio_execucao
  );
  const [dataFimExecucao, setDataFimExecucao] = useState(
    dadosEvolucao.data_fim_execucao
  );
  const [evolucaoTexto, setEvolucaoTexto] = useState(dadosEvolucao.evolucao);
  const [mensagemErro, setMensagemErro] = useState("");

  const submitHandler = async () => {
    let erro = "";
    if (evolucaoTexto === "" || !evolucaoTexto) {
      erro = "Introduza uma evolução";
    }
    if (!erro) {
      startLoading();
      try {
        const formData = new FormData();
        formData.append("evolucao", evolucaoTexto);
        formData.append("data_inicio_execucao", dataInicioExecucao);
        formData.append("data_fim_execucao", dataFimExecucao);
        formData.append("medico", auth.userId);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/editarevolucao/${dadosEvolucao.id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        toast.success("Evolução editada com sucesso");
        handleModalEditarSuccess({
          id: dadosEvolucao.id,
          _id: dadosEvolucao.id,
          evolucao: evolucaoTexto,
          data_inicio_execucao: dataInicioExecucao,
          data_fim_execucao: dataFimExecucao,
          medico: { id: auth.userId, name: auth.nome, _id: auth.userId },
        });
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    } else {
      setMensagemErro(erro);
    }
    setIsSubmitting(false);
  };

  const preSubmitHandler = () => {
    if (!dataInicioExecucao) {
      toast.error("Introduza a data de inicio de execução");
    } else if (
      !moment.isMoment(dataInicioExecucao) ||
      !dataInicioExecucao.isValid()
    ) {
      toast.error("Introduza a data de inicio de execução corretamente");
    } else if (!dataFimExecucao) {
      toast.error("Introduza a data de fim de execução");
    } else if (
      !moment.isMoment(dataFimExecucao) ||
      !dataFimExecucao.isValid()
    ) {
      toast.error("Introduza a data de fim de execução corretamente");
    } else if (moment(dataFimExecucao).isBefore(moment(dataInicioExecucao))) {
      toast.error(
        "A data de fim de execução não pode ser antes da data de inicio."
      );
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModalEvolucao}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <h1 className="fluxo-atendimento__modal__titulo">
          Adicionar Evolução - {cliente.nome}
        </h1>
        <div className="adicionarEvolucaoContainer__d">
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            className="adicionar-despesa__modal__container--span1"
          >
            <DateTimePicker
              className="valorDataCaixa__fields--field muiDatePicker"
              label="Data Inicial*"
              inputFormat="DD-MM-YYYY HH:mm"
              renderInput={(params) => <TextField {...params} />}
              onChange={(value) => setDataInicioExecucao(value)}
              value={dataInicioExecucao}
            />
          </LocalizationProvider>

          <LocalizationProvider
            dateAdapter={AdapterMoment}
            className="adicionar-despesa__modal__container--span1"
          >
            <DateTimePicker
              className="valorDataCaixa__fields--field muiDatePicker"
              label="Data Final*"
              inputFormat="DD-MM-YYYY HH:mm"
              renderInput={(params) => <TextField {...params} />}
              onChange={(value) => setDataFimExecucao(value)}
              value={dataFimExecucao}
            />
          </LocalizationProvider>
        </div>
        <div className="adicionarEvolucaoContainer">
          <textarea
            className="executarProcedimento__container__textarea"
            placeholder="Escreva aqui a evolução do procedimento."
            cols="30"
            rows="5"
            value={evolucaoTexto}
            onChange={(e) => setEvolucaoTexto(e.target.value)}
          />
        </div>
        {mensagemErro && (
          <div className="modalEvolucaoErroContainer">{mensagemErro}</div>
        )}
        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            disabled={isSubmitting}
            className="blue-button"
            onClick={preSubmitHandler}
            style={{ marginRight: "10px" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>

          <span className="cancel-btn" onClick={handleCloseModalEvolucao}>
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalEditarEvolucao;
