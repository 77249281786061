import { useState, useEffect } from "react";
import InputNumber from "../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../shared/components/inputs/InputText/InputText";
import InputSelect from "../../../../shared/components/inputs/InputSelect/InputSelect";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./ModalDespesaRecorrente.scss";
import moment from "moment";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  "Cheque Visado",
  "Transferência de crédito",
  "Internet banking",
  "Cartão do banco",
  "Débito direto da conta bancária",
  "Cartão de crédito",
  "Cartão de débito",
];

const tiposRepeticao = [
  { value: "semanal", label: "Semanal" },
  { value: "quinzenal", label: "Quinzenal" },
  { value: "mensal", label: "Mensal" },
  { value: "bimestral", label: "Bimestral" },
  { value: "trimestral", label: "Trimestral" },
  { value: "semestral", label: "Semestral" },
  { value: "anual", label: "Anual" },
];

function ModalEditarDespesaRecorrente({
  openModalDespesaRecorrente,
  handleCloseModalDespesaRecorrente,
  sendRequest,
  clinicaId,
  updateDespesasRecorrentes,
  auth,
  despesaRecorrenteFiltrada,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalDespesaRecorrente);
  const [descricao, setDescricao] = useState(
    despesaRecorrenteFiltrada.descricao
  );
  const [valor, setValor] = useState(despesaRecorrenteFiltrada.valor);
  const [observacao, setObservacao] = useState(
    despesaRecorrenteFiltrada.observacao
  );
  const [categoria, setCategoria] = useState(
    despesaRecorrenteFiltrada.categoria._id
  );
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState(despesaRecorrenteFiltrada.caixa._id);
  const [caixas, setCaixas] = useState([]);

  const [despesaPaga, setDespesaPaga] = useState(
    despesaRecorrenteFiltrada.despesaPaga
  );

  const [tipoRepeticao, setTipoRepeticao] = useState(
    despesaRecorrenteFiltrada.tipoRepeticao
  );

  const [metodoPagamento, setMetodoPagamento] = useState(
    despesaRecorrenteFiltrada.metodoPagamento
      ? despesaRecorrenteFiltrada.metodoPagamento
      : formasPagamento[0]
  );

  const [dataTermino, setDataTermino] = useState(
    despesaRecorrenteFiltrada.dataTermino
      ? despesaRecorrenteFiltrada.dataTermino
      : moment().add(1, "year")
  );
  const [dataInicio, setDataInicio] = useState(
    despesaRecorrenteFiltrada.dataInicio
      ? despesaRecorrenteFiltrada.dataInicio
      : moment()
  );

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, caixasResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategorias(categoriasFormatted);

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));
        setCaixas(caixasFormatted);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalDespesaRecorrente);
  }, [openModalDespesaRecorrente]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const guardarDespesaHandler = async () => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("observacoes", observacao ? observacao : "");
      formData.append("descricao", descricao);
      formData.append("valor", valor);
      formData.append("tipoRepeticao", tipoRepeticao);
      formData.append("categoria", categoria);
      formData.append("caixa", caixa);
      formData.append("atualizadoPor", auth.userId);
      formData.append("dataTermino", moment(dataTermino).format("MM-DD-YYYY"));
      formData.append("dataInicio", moment(dataInicio).format("MM-DD-YYYY"));

      if (despesaPaga) {
        formData.append("metodoPagamento", metodoPagamento);
        formData.append("despesaPaga", true);
      } else {
        formData.append("despesaPaga", false);
      }

      const despesaAdicionada = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/despesasrecorrentes/${despesaRecorrenteFiltrada._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      updateDespesasRecorrentes(despesaAdicionada.despesaRecorrente, "edit");
      toast.success("Despesa recorrente editada com sucesso");
      handleCloseModalDespesaRecorrente();
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  const preGuardarDespesaHandler = () => {
    if (valor === 0 || valor < 0) {
      toast.error("O valor da despesa deve ser > 0");
    } else if (!descricao || descricao.trim().length === 0) {
      toast.error("Introduza uma descrição");
    } else if (!dataTermino) {
      toast.error("Introduza a data de término");
    } else if (!moment.isMoment(dataTermino) || !dataTermino.isValid()) {
      toast.error("Introduza a data de término corretamente");
    } else if (!dataInicio) {
      toast.error("Introduza a data de inicio");
    } else if (!moment.isMoment(dataInicio) || !dataInicio.isValid()) {
      toast.error("Introduza a data de inicio corretamente");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      guardarDespesaHandler();
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalDespesaRecorrente}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Nova despesa recorrente
          </h1>
          <div className="adicionar-despesa__modal__container">
            <InputText
              className="adicionar-despesa__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />
            <InputNumber
              className="adicionar-despesa__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />
            <div className="customInputContainer adicionar-despesa__modal__container--span1">
              <select
                onChange={(e) => setTipoRepeticao(e.target.value)}
                className="customInputSelect"
                value={tipoRepeticao}
              >
                {tiposRepeticao.map((tipo) => (
                  <option key={tipo.value} value={tipo.value}>
                    {tipo.label}
                  </option>
                ))}
              </select>
              <label className="customInputLabelSelect">
                Tipo de repetição
              </label>
            </div>
            {categorias && categoria && (
              <div className="customInputContainer adicionar-despesa__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
              </div>
            )}
            {caixas && caixa && (
              <div className="customInputContainer adicionar-despesa__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
              </div>
            )}

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de inicio*"
                inputFormat="DD-MM-YYYY"
                value={dataInicio}
                onChange={(value) => setDataInicio(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data de término*"
                inputFormat="DD-MM-YYYY"
                value={dataTermino}
                onChange={(value) => setDataTermino(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-despesa__modal__container__textarea"
            ></textarea>
          </div>

          <div className="adicionar-despesa__modal__container__checkbox-pago__container">
            <input
              type="checkbox"
              name=""
              id="ja-foi-pago-checkbox"
              onChange={(e) => setDespesaPaga(e.target.checked)}
              checked={despesaPaga}
            />
            <label htmlFor="ja-foi-pago-checkbox">
              Essa despesa fica como paga
            </label>
          </div>

          {despesaPaga && (
            <div className="adicionar-despesa__modal__pagamento">
              <InputSelect
                label="Forma de pagamento"
                initialValue={metodoPagamento}
                handleChange={metodoPagamentoChangeHandler}
                items={formasPagamento}
              />
            </div>
          )}
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalDespesaRecorrente}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              disabled={isSubmitting}
              className="blue-button"
              onClick={preGuardarDespesaHandler}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEditarDespesaRecorrente;
