import { useState, useContext } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { clienteSchema } from "../../../shared/util/schemas";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import DriveFolderUploadOutlined from "@mui/icons-material/DriveFolderUploadOutlined";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import toast from "react-hot-toast";
import { CloseOutlined } from "@mui/icons-material";

//Mui icons
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PageviewIcon from "@mui/icons-material/Pageview";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import "./NewCliente.scss";
import "../../../style/formError.scss";
import ModalListaClientesFinancas from "../components/modalListaClientesFinancas/ModalListaClientesFinancas";

import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import codigoPaises from "../components/codigoPaises";
import { useLoading } from "../../../shared/context/LoadingContext";
import { fixSpaces } from "../../../shared/util/helperFunctions";

function New() {
  const { startLoading, stopLoading } = useLoading();
  const [dataNascimento, setDataNascimento] = useState(
    moment().subtract(18, "years")
  );
  const [file, setFile] = useState("");
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [nomeFiscal, setNomeFiscal] = useState("");
  const [nome, setNome] = useState("");
  const [nif, setNif] = useState("999999999");
  const [identificacao, setIdentificacao] = useState("");
  const [pais, setPais] = useState({ nome: "CABO VERDE", codigo: "CV" });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChooseCliente = (cliente) => {
    setOpenModal(false);
    setNomeFiscal(cliente.Name);
    if (!nome || nome.trim() === "") {
      setNome(cliente.Name);
    }
    setNif(cliente.TaxId);
    setIdentificacao(cliente.BI);
  };

  const onSubmit = async (values, actions) => {
    startLoading();
    try {
      if (nomeFiscal?.trim() === "" || !nomeFiscal) {
        toast.error("Introduza o nome do cliente antes de continuar");
      } else if (!pais || pais === "") {
        toast.error("Introduza o país do cliente antes de continuar");
      } else if (!nif || nif === "") {
        toast.error("Introduza o NIF do cliente antes de continuar");
      } else if (!dataNascimento) {
        toast.error("Introduza a data de nascimento antes de continuar");
      } else if (
        !moment.isMoment(dataNascimento) ||
        !dataNascimento.isValid()
      ) {
        toast.error("Introduza a data de nascimento corretamente");
      } else {
        const formData = new FormData();
        // normalizar os nomes
        const wordsNomeFiscal = nomeFiscal.toLowerCase().split(" ");
        const wordsNome = nome.toLowerCase().split(" ");
        // Uppercase the first letter of each word
        const capitalizedWordsNomeFiscal = wordsNomeFiscal.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const capitalizedWordsNome = wordsNome.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        const titledNameFiscal = fixSpaces(
          capitalizedWordsNomeFiscal.join(" ")
        );
        const titledName = fixSpaces(capitalizedWordsNome.join(" "));

        formData.append("nomeFiscal", titledNameFiscal);
        formData.append("nome", nome ? titledName : titledNameFiscal);
        formData.append("genero", values.genero);
        formData.append(
          "data_nasc",
          moment(dataNascimento).format("DD-MM-YYYY")
        );
        formData.append("email", fixSpaces(values.email));
        formData.append("contacto2", fixSpaces(values.contacto2));
        formData.append("contacto", fixSpaces(values.contacto));
        formData.append("pais", JSON.stringify(pais));
        formData.append("sms", values.sms);
        formData.append("com_email", values.com_email);
        formData.append("com_whatsapp", values.com_whatsapp);
        formData.append("observacao", values.observacao);
        if (file) {
          const fileExtension = file.name.split(".").pop();
          const modifiedFile = new File(
            [file],
            `${clinica.clinica._id}-${file.name}.${fileExtension}`,
            { type: file.type }
          );
          formData.append("foto", modifiedFile);
        }
        formData.append("endereco", fixSpaces(values.endereco));
        formData.append("responsavel", fixSpaces(values.responsavel));
        formData.append("nif", nif);
        formData.append("identificacao", identificacao ? identificacao : "");
        formData.append("nr_beneficiario", values.nr_beneficiario);
        formData.append("ativo", true);
        formData.append("clinica", clinica.clinica._id);
        formData.append("criadoPor", auth.userId);

        //Verificando Duplicidade
        const formData2 = new FormData();
        formData2.append("nome", titledName ? titledName : titledNameFiscal);
        formData2.append("contacto", values.contacto);
        formData2.append("clinica", clinica.clinica._id);

        let respostaDuplicidade;
        try {
          respostaDuplicidade = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/verificarduplicidade`,
            "POST",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (!respostaDuplicidade.duplicidade) {
          try {
            const resposta = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/clientes`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
            toast.success("Cliente adicionado com sucesso!");
            navigate(`/clientes/${resposta.cliente.id}`);
          } catch (err) {
            console.error("err", err);
          }
        } else {
          toast.error("Esse cliente já existe no sistema!");
        }
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      genero: "feminino",
      email: "",
      contacto2: "",
      contacto: "",
      sms: false,
      com_email: false,
      com_whatsapp: false,
      observacao: "",
      endereco: "",
      responsavel: "",
      nr_beneficiario: "",
    },
    validationSchema: clienteSchema,
    onSubmit,
  });

  const findClientInfo = async (nif, nomeToCheck) => {
    if (pais.nome !== "CABO VERDE") {
      toast.error("Procura por nome ou nif só para Cabo Verde");
    } else {
      const formData = new FormData();
      nomeToCheck && formData.append("nome", nomeToCheck);
      nif && formData.append("nif", nif);
      formData.append(
        "clienteSecret",
        clinica.clinica.dadosEmissor.cliente_oauth_secret
      );
      formData.append("clienteId", clinica.clinica.dadosEmissor.cliente_id);
      try {
        startLoading();
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/documentoeletronico/nifbinome`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        stopLoading();

        if (response.payload.length === 0) {
          toast.error("Nenhum cliente encontrado");
        } else if (response.payload.length === 1) {
          toast.success("Cliente encontrado");
          setNomeFiscal(response.payload[0].Name);
          setNome(response.payload[0].Name);
          if (!nome || nome.trim() === "") {
            setNome(response.payload[0].Name);
          }
          setNif(response.payload[0].TaxId);
          setIdentificacao(response.payload[0].BI);
        } else {
          setModalData(response.payload);
          setOpenModal(true);
        }
      } catch (error) {
        stopLoading();
        console.error(error);
      }
    }
  };

  // Custom option rendering function
  const renderOption = (props, option) => (
    <Box
      component="span"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: "8px",
      }}
      {...props}
    >
      <Typography variant="subtitle1">{option.nome}</Typography>
      <Typography variant="body2" color="textSecondary">
        {option.codigo}
      </Typography>
    </Box>
  );

  return (
    <>
      <ModalListaClientesFinancas
        open={openModal}
        handleCloseModal={handleCloseModal}
        clientes={modalData}
        handleChooseCliente={handleChooseCliente}
      />
      <div className="new-cliente">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Adicionar Cliente"
            icon={PersonOutlineOutlinedIcon}
            paths={[
              { nome: "Clientes", link: `../../clientes` },
              { nome: "Novo" },
            ]}
          />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/medico-ficha.png" alt="" />
            </div>
            <div className="bottom-right">
              <div className="imagePicker takes1-2">
                <div className="imagePickerContainer">
                  <CloseOutlined />
                  <img
                    src={
                      file ? URL.createObjectURL(file) : "/images/no-image.jpg"
                    }
                    alt=""
                  />
                  <label className="icon-file" htmlFor="file">
                    <DriveFolderUploadOutlined className="icon" />
                  </label>
                </div>

                <input
                  className="input"
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              <div className="takes2-5 form-input">
                <input
                  id="nomeFiscal"
                  name="nomeFiscal"
                  type="text"
                  placeholder="Nome Fiscal"
                  value={nomeFiscal}
                  onChange={(e) => setNomeFiscal(e.target.value)}
                  className={
                    errors.nomeFiscal && touched.nomeFiscal
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="nomeFiscal">
                  Nome Fiscal*
                </label>
                <PageviewIcon
                  className="icon-search-field"
                  onClick={findClientInfo.bind(null, null, nomeFiscal)}
                />
                {errors.nomeFiscal && touched.nomeFiscal ? (
                  <p className="error-message">{errors.nomeFiscal}</p>
                ) : null}
              </div>

              <div className="takes5-7">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    className="dataNascimento__cliente"
                    label="Data Nascimento*"
                    inputFormat="DD-MM-YYYY"
                    value={dataNascimento}
                    onChange={(value) => setDataNascimento(value)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className="takes2-5 row2 form-input">
                <input
                  id="nome"
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  className={
                    errors.nome && touched.nome ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nome">
                  Nome
                </label>
              </div>

              <div className="form-input takes5-7 row2">
                <select
                  id="genero"
                  name="genero"
                  value={values.genero}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input"
                >
                  <option value="feminino">Feminino</option>
                  <option value="masculino">Masculino</option>
                  <option value="outro">Outro</option>
                </select>
              </div>

              <div className="form-input takes1-3">
                <input
                  id="endereco"
                  name="endereco"
                  type="text"
                  placeholder="Mindelo"
                  value={values.endereco}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.endereco && touched.endereco
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="endereco">
                  Endereço*
                </label>
                {errors.endereco && touched.endereco ? (
                  <p className="error-message">{errors.endereco}</p>
                ) : null}
              </div>

              <div className="form-input takes3-5">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="email">
                  Email
                </label>
                {errors.email && touched.email ? (
                  <p className="error-message">{errors.email}</p>
                ) : null}
              </div>

              <div className="form-input takes5-7">
                <input
                  id="responsavel"
                  name="responsavel"
                  type="text"
                  placeholder="Joao Sousa"
                  value={values.responsavel}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.responsavel && touched.responsavel
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="responsavel">
                  Responsável
                </label>
                {errors.responsavel && touched.responsavel ? (
                  <p className="error-message">{errors.responsavel}</p>
                ) : null}
              </div>

              <div className="form-input takes1-3">
                <input
                  id="contacto"
                  name="contacto"
                  type="text"
                  placeholder="5999999"
                  value={values.contacto}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto && touched.contacto
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto">
                  Contacto*
                </label>
                {errors.contacto && touched.contacto ? (
                  <p className="error-message">{errors.contacto}</p>
                ) : null}
              </div>

              <div className="form-input takes3-5">
                <input
                  id="contacto2"
                  name="contacto2"
                  type="text"
                  placeholder="2313131"
                  value={values.contacto2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.contacto2 && touched.contacto2
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="contacto2">
                  Contacto 2
                </label>
                {errors.contacto2 && touched.contacto2 ? (
                  <p className="error-message">{errors.contacto2}</p>
                ) : null}
              </div>

              <FormControl style={{ width: "100%" }} className="takes5-7">
                <Autocomplete
                  id="treatment-autocomplete"
                  value={pais}
                  onChange={(e, value) => setPais(value)}
                  options={codigoPaises}
                  getOptionLabel={(option) =>
                    `${option.nome} - ${option.codigo}`
                  }
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pais"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </FormControl>

              <div className="form-input takes1-3">
                <input
                  id="identificacao"
                  name="identificacao"
                  type="text"
                  placeholder="1333333"
                  value={identificacao}
                  onChange={(e) => setIdentificacao(e.target.value)}
                  className={
                    errors.identificacao && touched.identificacao
                      ? "input-error input"
                      : "input"
                  }
                />

                <label className="label" htmlFor="identificacao">
                  BI / CNI
                </label>
                {errors.identificacao && touched.identificacao ? (
                  <p className="error-message">{errors.identificacao}</p>
                ) : null}
              </div>

              <div className="form-input takes3-5">
                <input
                  id="nif"
                  name="nif"
                  type="text"
                  placeholder="1333333"
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                  className={
                    errors.nif && touched.nif ? "input-error input" : "input"
                  }
                />
                <label className="label" htmlFor="nif">
                  Nif
                </label>
                <PageviewIcon
                  className="icon-search-field"
                  onClick={findClientInfo.bind(null, nif, null)}
                />
                {errors.nif && touched.nif ? (
                  <p className="error-message">{errors.nif}</p>
                ) : null}
              </div>

              <div className="form-input takes5-7">
                <input
                  id="nr_beneficiario"
                  name="nr_beneficiario"
                  type="text"
                  placeholder="1333333"
                  value={values.nr_beneficiario}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.nr_beneficiario && touched.nr_beneficiario
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="nr_beneficiario">
                  Nr Beneficiário
                </label>
                {errors.nr_beneficiario && touched.nr_beneficiario ? (
                  <p className="error-message">{errors.nr_beneficiario}</p>
                ) : null}
              </div>

              <div className="formGroup takes1-4">
                <div className="comunicacao">
                  <label className="label">
                    Email?
                    <input
                      id="com_email"
                      name="com_email"
                      type="checkbox"
                      value={values.com_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.com_email && touched.com_email
                          ? "input-error input"
                          : "input"
                      }
                    />
                  </label>
                  <label className="label">
                    SMS?
                    <input
                      id="sms"
                      name="sms"
                      type="checkbox"
                      value={values.sms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.sms && touched.sms
                          ? "input-error input"
                          : "input"
                      }
                    />
                  </label>
                  <label className="label">
                    Whatsapp?
                    <input
                      id="com_whatsapp"
                      name="com_whatsapp"
                      type="checkbox"
                      value={values.com_whatsapp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.com_whatsapp && touched.com_whatsapp
                          ? "input-error input"
                          : "input"
                      }
                    />
                  </label>
                  {errors.com_whatsapp && touched.com_whatsapp ? (
                    <p className="error-message">{errors.com_whatsapp}</p>
                  ) : null}
                </div>
              </div>

              <div className="form-input takes1-7">
                <textarea
                  rows="4"
                  cols="50"
                  id="observacao"
                  name="observacao"
                  type="textarea"
                  placeholder="Observações"
                  value={values.observacao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.observacao && touched.observacao
                      ? "input-error input"
                      : "input"
                  }
                  style={{ resize: "none" }}
                />

                {errors.observacao && touched.observacao ? (
                  <p className="error-message">{errors.observacao}</p>
                ) : null}
              </div>

              <div className="botoes">
                <button
                  className="cancel-btn"
                  onClick={() => navigate("/clientes")}
                >
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default New;
