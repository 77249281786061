import React, { useState, useContext, useEffect } from "react";
import "./ModalImportXlsStock.scss";
import moment from "moment";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import { AuthContext } from "../../../shared/context/auth-context";
import InputNumber from "../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../shared/components/inputs/InputText/InputText";

import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";

import { TextField, MenuItem } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const UNIDADES_MEDIDA = [
  { value: "EA", label: "Unidade" },
  { value: "KGM", label: "Kilograma (kg)" },
  { value: "MTR", label: "Metro (m)" },
  { value: "LTR", label: "Litro (l)" },
  { value: "MTQ", label: "Metro Cúbico (m³)" },
  { value: "MTK", label: "Metro Quadrado (m²)" },
  { value: "BX", label: "Caixa" },
];

const normalizeUnidadeMedida = (unit) => {
  if (!unit) return "EA";

  // Convert to lowercase and remove spaces
  const normalized = unit.toString().toLowerCase().trim();

  // Handle variations
  switch (normalized) {
    // Each/Unit variations
    case "ea":
    case "un":
    case "unidade":
    case "cada":
    case "unit":
    case "piece":
    case "pce":
      return "EA";

    // Kilogram variations
    case "kgm":
    case "kg":
    case "kgs":
    case "kilos":
    case "kilogramas":
    case "kilogram":
    case "kilograma":
      return "KGM";

    // Meter variations
    case "mtr":
    case "m":
    case "metro":
    case "metros":
      return "MTR";

    // Liter variations
    case "ltr":
    case "l":
    case "lt":
    case "litro":
    case "litros":
      return "LTR";

    // Cubic meter variations
    case "mtq":
    case "m3":
    case "m³":
    case "metro cubico":
    case "metros cubicos":
    case "metro cúbico":
    case "metros cúbicos":
      return "MTQ";

    // Square meter variations
    case "mtk":
    case "m2":
    case "m²":
    case "metro quadrado":
    case "metros quadrados":
      return "MTK";

    // Box variations
    case "bx":
    case "cx":
    case "caixa":
    case "caixas":
    case "box":
    case "boxes":
      return "BX";

    default:
      return "EA"; // Default to 'EA' for unrecognized units
  }
};

function ModalImportXlsStock({
  stock,
  handleCloseModalImportStock,
  sendRequest,
  produtos,
  atualizarStock,
}) {
  const { startLoading, stopLoading } = useLoading();
  const clinica = useContext(ClinicaContext);
  const auth = useContext(AuthContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [categoriasStock, setCategoriasStock] = useState();
  const [adicionarComoDespesa, setAdicionarComoDespesa] = useState(false);
  const [custoTotal, setCustoTotal] = useState(0);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [descricao, setDescricao] = useState("");
  const [despesaPaga, setDespesaPaga] = useState(true);

  const [currentStep, setCurrentStep] = useState(1);
  const [processedData, setProcessedData] = useState([]);
  const [categoriaPrincipal, setCategoriaPrincipal] = useState();

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          categoriasStockResponse,
          categoriasFinanceiroResponse,
          caixasResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasstock/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/despesa/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        setCategoriasStock(categoriasStockResponse.categoriasStock);
        setCategoriaPrincipal(categoriasStockResponse.categoriasStock[0]._id);

        setCategorias(
          categoriasFinanceiroResponse.categoriasFinanceiro.map((cat) => {
            return { id: cat.id, value: cat.categoria };
          })
        );
        setCategoria(categoriasFinanceiroResponse.categoriasFinanceiro[0].id);
        setCaixas(
          caixasResponse.caixas.map((cai) => {
            return { id: cai.id, value: cai.caixa };
          })
        );
        setCaixa(caixasResponse.caixas[0].id);
      } catch (err) {
        console.error(err);
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [auth.token, clinica, sendRequest]);

  const handleSubmitImportStock = async () => {
    if (!processedData || processedData.length === 0) {
      toast.error("Nenhum dado disponível para importar.");
      return;
    }

    processedData.forEach((p) => {
      if (p.dataValidade) {
        if (!moment.isMoment(p.dataValidade) || !p.dataValidade.isValid()) {
          toast.error("Existe uma(s) data(s) de validade incorreta(s)");
          return;
        }
      }
    });

    startLoading();
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      const novosProdutos = [];
      const produtosExistentes = [];
      const novosMovimentosParaStock = [];
      let updatedStock = [...stock.stock];

      for (const item of processedData) {
        const produtoExistente = updatedStock.find(
          (stockItem) =>
            stockItem.produto.nome.trim().toLowerCase() ===
            item.nome.trim().toLowerCase()
        );

        if (produtoExistente) {
          // Update existing product
          const loteExistente = produtoExistente.lotes.find(
            (l) =>
              l.lote.trim().toLowerCase() === item.lote.trim().toLowerCase()
          );

          if (loteExistente) {
            // Update existing lote
            loteExistente.quantidade += item.quantidade;
            loteExistente.dataValidade = item.dataValidade;
          } else {
            // Add new lote
            produtoExistente.lotes.push({
              lote: item.lote,
              quantidade: item.quantidade,
              dataValidade: item.dataValidade,
            });
          }

          produtoExistente.quantidadeTotal += item.quantidade;
          produtoExistente.quantidadeIdeal = item.quantidadeIdeal;

          produtosExistentes.push(produtoExistente);

          novosMovimentosParaStock.push({
            produto: produtoExistente.produto._id,
            lote: item.lote,
            dataValidade: item.dataValidade,
            quantidade: item.quantidade,
            data: new Date(),
            saida: false,
            responsavelMovimentoUser: auth.userId,
          });
        } else {
          // Add new product
          novosProdutos.push({
            clinica: clinica.clinica._id,
            nome: item.nome,
            categoriaStock: item.categoriaStock,
            unidadeMedida: item.unidadeMedida,
            lotes: [
              {
                lote: item.lote,
                quantidade: item.quantidade,
                dataValidade: item.dataValidade,
              },
            ],
            quantidadeTotal: item.quantidade,
            quantidadeIdeal: item.quantidadeIdeal,
          });
        }
      }

      const novosProdutosBackend = novosProdutos.map((p) => ({
        clinica: p.clinica,
        nome: p.nome,
        categoriaStock: p.categoriaStock,
        criadoPor: auth.userId,
        unidadeMedida: p.unidadeMedida,
      }));

      // Add new products
      if (novosProdutosBackend.length > 0) {
        const formData = new FormData();
        formData.append("produtos", JSON.stringify(novosProdutosBackend));

        const produtosRetornados = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/produtos/addmany`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        produtosRetornados.produtos.forEach((p, index) => {
          const originalProduto = novosProdutos[index];
          novosMovimentosParaStock.push({
            produto: p._id,
            lote: originalProduto.lotes[0].lote,
            dataValidade: originalProduto.lotes[0].dataValidade,
            quantidade: originalProduto.lotes[0].quantidade,
            data: new Date(),
            saida: false,
            responsavelMovimentoUser: auth.userId,
          });

          // Add new product to updatedStock
          updatedStock.push({
            produto: p,
            lotes: originalProduto.lotes,
            quantidadeTotal: originalProduto.quantidadeTotal,
            quantidadeIdeal: originalProduto.quantidadeIdeal,
          });
        });
      }

      const stockAtualizado = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/stocks/${stock._id}`,
        "PATCH",
        JSON.stringify({
          updatedData: updatedStock,
          historicoMovimentos: novosMovimentosParaStock,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      atualizarStock(
        stockAtualizado.stock,
        `Importação concluída com sucesso! ${novosProdutos.length} novos produtos e ${produtosExistentes.length} produtos existentes atualizados.`
      );
      handleCloseModalImportStock();
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    // Check file size (10MB limit)
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes

    // Check file extension
    const allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (!allowedExtensions.test(selectedFile.name)) {
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage(
        `O ficheiro "${selectedFile.name}" tem uma extensão inválida.`
      );
      return;
    } else {
      setErrorMessage("");
    }

    setErrorMessage("");
    convertFileToObject(selectedFile);
    setFile(selectedFile);
  };

  const convertFileToObject = (ficheiro) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryData = event.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const processedData = jsonData.map((row) => {
        let dataValidade = null;
        if (row.dataValidade) {
          dataValidade = new Date(
            (row.dataValidade - (25567 + 2)) * 86400 * 1000
          );
          dataValidade = new Date(
            dataValidade.getTime() + dataValidade.getTimezoneOffset() * 60000
          );
        }

        return {
          nome: row.nome,
          quantidade: Number(row.quantidade),
          quantidadeIdeal: Number(
            row["quantidade ideal"] || row.quantidadeIdeal
          ),
          lote: row.lote || "Default",
          dataValidade: dataValidade,
          categoriaStock: categoriaPrincipal,
          unidadeMedida: normalizeUnidadeMedida(row.unidadeMedida), // Will convert to international code
        };
      });

      // Validate the data
      const errors = validateExcelData(jsonData);
      if (errors.length > 0) {
        setErrorMessage(errors.join(" "));
      } else {
        setProcessedData(processedData);
      }
    };
    reader.readAsArrayBuffer(ficheiro);
  };

  const custoTotalChangeHandler = (value) => {
    setCustoTotal(value);
  };

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const validateExcelData = (data) => {
    const requiredColumns = ["nome", "quantidade", "quantidade ideal"];
    const errors = [];

    if (data.length === 0) {
      errors.push("O arquivo está vazio.");
    } else {
      const firstRow = data[0];
      requiredColumns.forEach((column) => {
        if (column === "quantidade ideal") {
          if (
            !("quantidade ideal" in firstRow) &&
            !("quantidadeIdeal" in firstRow)
          ) {
            errors.push(`A coluna "${column}" está faltando no arquivo.`);
          }
        } else if (!(column in firstRow)) {
          errors.push(`A coluna "${column}" está faltando no arquivo.`);
        }
      });

      // Check if dataValidade is present and valid
      if ("dataValidade" in firstRow) {
        data.forEach((row, index) => {
          if (row.dataValidade && isNaN(row.dataValidade)) {
            errors.push(
              `Data inválida na linha ${index + 1}: ${row.dataValidade}`
            );
          }
        });
      }
    }

    return errors;
  };

  const handleCategoriaPrincipalChange = (event) => {
    const newCategoria = event.target.value;
    setCategoriaPrincipal(newCategoria);
    updateProcessedDataWithNewCategoria(newCategoria);
  };

  const handleProceedToNextStep = () => {
    if (processedData) {
      setCurrentStep(2);
    } else {
      toast.error(
        "Por favor, selecione um arquivo válido antes de prosseguir."
      );
    }
  };

  const StepTwo = ({ processedData, setProcessedData, categoriasStock }) => {
    const handleTextChange = (index, field, value) => {
      const updatedData = [...processedData];
      updatedData[index][field] = value;
      setProcessedData(updatedData);
    };

    const handleCategoriaChange = (index, value) => {
      const updatedData = [...processedData];
      updatedData[index].categoriaStock = value;
      setProcessedData(updatedData);
    };

    const handleDateChange = (index, date) => {
      const updatedData = [...processedData];
      updatedData[index].dataValidade = date ? date.toDate() : null;
      setProcessedData(updatedData);
    };
    const handleNumberChange = (index, field, value) => {
      const updatedData = [...processedData];
      updatedData[index][field] = Number(value);
      setProcessedData(updatedData);
    };

    const renderCategoryOptions = (categories, level = 0) => {
      return categories.flatMap((category) => [
        <MenuItem
          key={category.id}
          value={category.id}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          {category.nome}
        </MenuItem>,
        ...(category.subcategorias
          ? renderCategoryOptions(category.subcategorias, level + 1)
          : []),
      ]);
    };

    return (
      <div className="stockImport2__container">
        {processedData.map((item, index) => (
          <div key={crypto.randomUUID() + index} className="stockImport2__item">
            <TextField
              label="Nome do Produto"
              value={item.nome}
              onChange={(e) => handleTextChange(index, "nome", e.target.value)}
              fullWidth
            />

            <TextField
              select
              label="Medida"
              value={item.unidadeMedida || "EA"} // Changed default to EA
              onChange={(e) =>
                handleTextChange(index, "unidadeMedida", e.target.value)
              }
              fullWidth
            >
              {UNIDADES_MEDIDA.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Categoria"
              value={item.categoriaStock}
              onChange={(e) => handleCategoriaChange(index, e.target.value)}
              fullWidth
              variant="outlined"
              className=""
            >
              {renderCategoryOptions(categoriasStock)}
            </TextField>

            <TextField
              label="Lote"
              value={item.lote}
              onChange={(e) => handleTextChange(index, "lote", e.target.value)}
              fullWidth
            />

            <TextField
              label="Qtd"
              type="number"
              value={item.quantidade}
              onChange={(e) =>
                handleNumberChange(index, "quantidade", e.target.value)
              }
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
            />

            <TextField
              label="Qtd Ideal"
              type="number"
              value={item.quantidadeIdeal}
              onChange={(e) =>
                handleNumberChange(index, "quantidadeIdeal", e.target.value)
              }
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
            />

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-entrada__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field"
                label="Validade"
                inputFormat="DD-MM-YYYY"
                value={item.dataValidade ? moment(item.dataValidade) : null}
                onChange={(date) => handleDateChange(index, date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        ))}
      </div>
    );
  };

  const updateProcessedDataWithNewCategoria = (newCategoria) => {
    if (processedData.length > 0) {
      const updatedData = processedData.map((item) => ({
        ...item,
        categoriaStock: newCategoria,
      }));
      setProcessedData(updatedData);
    }
  };

  return (
    <div className="stockImport__container">
      {currentStep === 1 ? (
        <>
          <div className="modalAdicionarFicheiros__container">
            <div className="modalAdicionarFicheiros__container-adicionar">
              {categoriasStock && (
                <div className="customInputContainer">
                  <select
                    onChange={handleCategoriaPrincipalChange}
                    className="customInputSelect"
                    value={categoriaPrincipal}
                  >
                    {categoriasStock.map((c) => (
                      <option value={c._id}>{c.nome}</option>
                    ))}
                  </select>
                  <label className="customInputLabelSelect">
                    Categoria Principal
                  </label>
                </div>
              )}
              <div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="fileInput"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="fileInput"
                  className="modalAdicionarFicheiros__container-adicionar--btn-adicionar"
                >
                  Escolher Ficheiro
                </label>
              </div>
              {!file?.name && (
                <>
                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    O Tamanho máximo do upload é 10MB
                  </span>
                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    Formatos aceites: .xls e .xlsx
                  </span>
                </>
              )}
              {file?.name && (
                <span className="modalImportarXlsStock__nomeFicheiro">
                  {file.name}
                </span>
              )}
              <span
                className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro"
                style={{
                  marginTop: "20px",
                  lineHeight: "25px",
                  maxWidth: "800px",
                }}
              >
                O ficheiro deve ter 6 colunas: "nome", "quantidade", "quantidade
                ideal", "unidadeMedida", "lote" e "dataValidade" (sendo
                dataValidade e unidadeMedida opcionais), escritas exatamente
                dessa forma. Os nomes dos produtos cuja quantidade pretende
                alterar, devem ser exatamente iguais aos nomes já existentes no
                stock, caso contrário serão criados novos produtos.
              </span>

              <span className="modalImportarXlsStock__spanModeloXlsx">
                <a
                  href="/modeloImportStock.xlsx"
                  download="modeloImportStock.xlsx"
                >
                  Baixar Modelo xlsx
                </a>
              </span>
            </div>

            <div>
              <div className="adicionar-despesa__modal__container__checkbox-pago__container">
                <input
                  type="checkbox"
                  name=""
                  id="adicionarComoDespesa-checkbox"
                  onChange={(e) => setAdicionarComoDespesa(e.target.checked)}
                  checked={adicionarComoDespesa}
                />
                <label htmlFor="adicionarComoDespesa-checkbox">
                  Adicionar os custos como despesa
                </label>
              </div>

              {adicionarComoDespesa && (
                <div className="modalImportarXlsStock__adicionarComoDespesa__container">
                  <InputText
                    className="modalImportarXlsStock__adicionarComoDespesa__container__span3"
                    label="Descrição *"
                    initialValue={descricao}
                    handleChange={descricaoChangeHandler}
                  />

                  <InputNumber
                    className="modalImportarXlsStock__adicionarComoDespesa__container__span1"
                    label="Custo Total *"
                    initialValue={custoTotal}
                    handleChange={custoTotalChangeHandler}
                  />

                  {categorias && categoria && (
                    <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span2">
                      <select
                        onChange={(e) => setCategoria(e.target.value)}
                        className="customInputSelect"
                        value={categoria}
                      >
                        {categorias &&
                          categoria &&
                          categorias.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                      <label className="customInputLabelSelect">
                        Categoria
                      </label>
                    </div>
                  )}

                  {caixas && caixa && (
                    <div className="customInputContainer modalImportarXlsStock__adicionarComoDespesa__container__span2">
                      <select
                        onChange={(e) => setCaixa(e.target.value)}
                        className="customInputSelect"
                        value={caixa}
                      >
                        {caixas &&
                          caixa &&
                          caixas.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.value}
                            </option>
                          ))}
                      </select>
                      <label className="customInputLabelSelect">Caixa</label>
                    </div>
                  )}

                  <div className="modalImportarXlsStock__adicionarComoDespesa__container__despesaPagaContainer modalImportarXlsStock__adicionarComoDespesa__container__span3">
                    <input
                      type="checkbox"
                      name=""
                      id="adicionarComoDespesa-checkbox"
                      onChange={(e) => setDespesaPaga(e.target.checked)}
                      checked={despesaPaga}
                    />
                    <label htmlFor="adicionarComoDespesa-checkbox">
                      Despesa Paga
                    </label>
                  </div>
                </div>
              )}
            </div>
            {errorMessage && (
              <div className="modalAdicionarFicheiros__errorMessage">
                {errorMessage}
              </div>
            )}
          </div>
          {errorMessage && (
            <div className="modalAdicionarFicheiros__errorMessage">
              {errorMessage}
            </div>
          )}
        </>
      ) : (
        <StepTwo
          processedData={processedData}
          setProcessedData={setProcessedData}
          categoriasStock={categoriasStock}
          handleSubmitImportStock={handleSubmitImportStock}
          produtos={produtos}
          stock={stock}
          auth={auth}
          clinica={clinica}
          sendRequest={sendRequest}
          atualizarStock={atualizarStock}
          handleCloseModalImportStock={handleCloseModalImportStock}
        />
      )}

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalImportStock}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        {currentStep === 2 && (
          <span
            disabled={isSubmitting || errorMessage || !file}
            className={
              isSubmitting || errorMessage || !file
                ? "cancel-btn"
                : `blue-button`
            }
            onClick={handleSubmitImportStock}
          >
            {isSubmitting ? "Processando..." : "Processar Arquivo"}
          </span>
        )}

        {currentStep === 1 && (
          <span
            disabled={isSubmitting || errorMessage || !file || !processedData}
            className={
              isSubmitting || errorMessage || !file || !processedData
                ? "cancel-btn"
                : `blue-button`
            }
            onClick={handleProceedToNextStep}
          >
            {isSubmitting ? "Processando..." : "Prosseguir"}
          </span>
        )}
      </div>
    </div>
  );
}

export default ModalImportXlsStock;
