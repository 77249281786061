import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { useHttpClient } from "../../../shared/hooks/http-hook";
//MUI
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
/////////////////////////////////////////////////////
import FiltrosFinanceiro from "./components/filtros/FiltrosFinanceiro";
import { generateExcelFile } from "../../../shared/components/excelUtils/exportExcel";
import { useLoading } from "../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import "./FluxoCaixa.scss";

function FluxoCaixa({ clinicaId, auth }) {
  const { startLoading, stopLoading } = useLoading();

  const [financialData, setFinancialData] = useState({
    totalRecebido: 0,
    totalReceber: 0,
    totalDespesas: 0,
    totalPorPagar: 0,
    saldo: 0,
  });

  const [dataFiltro, setDataFiltro] = useState({
    dataInicial: moment().format("DD/MM/YYYY"),
    dataFinal: moment().format("DD/MM/YYYY"),
  });

  const [resumoData, setResumoData] = useState({
    Dinheiro: 0,
    Cheque: 0,
    "Cheque Visado": 0,
    "Transferência de crédito": 0,
    "Internet banking": 0,
    "Cartão do banco": 0,
    "Débito direto da conta bancária": 0,
    "Cartão de crédito": 0,
    "Cartão de débito": 0,
  });

  const [rows, setRows] = useState([]);
  const [contasCorrentes, setContasCorrentes] = useState();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);

  const [despesas, setDespesas] = useState([]);
  const [despesasAbsolut, setDespesasAbsolut] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();

  const { sendRequest } = useHttpClient();

  const fetchContasCorrentesDespesaEntrada = async (startDate, endDate) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      const [responseDataCc, responseDespesas, responseDataEntradas] =
        await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/daterange/fluxocaixa/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/despesas/clinica/daterange/fluxocaixa/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/daterange/fluxocaixa/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

      const updateState = (data, permission, setState, setAbsoluteState) => {
        if (auth.perm.includes(permission)) {
          setState(data);
          setAbsoluteState(data);
        } else {
          setState([]);
          setAbsoluteState([]);
        }
      };

      updateState(
        responseDataCc.contasCorrentes,
        "r-rec",
        setContasCorrentes,
        setContasCorrentesAbsolut
      );
      updateState(
        responseDespesas.despesas,
        "r-des",
        setDespesas,
        setDespesasAbsolut
      );
      updateState(
        responseDataEntradas.entradas,
        "r-ent",
        setEntradas,
        setEntradasAbsolut
      );

      handleFiltrar(
        responseDataCc.contasCorrentes,
        responseDespesas.despesas,
        responseDataEntradas.entradas,
        [],
        null,
        null,
        null,
        {
          dataInicial: moment(startDate).format("DD/MM/YYYY"),
          dataFinal: moment(endDate).format("DD/MM/YYYY"),
        }
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchContasCorrentesDespesaEntrada(
      new Date().toISOString(),
      new Date().toISOString()
    );
  }, []);

  useEffect(() => {
    let recebido = 0;
    let porReceber = 0;
    let dataParaDataGrid = [];
    let totalDespesas = 0;
    let aPagar = 0;

    let quantiaPorMetodoPagamento = {
      Dinheiro: 0,
      Cheque: 0,
      "Cheque Visado": 0,
      "Transferência de crédito": 0,
      "Internet banking": 0,
      "Cartão do banco": 0,
      "Débito direto da conta bancária": 0,
      "Cartão de crédito": 0,
      "Cartão de débito": 0,
    };

    if (contasCorrentes) {
      contasCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          //A proxima linha de codigo já não é necessária pois filtro
          //contas que não estão aprovadas na backend
          // if (conta.aprovado) {
          conta.parcelas.forEach((parcela) => {
            recebido += parcela.quantiaPaga;
            if (parcela.status !== "CANCELADA") {
              porReceber += parcela.quantiaPorPagar;
            }

            //Verificar se a parcela está paga e se não é uma parcela de isenção
            //Caracterizada por ambos campos quantiaPaga e quantiaPorPagar com valor zero
            if (
              parcela.parcelaPaga &&
              !(parcela.quantiaPorPagar === 0 && parcela.quantiaPaga === 0)
            ) {
              quantiaPorMetodoPagamento[parcela.metodoPagamento] +=
                parcela.quantiaPaga;
              dataParaDataGrid.push({
                tipo: "receita",
                cliente: contaCorrente.cliente.nome,
                idCliente: contaCorrente.cliente._id,
                dadosCliente: contaCorrente.cliente,
                idContaCorrente: contaCorrente._id,
                orcamento: conta.orcamento,
                id: parcela._id,
                contaId: conta._id,
                dataLimitePagamento: parcela.dataLimitePagamento,
                dataPagamento: parcela.dataPagamento,
                quantiaPorPagar: parcela.quantiaPorPagar,
                quantiaPaga: parcela.quantiaPaga,
                metodoPagamento: parcela.metodoPagamento,
                entrada: parcela.entrada,
                nrParcela: parcela.nrParcela,
                parcelaPaga: parcela.parcelaPaga,
                estado: parcela.parcelaPaga
                  ? "Pago"
                  : moment()
                      .startOf("day")
                      .isAfter(
                        moment(parcela.dataLimitePagamento).startOf("day")
                      )
                  ? "Em atraso"
                  : "",
                documentoEletronico: parcela.documentoEletronico,
              });
            }
          });
          // }
        });
      });
    }

    if (despesas && despesas.length > 0) {
      despesas.forEach((despesa) => {
        if (!despesa.despesaPaga) aPagar += despesa.valor;
        else {
          quantiaPorMetodoPagamento[despesa.metodoPagamento] -= despesa.valor;
          totalDespesas += despesa.valor;
          dataParaDataGrid.push({
            tipo: "despesa",
            id: despesa.id,
            dataLimitePagamento: despesa.dataLimitePagamento,
            dataPagamento: despesa.dataPagamento,
            valor: despesa.valor,
            metodoPagamento: despesa.metodoPagamento,
            despesaPaga: despesa.despesaPaga,
            descricao: despesa.descricao,
            comprovativo: despesa.comprovativo,
            estado: despesa.despesaPaga
              ? "Pago"
              : moment()
                  .startOf("day")
                  .isAfter(moment(despesa.dataLimitePagamento).startOf("day"))
              ? "Em atraso"
              : "",
          });
        }
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        if (!entrada.entradaRecebida) {
          porReceber += entrada.valor;
        } else {
          recebido += entrada.valor;
          quantiaPorMetodoPagamento[entrada.metodoPagamento] += entrada.valor;
          dataParaDataGrid.push({
            tipo: "entrada",
            id: entrada.id,
            cliente: entrada.cliente,
            dataPagamento: entrada.dataPagamento,
            valor: entrada.valor,
            metodoPagamento: entrada.metodoPagamento,
            descricao: entrada.descricao,
            comprovativo: entrada.comprovativo,
            estado: "Pago",
            documentoEletronico: entrada.documentoEletronico,
          });
        }
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) => new Date(a.dataPagamento) - new Date(b.dataPagamento)
    );

    let saldo = 0;
    let finalDataParaDataGrid = [];

    dataParaDataGrid.forEach((dt) => {
      if (dt.tipo === "despesa") {
        saldo -= dt.valor;
      } else if (dt.tipo === "receita") {
        saldo += dt.quantiaPaga;
      } else {
        saldo += dt.valor;
      }

      finalDataParaDataGrid.push({
        ...dt,
        saldo: saldo.toLocaleString("pt-BR") + "$00",
      });
    });

    setFinancialData({
      totalRecebido: recebido.toLocaleString("pt-BR"),
      totalReceber: porReceber.toLocaleString("pt-BR"),
      totalDespesas: totalDespesas.toLocaleString("pt-BR"),
      totalPorPagar: aPagar.toLocaleString("pt-BR"),
      saldo: (recebido - totalDespesas).toLocaleString("pt-BR"),
    });

    setResumoData(quantiaPorMetodoPagamento);

    setRows(dataGridData(finalDataParaDataGrid));
  }, [contasCorrentes, despesas, entradas]);

  const handleFiltrar = (
    newContasCorrentes,
    newDespesas,
    newEntradas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet,
    data
  ) => {
    setFiltroCaixa(filtroCai);
    setFiltroCategoria(filtroCat);
    setFiltroMetodoPagamento(filtroMet);
    setFiltrosAplicados(filtros);
    if (newContasCorrentes) {
      setContasCorrentes([...newContasCorrentes]);
    }
    if (newDespesas) {
      setDespesas([...newDespesas]);
    }
    if (newEntradas) {
      setEntradas([...newEntradas]);
    }

    if (data !== "modalFiltros") setDataFiltro(data);
  };

  const handleExportar = () => {
    const sheetData = rows.map((r) => {
      return {
        descricao: r.descricao
          ? r.descricao
          : `${r.nome}${r.nrParcela ? " - Parcela " + r.nrParcela : ""}`,
        cliente: r.cliente,
        valor: r.valor,
        tipo: r.tipo,
        estado: r.estado,
        data_Pagamento: r.dataPagamento
          ? moment(r.dataPagamento).format("DD-MM-YYYY")
          : "-",
        dataLimitePagamento: r.dataLimitePagamento
          ? moment(r.dataLimitePagamento).format("DD-MM-YYYY")
          : "-",
        metodoPagamento: r.metodoPagamento ? r.metodoPagamento : "-",
      };
    });

    generateExcelFile(
      `HistoricoFinanceiro ${moment().format("DD-MM-YYYY")}.xlsx`,
      "Financeiro",
      sheetData
    );
  };

  const actionColumn = [
    {
      field: "inOrOut",
      headerName: "",
      width: 30,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
            )}
            {params.row.tipo === "despesa" && (
              <CallMadeIcon className="financeiro__container__cabecalho__totais--icon_div--despesa" />
            )}

            {params.row.tipo === "entrada" && (
              <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
            )}
          </>
        );
      },
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span className="spanCellPago">
                {moment(params.row.dataPagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "despesa" && (
              <span>
                {moment(params.row.dataPagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <span>
                {moment(params.row.dataPagamento).format("DD-MM-YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span className="spanCellPago">
                  {params.row.nome}{" "}
                  <Link
                    to={`/clientes/${params.row.idCliente}`}
                    className="link-cliente-receita"
                  >
                    {" "}
                    {params.row.cliente}
                  </Link>
                </span>
                {params.row.nrParcela && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="spanCellPago">
                {params.row.descricao}
                {params.row.cliente && (
                  <>
                    {" - "}
                    <Link
                      to={`/clientes/${params.row.cliente._id}`}
                      className="link-cliente-receita"
                    >
                      {params.row.cliente.nome}
                    </Link>
                  </>
                )}
              </div>
            )}

            {params.row.tipo === "despesa" && (
              <div className="spanCellPago">{params.row.descricao}</div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                <span
                  className={`debitos__container__estado ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__estado--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__estado--pago"
                      : ""
                  }`}
                >
                  {params.row.metodoPagamento
                    ? params.row.metodoPagamento
                    : params.row.estado}
                </span>
              </div>
            )}

            {params.row.tipo === "despesa" && (
              <div className="cellDebitoEstado">
                <span
                  className={`debitos__container__estado ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__estado--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__estado--pago"
                      : ""
                  }`}
                >
                  {params.row.metodoPagamento
                    ? params.row.metodoPagamento
                    : params.row.estado}
                </span>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellAction">
                <span
                  className={`debitos__container__valor ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__valor--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__valor--pago"
                      : ""
                  }`}
                  style={{ marginLeft: "auto" }}
                >
                  {params.row.valor}
                </span>
              </div>
            )}

            {(params.row.tipo === "despesa" ||
              params.row.tipo === "entrada") && (
              <span
                style={{
                  color: `${
                    params.row.tipo === "despesa" ? "crimson" : "inherit"
                  }`,
                }}
              >
                {params.row.tipo === "despesa" ? "-" : ""}
                {params.row.valor}
              </span>
            )}
          </>
        );
      },
    },
    {
      field: "saldo",
      headerName: "Saldo",
      flex: 1,
      renderCell: (params) => {
        return <span>{params.row.saldo}</span>;
      },
    },
  ];

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosFinanceiro
          contasCorrentes={contasCorrentesAbsolut}
          despesas={despesasAbsolut}
          entradas={entradasAbsolut}
          handleFiltrar={handleFiltrar}
          handleExportar={handleExportar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          clinicaId={clinicaId}
          auth={auth}
          fetchContasCorrentesDespesaEntrada={
            fetchContasCorrentesDespesaEntrada
          }
        />
      </div>

      <div className="financeiro__container__cabecalho">
        <div className="financeiro__container__cabecalho__totais">
          <div className="financeiro__container__cabecalho__totais--recebido">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--recebido">
              <div className="financeiro__container__cabecalho__totais--icon_div">
                <span className="financeiro__container__cabecalho__totais--titulos">
                  Receitas
                </span>
                <CallReceivedIcon className="financeiro__container__cabecalho__totais--icon_div--receita" />
              </div>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--recebido--valor_principal">
                  {financialData.totalRecebido}$00
                </span>
                <span className="financeiro__container__cabecalho__totais--texto--info">
                  A receber {financialData.totalReceber}$00
                </span>
              </div>
            </div>
          </div>
          <span className="financeiro__container__cabecalho__totais--separador">
            -
          </span>
          <div className="financeiro__container__cabecalho__totais--por-receber">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--por-receber">
              <div className="financeiro__container__cabecalho__totais--icon_div">
                <span className="financeiro__container__cabecalho__totais--titulos">
                  Despesas
                </span>
                <CallMadeIcon className="financeiro__container__cabecalho__totais--icon_div--despesa" />
              </div>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--por-receber--valor_principal">
                  {financialData.totalDespesas}$00
                </span>
                <span className="financeiro__container__cabecalho__totais--texto--info">
                  A pagar {financialData.totalPorPagar}$00
                </span>
              </div>
            </div>
          </div>
          <span className="financeiro__container__cabecalho__totais--separador">
            =
          </span>
          <div className="financeiro__container__cabecalho__totais--saldo">
            <div className="financeiro__container__cabecalho__totais__firstContainer financeiro__container__cabecalho__totais__firstContainer--saldo">
              <span className="financeiro__container__cabecalho__totais--titulos">
                Saldo
              </span>
              <div className="financeiro__container__cabecalho__totais--texto">
                <span className="financeiro__container__cabecalho__totais--saldo--valor_principal">
                  {financialData.saldo}$00
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            columns={actionColumn}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </div>
      )}

      <div className="fluxoCaixa__resumo__container">
        <div className="fluxoCaixa__resumo__cabecalho">
          {dataFiltro === "todos"
            ? "Resumo Total"
            : `Resumo de ${dataFiltro.dataInicial}  a ${dataFiltro.dataFinal}`}
        </div>

        <div className="fluxoCaixa__resumo__body">
          <span className="fluxoCaixa__resumo__title">
            Totais por meio de Pagamento
          </span>
          <div className="fluxoCaixa__resumo__list">
            <div className="fluxoCaixa__resumo__list__left">
              {Object.entries(resumoData)
                .slice(0, 5)
                .map(([key, value]) => (
                  <div
                    className="fluxoCaixa__resumo__list__left__item"
                    key={key}
                  >
                    <span>{key}</span>
                    <span>{value.toLocaleString("pt-BR")}$00</span>
                  </div>
                ))}
            </div>
            <div className="fluxoCaixa__resumo__list__right">
              {Object.entries(resumoData)
                .slice(5)
                .map(([key, value]) => (
                  <div
                    className="fluxoCaixa__resumo__list__right__item"
                    key={key}
                  >
                    <span>{key}</span>
                    <span>{value.toLocaleString("pt-BR")}$00</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FluxoCaixa;
