import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import "../List.scss";

//mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete"; //Delete icon
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"; //Edit icon
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"; //gerar Orcamento
import Tooltip from "@mui/material/Tooltip";
//Antd
import { Popover } from "antd";
import { Popconfirm } from "antd";
import moment from "moment";

function ListAvaliacao(props) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  let content = [];
  if (props.data) {
    props.data.forEach((row) => {
      let vazio = true;
      if (
        !row.terminado &&
        !row.transformado &&
        row.status !== "INTERROMPIDO"
      ) {
        vazio = false;
        content.push(
          <div className="dots__menu__popup">
            {/* Editar */}
            {auth.perm.includes("u-aval") && (
              <div
                className="popOverMenu--option"
                onClick={() => navigate(`/avaliacao/edit/${row.id}`)}
              >
                <span>Editar</span>
                <ModeEditOutlineOutlinedIcon className="popOverMenu--option__icon" />
              </div>
            )}
            {/* Desativar Avaliacao */}

            {auth.perm.includes("d-aval") && (
              <Popconfirm
                title="Desativação de avaliação"
                description="Tem a certeza que pretende desativar a avaliação?"
                icon={<DeleteIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={props.handleDesativar.bind(null, row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Desativar</span>
                  <DeleteIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );
      }

      if (
        row.transformado &&
        !row.terminado &&
        !row.fechado &&
        row.status !== "INTERROMPIDO"
      ) {
        vazio = false;
        content.push(
          <div className="dots__menu__popup">
            {auth.perm.includes("d-aval") && (
              <Popconfirm
                title="Fecho de avaliação"
                description="Tem a certeza que pretende fechar a avaliação?"
                icon={<VpnKeyIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={props.handleFecharAbrir.bind(null, row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Fechar</span>
                  <VpnKeyIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );
      }

      if (row.fechado && row.status !== "INTERROMPIDO") {
        vazio = false;
        content.push(
          <div className="dots__menu__popup">
            {auth.perm.includes("d-aval") && (
              <Popconfirm
                title="Abertura de avaliação"
                description="Tem a certeza que pretende abrir a avaliação?"
                icon={<VpnKeyIcon style={{ color: "red" }} />}
                okText="Sim"
                cancelText="Não"
                onConfirm={props.handleFecharAbrir.bind(null, row.id)}
              >
                <div className="popOverMenu--option">
                  <span>Abrir</span>
                  <VpnKeyIcon className="popOverMenu--option__icon" />
                </div>
              </Popconfirm>
            )}
          </div>
        );
      }

      if (vazio) {
        content.push("vazio");
      }
    });
  }
  return (
    <>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Código
              </TableCell>
              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Criado por
              </TableCell>
              <TableCell className="tableCell tableCell--heading tableCell--heading--start">
                Médico Responsável
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Data Avaliação
              </TableCell>

              <TableCell className="tableCell tableCell--heading">
                Estado
              </TableCell>

              <TableCell className="tableCell tableCell--heading tableCell--heading--end">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>

          {props.data && (
            <TableBody>
              {props.data.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="tableCell">
                    {"AV" + row.numero + "_" + moment(row.created_at).year()}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.criado_por?.name}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.medico_responsavel.name}
                  </TableCell>
                  <TableCell className="tableCell">
                    {row.data_avaliacao}
                  </TableCell>

                  <TableCell className="tableCell">
                    <span
                      className={`status ${
                        row.status
                          ? row.status
                          : row.terminado && !row.fechado
                          ? "concluido"
                          : row.transformado && !row.fechado
                          ? "transformado"
                          : !row.terminado && !row.transformado && !row.fechado
                          ? "pendente"
                          : row.fechado
                          ? "fechado"
                          : ""
                      }`}
                    >
                      {row.status === "INTERROMPIDO"
                        ? "Interrompido"
                        : row.terminado && !row.fechado
                        ? "Concluido"
                        : row.transformado && !row.fechado
                        ? "Transformado"
                        : !row.transformado && !row.terminado && !row.fechado
                        ? "Criado"
                        : row.fechado
                        ? "Fechado"
                        : ""}
                    </span>
                  </TableCell>

                  <TableCell className="tableCell">
                    <div className="tableCell__action">
                      {/* Gerar Orçamento */}
                      {auth.perm.includes("c-orc") &&
                        !row.terminado &&
                        !row.fechado && (
                          <Tooltip title="Gerar Orçamento">
                            <ArticleOutlinedIcon
                              className="icon__list"
                              onClick={() =>
                                navigate(`/orcamentos/new/${row.id}`)
                              }
                            />
                          </Tooltip>
                        )}
                      {(!auth.perm.includes("c-orc") || row.terminado) && (
                        <div>&#12644;&#12644;&#12644;</div>
                      )}
                      {auth.perm.includes("r-aval") && (
                        <Tooltip title="Ver Avaliação">
                          <RemoveRedEyeIcon
                            className="icon__list"
                            onClick={props.handleOpenModalAvaliacao.bind(
                              null,
                              row.id
                            )}
                          />
                        </Tooltip>
                      )}
                      {!auth.perm.includes("r-aval") && (
                        <div>&#12644;&#12644;&#12644;</div>
                      )}

                      {content[index] !== "vazio" && (
                        <Popover
                          content={content[index]}
                          trigger="click"
                          placement="bottom"
                        >
                          <div className="dots__menu">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                          </div>
                        </Popover>
                      )}
                      {!content[index] && <div>&#12644;&#12644;&#12644;</div>}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default ListAvaliacao;
