import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import uuid from "react-uuid";

import validarPrescricao from "./helperFunctions/validation";

import "moment/locale/pt";

import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import ElementoPrescricao from "./components/ElementoPrescricao";
import PdfPrescricaoWrapper from "../../../shared/components/PdfGenerator/PdfPrescricao/PdfPrescricaoWrapper";
import SugestaoForm from "./components/SugestaoForm";

import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";

import ModalInfo from "../../../shared/components/UIElements/ModalInfo";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tooltip from "@mui/material/Tooltip";
import { useLoading } from "../../../shared/context/LoadingContext";
import "../../../style/formError.scss";
import "./PrescricaoSugestoes.scss";
import "./NewPrescricao.scss";

function NewPrescricao() {
  const { startLoading, stopLoading } = useLoading();

  const [showPdf, setShowPdf] = useState(false);
  const [pdfData, setPdfData] = useState();
  const [medicamentos, setMedicamentos] = useState([]);
  const [sugestoes, setSugestoes] = useState();
  const [cliente, setCliente] = useState();
  const [clinicaData, setClinicaData] = useState();
  const [medicoData, setMedicoData] = useState();
  const [sugestaoModalOpen, setSugestaoModalOpen] = useState(false);
  const [nrMedicamentos, setNrMedicamentos] = useState(1);
  const [serviceList, setServiceList] = useState([
    {
      id: "1",
      medicamento: "",
      duracao: "",
      posologia: "",
      qtdd: "",
      tipo: "",
      show: true,
    },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const clienteId = useParams().clienteId;

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
  });

  //fetch receita, medicos e avaliacao
  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          medicamentosResponse,
          sugestoesResponse,
          clienteResponse,
          clinicaResponse,
          medicoResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/medicamentos/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesPrescricao/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${clienteId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${auth.userId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process medicamentos
        if (medicamentosResponse.medicamentos) {
          setMedicamentos(
            medicamentosResponse.medicamentos.map((med) => ({
              label: med.nome,
              id: med.id,
            }))
          );
        }

        // Process sugestoes
        setSugestoes(
          sugestoesResponse.sugestoesPrescricao.map((s) => ({
            id: s.id,
            medicamento: s.sugestao.medicamento.nome,
            duracao: s.sugestao.duracao,
            quantidade: s.sugestao.quantidade,
            tipo: s.sugestao.tipo,
            posologia: s.sugestao.posologia,
          }))
        );

        // Set cliente
        setCliente(clienteResponse.cliente);

        // Set clinica data
        setClinicaData(clinicaResponse.clinica);

        // Set medico data
        setMedicoData(medicoResponse.user);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clinica.clinica._id, clienteId, auth.userId, auth.token]);

  const showPreviousPage = () => {
    setShowPdf(false);
  };

  const medicamentoChangeHandler = (id, medicamento) => {
    serviceList.forEach((ser) => {
      if (ser.id === id) ser.medicamento = medicamento;
    });
  };

  const duracaoChangeHandler = (id, duracao) => {
    serviceList.forEach((ser) => {
      if (ser.id === id) ser.duracao = duracao;
    });
  };

  const posologiaChangeHandler = (id, posologia) => {
    serviceList.forEach((ser) => {
      if (ser.id === id) ser.posologia = posologia;
    });
  };

  const qtddChangeHandler = (id, qtdd) => {
    serviceList.forEach((ser) => {
      if (ser.id === id) ser.qtdd = qtdd;
    });
  };

  const tipoChangeHandler = (id, tipo) => {
    serviceList.forEach((ser) => {
      if (ser.id === id) ser.tipo = tipo;
    });
  };

  const addSugestao = (id) => {
    const sugestao = sugestoes.filter((s) => s.id === id)[0];
    let adicionado = false;

    setServiceList((prevServiceList) => {
      return prevServiceList.map((serv, index) => {
        if (
          !serv.medicamento &&
          !serv.duracao &&
          !serv.qtdd &&
          !serv.tipo &&
          !serv.posologia &&
          serv.show &&
          !adicionado
        ) {
          adicionado = true;
          return {
            ...serv,
            id: index === 0 ? "1" : uuid(),
            medicamento: sugestao.medicamento,
            duracao: sugestao.duracao,
            posologia: sugestao.posologia,
            qtdd: sugestao.quantidade,
            tipo: sugestao.tipo,
            show: true,
          };
        }
        return serv;
      });
    });

    if (!adicionado && nrMedicamentos < 3) {
      setNrMedicamentos((current) => current + 1);
      setServiceList([
        ...serviceList,
        {
          id: uuid(),
          medicamento: sugestao.medicamento,
          duracao: sugestao.duracao,
          posologia: sugestao.posologia,
          qtdd: sugestao.quantidade,
          tipo: sugestao.tipo,
          show: true,
        },
      ]);
    }
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return {
        ...currentModalProps,
        show: false,
      };
    });
  };

  const addService = (e) => {
    setNrMedicamentos((current) => current + 1);
    setServiceList([
      ...serviceList,
      {
        id: uuid(),
        medicamento: "",
        duracao: "",
        posologia: "",
        qtd: "",
        tipo: "",
        show: true,
      },
    ]);
  };

  const removeService = (id) => {
    setNrMedicamentos((current) => current - 1);

    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice] = { show: false };
    setServiceList([...list]);
  };

  const submitHandler = async () => {
    startLoading();
    try {
      const formErrorMessage = validarPrescricao(serviceList);
      if (!formErrorMessage) {
        const filteredServiceList = [];
        const medicamentosVar = medicamentos;

        //Gerando Filtered service List para lists com show = true
        //E Tornando os medicamentos lower case com a primeira letra em upper
        //Para evitar que mesmos medicamentos escritos com capitals diferentes sejam repetidos na bd
        serviceList.forEach((service) => {
          if (service.show) {
            if (service.medicamento.label) {
              filteredServiceList.push({
                medicamento: {
                  id: service.medicamento.id,
                  label:
                    service.medicamento.label.charAt(0).toUpperCase() +
                    service.medicamento.label.toLowerCase().slice(1),
                },
                duracao: service.duracao,
                posologia: service.posologia,
                qtdd: service.qtdd,
                tipo: service.tipo,
              });
            } else {
              filteredServiceList.push({
                medicamento:
                  service.medicamento.charAt(0).toUpperCase() +
                  service.medicamento.toLowerCase().slice(1),
                duracao: service.duracao,
                posologia: service.posologia,
                qtdd: service.qtdd,
                tipo: service.tipo,
              });
            }
          }
        });

        //Adicionando Ids aos medicamentos que ja têm id
        filteredServiceList.forEach((service) => {
          medicamentosVar.forEach((med) => {
            if (service.medicamento.label) {
              if (service.medicamento.label === med.label) {
                service.medicamento = med;
              }
            } else {
              if (service.medicamento === med.label) {
                service.medicamento = med;
              }
            }
          });
        });

        //Apanhando todos os medicamentos em string da Service List
        let medicamentosLista = filteredServiceList.map((service) => {
          if (service.medicamento.label) {
            return service.medicamento.label;
          } else return service.medicamento;
        });

        //Caso o usuario repetiu medicamentos, filtramos os repetidos com o uso de Set
        let tempMedicamentosLista = new Set(medicamentosLista);
        tempMedicamentosLista = Array.from(tempMedicamentosLista);
        medicamentosLista = tempMedicamentosLista;

        //Filtrando os medicamentos que já tem id
        medicamentosLista.forEach((medLista) => {
          medicamentosVar.forEach((med) => {
            if (med.label === medLista) {
              tempMedicamentosLista = tempMedicamentosLista.filter(
                (medicamento) => medicamento !== med.label
              );
            }
          });
        });

        const medicamentosAInserir = JSON.stringify(tempMedicamentosLista);
        let respostaMedicamentos = null;

        //Guarando medicamentos novos se houver
        if (tempMedicamentosLista.length > 0) {
          const formData = new FormData();
          formData.append("medicamentos", medicamentosAInserir);
          formData.append("clinicaId", clinica.clinica._id);

          try {
            respostaMedicamentos = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/medicamentos`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        //Com os medicamentos adicionados à bd, adicionar id aos medicamentos da service list
        //Atualizar a state dos medicamentos adicionando os agora inseridos na bd
        if (respostaMedicamentos) {
          setMedicamentos((prev) => {
            return { ...prev, ...respostaMedicamentos };
          });

          filteredServiceList.forEach((service) => {
            if (!service.medicamento.id) {
              respostaMedicamentos.medicamentos.forEach((res) => {
                if (res.nome === service.medicamento) {
                  service.medicamento = { label: res.nome, id: res.id };
                }
              });
            }
          });
        }

        // guardando a propria prescricao
        const prescricoes = filteredServiceList.map((service) => {
          return {
            medicamento: service.medicamento.id,
            duracao: service.duracao,
            posologia: service.posologia,
            quantidade: service.qtdd,
            tipo: service.tipo,
          };
        });

        const formData2 = new FormData();
        formData2.append("clienteId", clienteId);
        formData2.append("medicoId", auth.userId);
        formData2.append("clinicaId", clinica.clinica._id);
        formData2.append("prescricoes", JSON.stringify(prescricoes));
        formData2.append("criadoPor", auth.userId);

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/prescricoes`,
            "POST",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        setPdfData({
          cliente: cliente,
          clinica: clinicaData,
          medico: medicoData,
          data: filteredServiceList.map((el) => {
            if (el.medicamento.label) {
              return {
                medicamento: el.medicamento.label,
                duracao: el.duracao,
                posologia: el.posologia,
                quantidade: el.qtdd,
                tipo: el.tipo,
              };
            } else {
              return {
                medicamento: el.medicamento,
                duracao: el.duracao,
                posologia: el.posologia,
                quantidade: el.qtdd,
                tipo: el.tipo,
              };
            }
          }),
        });
        setShowPdf(true);
      } else {
        toast.error(formErrorMessage);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  const handleAdicionarSugestao = async (
    medicamento,
    duracao,
    qtdd,
    tipo,
    posologia
  ) => {
    if (
      !medicamento ||
      !duracao ||
      !qtdd ||
      !tipo ||
      !posologia ||
      medicamento.trim() === "" ||
      duracao.trim() === "" ||
      qtdd.trim() === "" ||
      tipo.trim() === "" ||
      posologia.trim() === ""
    ) {
      toast.error("Preencha todos os campos");
    } else if (isNaN(qtdd)) {
      toast.error("Quantidade deve ser um valor numérico");
    } else {
      startLoading();
      try {
        let respostaMedicamentos;
        if (!medicamento.id) {
          medicamento =
            medicamento.charAt(0).toUpperCase() +
            medicamento.toLowerCase().slice(1);

          medicamentos.forEach((med) => {
            if (medicamento === med.label) {
              medicamento = med;
            }
          });
        }

        if (!medicamento.id) {
          const formData = new FormData();
          formData.append("medicamentos", JSON.stringify([medicamento]));
          formData.append("clinicaId", clinica.clinica._id);
          try {
            respostaMedicamentos = await sendRequest(
              `${process.env.REACT_APP_BACKEND_LINK}/api/medicamentos`,
              "POST",
              formData,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) {
            console.error("err", err);
          }
        }

        if (respostaMedicamentos) {
          setMedicamentos((prev) => {
            return [
              ...prev,
              {
                label: respostaMedicamentos.medicamentos[0].nome,
                id: respostaMedicamentos.medicamentos[0].id,
              },
            ];
          });
          medicamento = respostaMedicamentos.medicamentos[0];
        }

        const sugest = {
          medicamento: medicamento.id,
          duracao: duracao,
          posologia: posologia,
          quantidade: qtdd,
          tipo: tipo,
        };

        const formData2 = new FormData();
        formData2.append("clinicaId", clinica.clinica._id);
        formData2.append("sugestao", JSON.stringify(sugest));
        formData2.append("criadoPor", auth.userId);
        let resposta;
        try {
          resposta = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesPrescricao`,
            "POST",
            formData2,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        if (resposta) {
          setSugestoes((prev) => {
            return [
              ...prev,
              {
                id: resposta.sugestaoPrescricao._id,
                medicamento:
                  resposta.sugestaoPrescricao.sugestao.medicamento.nome,
                duracao: sugest.duracao,
                posologia: sugest.posologia,
                quantidade: sugest.quantidade,
                tipo: sugest.tipo,
              },
            ];
          });
        }
        closeModalSugestao();
        toast.success("Sugestão guardada com sucesso");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
  };

  const deleteSugestao = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesPrescricao/${id}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setSugestoes((current) => {
        return current.filter((c) => c.id !== id);
      });
      toast.success("Sugestão apagada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      handleModalCancel();
      stopLoading();
    }
  };

  const sugestãoDeletionConfirmation = (id) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Eliminação de sugestão!`,
      message: `Tem a certeza que pretende eliminar a sugestão?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: deleteSugestao.bind(null, id),
    });
  };

  const closeModalSugestao = () => {
    setSugestaoModalOpen(false);
  };

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  return (
    <>
      {showPdf && pdfData && (
        <PdfPrescricaoWrapper
          showPreviousPage={showPreviousPage}
          prescricaoData={pdfData}
        />
      )}

      {!showPdf && (
        <>
          <ModalInfo {...modalProps} />
          <div className="new">
            <Sidebar />
            <div className="newContainer">
              {cliente && (
                <Navbar
                  title={`Gerar Prescrição - ${cliente.nome}`}
                  icon={MedicationOutlinedIcon}
                />
              )}
              <div className="bottom-orcamento caixa">
                {serviceList.map(
                  (singleService, i) =>
                    singleService.show && (
                      <div key={i} className="receita__item">
                        <ElementoPrescricao
                          id={singleService.id}
                          medicamento={singleService.medicamento}
                          duracao={singleService.duracao}
                          posologia={singleService.posologia}
                          qtdd={singleService.qtdd}
                          tipo={singleService.tipo}
                          medicamentos={medicamentos}
                          medicamentoChangeHandler={medicamentoChangeHandler}
                          duracaoChangeHandler={duracaoChangeHandler}
                          tipoChangeHandler={tipoChangeHandler}
                          posologiaChangeHandler={posologiaChangeHandler}
                          qtddChangeHandler={qtddChangeHandler}
                          removeService={removeService}
                        />
                      </div>
                    )
                )}

                {nrMedicamentos < 3 && (
                  <button className="add-btn" onClick={addService}>
                    +
                  </button>
                )}

                <hr className="prescricao__hr" />
                {sugestoes && (
                  <div className="prescricao-sugestoes-container">
                    {sugestoes.map((s, i) => (
                      <div className="prescricao-sugestoes-row" key={i}>
                        <div className="prescricao-sugestoes">
                          {`${s.quantidade} ${
                            s.quantidade > 1 ? "embalagens" : "embalagem"
                          } de ${s.medicamento}, ${s.tipo}. ${
                            s.posologia
                          } durante ${s.duracao} ${
                            s.duracao > 1 ? "dias" : "dia"
                          }`}
                        </div>

                        <div className="elemento-avaliacao-btns-container">
                          {nrMedicamentos <= 3 && (
                            <>
                              <Tooltip title="Adicionar">
                                <button
                                  className="btn-acoes-avaliacao btn-adicionar"
                                  onClick={addSugestao.bind(null, s.id)}
                                >
                                  <AddIcon className="mdi mdi-adicionar" />
                                  <AddCircleOutlineIcon className="mdi mdi-adicionar-empty" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Remover">
                                <button
                                  className="btn-acoes-avaliacao btn-delete"
                                  onClick={sugestãoDeletionConfirmation.bind(
                                    null,
                                    s.id
                                  )}
                                >
                                  <DeleteIcon className="mdi mdi-delete" />
                                  <DeleteForeverIcon className="mdi mdi-delete-empty" />
                                </button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <Button
                  variant="outlined"
                  onClick={() => setSugestaoModalOpen(true)}
                >
                  Adicionar Sugestão
                </Button>

                <SugestaoForm
                  medicamentos={medicamentos}
                  handleAdicionarSugestao={handleAdicionarSugestao}
                  sugestaoModalOpen={sugestaoModalOpen}
                  closeModalSugestao={closeModalSugestao}
                />
                <div className="botoes">
                  <button
                    className="cancel-btn botao"
                    onClick={() => navigate(`../../../clientes/${clienteId}`)}
                  >
                    Cancelar
                  </button>

                  <button
                    disabled={false}
                    className="blue-button"
                    onClick={preSubmitHandler}
                  >
                    {isSubmitting ? "Gerando..." : "Gerar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewPrescricao;
