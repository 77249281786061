import { useEffect, useContext, useState } from "react";
import { Collapse } from "antd";
import TodayIcon from "@mui/icons-material/Today";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import "./Permissoes.scss";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import toast from "react-hot-toast";
import { useLoading } from "../../../shared/context/LoadingContext";
import ModalAdicionarPerfil from "./components/ModalAdicionarPerfil";
import ModalEditarPerfil from "./components/ModalEditarPerfil";
import ModalInfo from "../../../shared/components/UIElements/ModalInfo";
function Permissoes() {
  const { startLoading, stopLoading } = useLoading();

  const [grupos, setGrupos] = useState();
  const [permissoes, setPermissoes] = useState();
  const [perfis, setPerfis] = useState([]);
  const [users, setUsers] = useState();
  const [selectedPerfil, setSelectedPerfil] = useState();
  const [selectedPerfilId, setSelectedPerfilId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalData, setModalData] = useState({ open: "" });
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const { Panel } = Collapse;

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  useEffect(() => {
    const getListaPermissoesAndPerfis = async () => {
      startLoading();
      try {
        const responseDataPermissoes = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/permissoes`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseDataUsers = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/clinica/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        setUsers(responseDataUsers.users);

        const responsePerfis = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/perfis/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        //Criando os grupos com set para nao repetirem
        //Criando uma lista geral com check predefinido a false para usuarios cujas
        //Permissoes ainda não estão definidas
        const groups = new Set();
        let tempListaPermissoesGeral = [];
        responseDataPermissoes.permissoes.forEach((el) => {
          tempListaPermissoesGeral.push({ ...el, check: false });
          groups.add(el.grupo);
        });

        setPermissoes(tempListaPermissoesGeral);
        setGrupos(Array.from(groups));

        setPerfis(responsePerfis.perfis);
        setSelectedPerfilId(responsePerfis.perfis[0]._id);
        setSelectedPerfil(responsePerfis.perfis[0]);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    getListaPermissoesAndPerfis();
  }, [sendRequest, auth, clinica]);

  const onChangeCollapse = (key) => {};

  const handlePerfilChange = (e) => {
    const filteredPerfil = perfis.filter((p) => p.id === e.target.value)[0];
    setSelectedPerfilId(e.target.value);
    setSelectedPerfil({ ...filteredPerfil });
  };

  const changePermissionCheck = (id, e) => {
    const tempPermissoes = [...selectedPerfil.permissoes];
    const indexPermissao = tempPermissoes.findIndex((per) => per.id === id);
    tempPermissoes[indexPermissao].check = e.target.checked;
    setSelectedPerfil((prev) => {
      return {
        ...prev,
        permissoes: tempPermissoes,
      };
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    startLoading();
    try {
      const formData = new FormData();
      const tempPermissoes = selectedPerfil.permissoes.map((p) => {
        return {
          permissao: p.permissao.id,
          check: p.check,
        };
      });
      formData.append("permissoes", JSON.stringify(tempPermissoes));

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/perfis/${selectedPerfilId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Permissoes atualizadas com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
    setIsSubmitting(false);
  };

  const checkAllHandler = (gr, ch) => {
    const tempSelectedPerfil = { ...selectedPerfil };
    const tempPermissoes = tempSelectedPerfil.permissoes.map((per) => {
      if (per.permissao.grupo === gr) {
        return {
          ...per,
          check: ch,
        };
      } else {
        return per;
      }
    });
    tempSelectedPerfil.permissoes = tempPermissoes;
    setSelectedPerfil({ ...tempSelectedPerfil });
  };

  const handleCloseModal = () => {
    setModalData({ open: "" });
  };

  const handleUpdatePerfis = (perfilRetornado, operacao) => {
    if (operacao === "adicionar") {
      const tempPerfis = [...perfis];
      tempPerfis.push(perfilRetornado);

      setPerfis(tempPerfis);
    }
    if (operacao === "editar") {
      setSelectedPerfil(perfilRetornado);
      setPerfis((current) =>
        current.map((c) =>
          c._id === perfilRetornado._id ? perfilRetornado : c
        )
      );
    }
  };

  const handleEliminarPerfil = async () => {
    const utilizadoresComOPerfil = [];

    users.forEach((u) => {
      if (u.perfis.includes(selectedPerfil._id)) {
        utilizadoresComOPerfil.push(u.name);
      }
    });

    if (utilizadoresComOPerfil.length > 0) {
      toast.error(
        `Não pode eliminar perfis associados a utilizadores: ${utilizadoresComOPerfil.join(
          " ,"
        )}`
      );
      handleModalCancel();
    } else {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/perfis/${selectedPerfilId}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const newPerfis = perfis.filter((p) => p._id !== selectedPerfil._id);

      setPerfis([...newPerfis]);
      setSelectedPerfil({ ...newPerfis[0] });
      setSelectedPerfilId(newPerfis[0]._id);
      toast.success("Perfil eliminado com sucesso!");
      handleModalCancel();
    }
  };

  const handleBeforeEliminarPerfil = async () => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Eliminar Perfil!`,
      message: `Tem a certeza que pretende eliminar o perfil selecionado?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: handleEliminarPerfil,
    });
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  return (
    <>
      <ModalInfo {...modalProps} />
      <div className="normalPage__container">
        <div className="permissoes__headerContainer">
          <div className="permissoes__select__container">
            {perfis && (
              <>
                <span>Perfil: </span>
                <select
                  className="select__filter-categoria"
                  onChange={handlePerfilChange}
                >
                  {perfis.map((p, index) => (
                    <option value={p.id} key={index}>
                      {p.nome}
                    </option>
                  ))}
                </select>
                <button
                  onClick={() =>
                    setModalData({
                      open: "editarPerfil",
                      perfil: selectedPerfil,
                    })
                  }
                  className="blue-button"
                >
                  Editar
                </button>

                <button
                  onClick={handleBeforeEliminarPerfil}
                  className="red-button"
                >
                  Eliminar
                </button>
              </>
            )}
          </div>
          <div className="permissoes__headerContainer__buttons">
            <button
              onClick={() => setModalData({ open: "adicionarPerfil" })}
              className="blue-button"
            >
              Adicionar Perfil
            </button>
          </div>
        </div>
        <Collapse defaultActiveKey={["0"]} onChange={onChangeCollapse}>
          {grupos &&
            grupos.map((grupo, index) => (
              <Panel
                header={
                  <div className="collapse__title">
                    <TodayIcon className="collapse__title__icon" />
                    <span className="collapse__title__text">{grupo}</span>
                  </div>
                }
                key={index}
              >
                <div className="collapse__content__container" key={index}>
                  {selectedPerfil &&
                    selectedPerfil.permissoes &&
                    selectedPerfil.permissoes.map((per, i) => {
                      if (per.permissao.grupo === grupo) {
                        return (
                          <div key={i}>
                            <label
                              htmlFor={per.id}
                              className="collapse__labelCheckbox"
                            >
                              <input
                                type="checkbox"
                                name=""
                                id={per.id}
                                checked={per.check}
                                onChange={changePermissionCheck.bind(
                                  null,
                                  per.id
                                )}
                              />
                              {per.permissao.permissao}
                            </label>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}

                  <div
                    key={selectedPerfil?.permissoes?.length}
                    className="collapse__checkbox--all__container"
                  >
                    <label
                      htmlFor={`checkbox--all__${grupo}`}
                      className="collapse__labelCheckbox collapse__labelCheckbox--all"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id={`checkbox--all__${grupo}`}
                        onChange={(e) =>
                          checkAllHandler(grupo, e.target.checked)
                        }
                      />
                      Marcar todos
                    </label>
                  </div>
                </div>
              </Panel>
            ))}
        </Collapse>
        <div className="permissoes__btn__container">
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {!isSubmitting ? "Guardar" : "Guardando"}
          </span>
        </div>
      </div>

      {modalData.open === "adicionarPerfil" && (
        <ModalAdicionarPerfil
          open={modalData.open === "adicionarPerfil"}
          handleCloseModal={handleCloseModal}
          sendRequest={sendRequest}
          auth={auth}
          permissoes={permissoes}
          handleUpdatePerfis={handleUpdatePerfis}
          clinicaId={clinica.clinica._id}
          modalData={modalData}
        />
      )}

      {modalData.open === "editarPerfil" && (
        <ModalEditarPerfil
          open={modalData.open === "editarPerfil"}
          handleCloseModal={handleCloseModal}
          sendRequest={sendRequest}
          auth={auth}
          handleUpdatePerfis={handleUpdatePerfis}
          modalData={modalData}
        />
      )}
    </>
  );
}

export default Permissoes;
