import React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalVerPlanoTratamentoInterrompido.scss";
import DoneIcon from "@mui/icons-material/Done";

function ModalVerPlanoTratamentoInterrompido({
  plano,
  openModal,
  handleCloseModal,
}) {
  const [planoTratamento, setPlanoTratamento] = useState();

  useEffect(() => {
    const sortedProcedimentos = [...plano.procedimentos].sort((a, b) => {
      if (a.procedimento_completo && !b.procedimento_completo) {
        return 1;
      }
      if (!a.procedimento_completo && b.procedimento_completo) {
        return -1;
      }
      return 0;
    });
    const planoFinal = { ...plano, procedimentos: sortedProcedimentos };
    setPlanoTratamento(planoFinal);
  }, [plano]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
      <Box className="fluxo-atendimento__modal__box" id="modal-ver-plano">
        <div className="verPlanoInterrompido caixa">
          <span className="verPlanoInterrompido__tabela-heading verPlanoInterrompido__tabela-heading--1st">
            Tratamento
          </span>
          <span className="verPlanoInterrompido__tabela-heading">Dentes</span>
          <span className="verPlanoInterrompido__tabela-heading">Faces</span>
          <span className="verPlanoInterrompido__tabela-heading">Sessões</span>
          <span className="verPlanoInterrompido__tabela-heading">Status</span>
          {planoTratamento &&
            planoTratamento.procedimentos.map(
              (p, index) =>
                !p.procedimento_completo && (
                  <React.Fragment key={index}>
                    <span className="verPlanoInterrompido__lineItem verPlanoInterrompido__lineItem--1st">
                      {p.tratamento.designacao}
                    </span>
                    <span className="verPlanoInterrompido__lineItem">
                      {p.dente}
                    </span>
                    <span className="verPlanoInterrompido__lineItem">
                      {p.faces.join(" ,")}
                    </span>

                    <span className="verPlanoInterrompido__lineItem">
                      {p.sessoes.length}
                    </span>
                    <div className="verPlanoInterrompido__procedimento__container verPlanoInterrompido__lineItem">
                      <span
                        className={`
                        verPlanoInterrompido__procedimento
                        ${
                          p.sessoes.length > 0
                            ? "verPlanoInterrompido__procedimento-emProgresso"
                            : "verPlanoInterrompido__procedimento-novo"
                        }
                      `}
                      >
                        {p.procedimentoCompleto ? (
                          <>
                            <span
                              className="
                       verPlanoInterrompido__procedimento verPlanoInterrompido__procedimento-completo"
                            >
                              <DoneIcon className="verPlanoInterrompido__procedimento__icon" />{" "}
                              Completo
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              className="
                        verPlanoInterrompido__procedimento verPlanoInterrompido__procedimento-interrompido"
                            >
                              <DoneIcon className="verPlanoInterrompido__procedimento__icon" />{" "}
                              Interrompido
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </React.Fragment>
                )
            )}
          <div className="linhaCinzenta">Finalizados</div>

          {planoTratamento &&
            planoTratamento.procedimentos.map(
              (p, index) =>
                p.procedimento_completo && (
                  <React.Fragment key={index}>
                    <span className="verPlanoInterrompido__lineItem verPlanoInterrompido__lineItem--1st">
                      {p.tratamento.designacao}
                    </span>
                    <span className="verPlanoInterrompido__lineItem">
                      {p.dente}
                    </span>
                    <span className="verPlanoInterrompido__lineItem">
                      {p.faces.join(" ,")}
                    </span>

                    <span className="verPlanoInterrompido__lineItem">
                      {p.sessoes.length}
                    </span>
                    <div className="verPlanoInterrompido__procedimento__container verPlanoInterrompido__lineItem">
                      <span
                        className="
                        verPlanoInterrompido__procedimento verPlanoInterrompido__procedimento-completo"
                      >
                        <DoneIcon className="verPlanoInterrompido__procedimento__icon" />{" "}
                        Completo
                      </span>
                    </div>
                  </React.Fragment>
                )
            )}

          <div className="botoes">
            <button className="cancel-btn" onClick={handleCloseModal}>
              Fechar
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalVerPlanoTratamentoInterrompido;
