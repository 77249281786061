import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ListaAtraso.scss";

import { localeText } from "../../../../shared/util/datatableTranslations";
import { DataGrid } from "@mui/x-data-grid";

const PAGESIZE = 15;

function ListaAtraso({ open, data, handleCloseModal }) {
  const [shownData, setShownData] = useState([]);

  useEffect(() => {
    setShownData(data);
  }, [data]);

  const columns = [
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 5,
    },
    {
      field: "quantiaPorPagar",
      headerName: "Valor",
      flex: 2,
    },
    {
      field: "contacto",
      headerName: "Contacto",
      flex: 2,
    },
    {
      field: "dataLimitePagamento",
      headerName: "Data Limite",
      flex: 2,
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 2,
      renderCell: (params) => {
        return (
          <span className="listaDivida__tableContent">
            <Link
              to={`/clientes/${params.row.idCliente}-debitos`}
              className="listaDivida__clientAnchor"
            >
              <button className="secretaria__dashboard__modalAcoesBtn">
                Ver Débitos
              </button>
            </Link>
          </span>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    if (data) {
      const result = data.filter((dt) =>
        dt.cliente.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setShownData(result);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
      <Box className="fluxo-atendimento__modal__box" id="dividas__modal">
        <div className="orcamentos-por-aprovar__modal__cabecalho">
          <h1 className="fluxo-atendimento__modal__titulo"> Dívidas</h1>
          <div className="search__datatable__container">
            <span>Procurar</span>
            <input
              type="search"
              className="search__datatable__input"
              placeholder="Procurar"
              onChange={searchHandler}
            />
          </div>
        </div>

        <div className="orcamentos-por-aprovar__modal__container">
          <DataGrid
            autoWidth
            className="datagrid"
            rows={shownData}
            columns={columns.concat(actionColumn)}
            pageSize={PAGESIZE}
            rowsPerPage={PAGESIZE}
            localeText={localeText}
          />
        </div>

        <div
          style={{
            gridColumn: "1/-1",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "25px",
          }}
        >
          <span
            className="cancel-btn"
            onClick={handleCloseModal.bind(null, "debitos")}
          >
            Fechar
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default memo(ListaAtraso);
