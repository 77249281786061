import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../shared/context/LoadingContext";
import "./ModalAdicionarPerfil.scss";

import toast from "react-hot-toast";

function ModalEditarPerfil({
  modalData,
  handleCloseModal,
  sendRequest,
  auth,
  handleUpdatePerfis,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [nome, setNome] = useState(modalData.perfil.nome);

  const submit = async () => {
    startLoading();
    setIsSubmitting(true);
    let perfilRetornado;
    try {
      if (!nome || nome.trim().length === 0) {
        toast.error("Por favor introduza o nome do perfil.");
      } else {
        const formData = new FormData();
        formData.append("nome", nome);
        formData.append("atualizadoPor", auth.userId);

        perfilRetornado = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/perfis/${modalData.perfil._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      }
      toast.success("Perfil modificado com sucesso!");
      handleUpdatePerfis(perfilRetornado.perfil, "editar");
      handleCloseModal();
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      stopLoading();
    }
  };
  return (
    <Modal
      open={modalData.open === "editarPerfil"}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box credencial__modal"
        id="modal__credencial"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Editar Perfil - {modalData.perfil.nome}
        </h1>

        <div className="modalAdicionarPerfil__container">
          <TextField
            label="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            fullWidth
          />
        </div>

        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModal}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={submit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalEditarPerfil;
