import { useState, useEffect } from "react";
import moment from "moment";
import InputNumber from "../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../shared/components/inputs/InputText/InputText";
import { Link } from "react-router-dom";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import toast from "react-hot-toast";

import InputSelect from "../../../../../shared/components/inputs/InputSelect/InputSelect";

import Autocomplete from "@mui/material/Autocomplete";
import { useLoading } from "../../../../../shared/context/LoadingContext";
import "./ModalEntrada.scss";

const formasPagamento = [
  "Dinheiro",
  "Cheque",
  // "Cheque Visado",
  // "Transferência de crédito",
  "Internet banking",
  // "Cartão do banco",
  // "Débito direto da conta bancária",
  "Cartão de crédito",
  // "Cartão de débito",
];

function ModalEntrada({
  openModalEntrada,
  handleCloseModalEntrada,
  sendRequest,
  clinicaId,
  updateEntradas,
  emitirDocEletronicoReembolso,
  auth,
}) {
  const { startLoading, stopLoading } = useLoading();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(openModalEntrada);
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState(0);
  const [observacao, setObservacao] = useState("");
  const [categoria, setCategoria] = useState();
  const [categorias, setCategorias] = useState([]);
  const [caixa, setCaixa] = useState();
  const [caixas, setCaixas] = useState([]);
  const [dataLimitePagamento, setDataLimitePagamento] = useState(
    moment().add(1, "M")
  );

  const [clientesFornecedores, setClientesFornecedores] = useState([]);
  const [clienteFornecedor, setClienteFornecedor] = useState();
  const [tipoEntrada, setTipoEntrada] = useState("clienteFornecedor");
  const [cliente, setCliente] = useState();
  const [clientesAutocomplete, setClientesAutocomplete] = useState([]);
  const [metodoPagamento, setMetodoPagamento] = useState(formasPagamento[0]);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          categoriasResponse,
          clientesFornecedoresResponse,
          clientesResponse,
          caixasResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriasfinanceiro/clinica/entrada/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientesfornecedores/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/nomeidmoradacontactonif/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/caixas/clinica/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        const categoriasFormatted = categoriasResponse.categoriasFinanceiro.map(
          (cat) => ({
            id: cat.id,
            value: cat.categoria,
          })
        );
        setCategorias(categoriasFormatted);
        setCategoria(categoriasFormatted[0]?.id);

        // Process clientesFornecedores
        const clientesFornecedoresFormatted =
          clientesFornecedoresResponse.clientesFornecedores.map((cf) => ({
            id: cf.id,
            value: cf.nome,
          }));
        setClientesFornecedores(clientesFornecedoresFormatted);
        setClienteFornecedor(
          clientesFornecedoresFormatted[0] || { id: "", value: "" }
        );

        // Process clientes
        const clientesFormatted = clientesResponse.clientes.map((c) => ({
          id: c._id,
          label: c.nome,
        }));
        setClientesAutocomplete(clientesFormatted);
        setCliente(clientesFormatted[0] || { id: "", label: "" });

        // Process caixas
        const caixasFormatted = caixasResponse.caixas.map((cai) => ({
          id: cai.id,
          value: cai.caixa,
        }));
        setCaixas(caixasFormatted);
        setCaixa(caixasFormatted[0]?.id);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [auth.token, clinicaId, sendRequest]);

  useEffect(() => {
    setOpenModal(openModalEntrada);
  }, [openModalEntrada]);

  const descricaoChangeHandler = (value) => {
    setDescricao(value);
  };

  const valorChangeHandler = (value) => {
    setValor(value);
  };

  const guardarEntradaHandler = async (emit) => {
    startLoading();
    try {
      const formData = new FormData();
      formData.append("tipo", tipoEntrada);
      formData.append("clinica", clinicaId);
      formData.append("observacoes", observacao ? observacao : "");
      formData.append("valor", valor);
      formData.append("descricao", descricao.trim());
      formData.append(
        "dataLimitePagamento",
        tipoEntrada === "reembolso" ? moment() : dataLimitePagamento
      );
      formData.append("criadoPor", auth.userId);

      formData.append("categoria", categoria);
      formData.append("caixa", caixa);
      if (tipoEntrada === "clienteFornecedor") {
        formData.append(
          "clienteFornecedor",
          clienteFornecedor.id ? clienteFornecedor.id : clienteFornecedor
        );
        formData.append("entradaRecebida", false);
      }

      if (tipoEntrada === "reembolso") {
        formData.append("cliente", cliente.id ? cliente.id : cliente);
        formData.append("metodoPagamento", metodoPagamento);
        formData.append("dataPagamento", moment());
        formData.append("entradaRecebida", true);
        formData.append("recebidoPor", auth.userId);
      }

      //LEMBRA DE ADICIONAR COMPROVATIVO MAIS TARDE
      let entradaAdicionada;
      try {
        entradaAdicionada = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/entradas`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        console.error("err", err);
      }

      if (emit) {
        if (tipoEntrada === "clienteFornecedor") {
          updateEntradas(entradaAdicionada.entrada, "inserir", emit);
        } else {
          emitirDocEletronicoReembolso(
            entradaAdicionada.entrada,
            null,
            "FRE",
            "inserir"
          );
        }
      } else {
        toast.success("Entrada adicionada com sucesso!");
        updateEntradas(entradaAdicionada.entrada, "inserir");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setIsSubmitting(false);
      handleCloseModalEntrada();
    }
  };

  const metodoPagamentoChangeHandler = (valor) => {
    setMetodoPagamento(valor);
  };

  const preGuardarEntradaHandler = (e, emit) => {
    if (valor === 0 || valor.trim() === "") {
      toast.error("O valor da entrada não pode ser 0");
    } else if (!descricao || descricao.trim() === "") {
      toast.error("Introduza a descrição");
    } else if (tipoEntrada === "reembolso" && !cliente) {
      toast.error("Escolha o Cliente");
    } else if (tipoEntrada !== "reembolso" && !clienteFornecedor) {
      toast.error("Escolha o Cliente/Fornecedor");
    } else if (tipoEntrada !== "reembolso" && !dataLimitePagamento) {
      toast.error("Introduza a data de limite de pagamento");
    } else if (
      tipoEntrada !== "reembolso" &&
      (!moment.isMoment(dataLimitePagamento) || !dataLimitePagamento.isValid())
    ) {
      toast.error("Introduza a data de limite de pagamento corretamente");
    } else if (!categoria) {
      toast.error("Introduza uma categoria");
    } else if (!caixa && tipoEntrada === "reembolso") {
      toast.error("Introduza uma categoria");
    } else if (!isSubmitting) {
      setIsSubmitting(true);
      guardarEntradaHandler(emit);
    }
  };

  const tipoEntradaChangeHandler = () => {
    if (tipoEntrada === "reembolso") {
      setTipoEntrada("clienteFornecedor");
    } else {
      setTipoEntrada("reembolso");
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Nova entrada</h1>
          <div className="adicionar-entrada__modal__tipoEntrada">
            <button
              onClick={tipoEntradaChangeHandler}
              className={`adicionar-entrada__modal__tipoEntrada__btn ${
                tipoEntrada === "clienteFornecedor"
                  ? "adicionar-entrada__modal__tipoEntrada__btn--active"
                  : ""
              }`}
            >
              Fatura Eletrónica
            </button>
            <button
              onClick={tipoEntradaChangeHandler}
              className={`adicionar-entrada__modal__tipoEntrada__btn ${
                tipoEntrada === "reembolso"
                  ? "adicionar-entrada__modal__tipoEntrada__btn--active"
                  : ""
              }`}
            >
              Entrada Clientes
            </button>
          </div>
          <div className="adicionar-entrada__modal__container">
            <InputText
              className="adicionar-entrada__modal__container--span2"
              label="Descrição *"
              initialValue={descricao}
              handleChange={descricaoChangeHandler}
            />

            {clientesFornecedores &&
              clienteFornecedor &&
              tipoEntrada === "clienteFornecedor" && (
                <div className="customInputContainer adicionar-entrada__modal__container--span2">
                  <select
                    onChange={(e) => setClienteFornecedor(e.target.value)}
                    className="customInputSelect"
                    value={clienteFornecedor}
                  >
                    {clientesFornecedores &&
                      clienteFornecedor &&
                      clientesFornecedores.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                  </select>
                  <label className="customInputLabelSelect">
                    Cliente/Fornecedor
                  </label>
                  <Link to={`../../configuracoes/clientesFornecedores`}>
                    <ControlPointIcon className="customInputPlusSign" />
                  </Link>
                </div>
              )}

            {tipoEntrada === "reembolso" && clientesAutocomplete && (
              <Autocomplete
                className="customInputContainer adicionar-entrada__modal__container--span2 entrada__autocomplete"
                disablePortal
                id="combo-box-demo"
                options={clientesAutocomplete}
                value={cliente}
                onChange={(e, newValue) => setCliente(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Cliente" />
                )}
              />
            )}

            <InputNumber
              className="adicionar-entrada__modal__container--span1"
              label="Valor *"
              initialValue={valor}
              handleChange={valorChangeHandler}
            />

            {tipoEntrada === "clienteFornecedor" && (
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                className="adicionar-entrada__modal__container--span1"
              >
                <DesktopDatePicker
                  className="valorDataCaixa__fields--field muiDatePicker"
                  label="Data limite pagamento*"
                  inputFormat="DD-MM-YYYY"
                  value={dataLimitePagamento}
                  onChange={(value) => setDataLimitePagamento(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}

            {tipoEntrada === "reembolso" && (
              <InputSelect
                className="adicionar-entrada__modal__container--span1"
                label="Forma de pagamento"
                initialValue={metodoPagamento}
                handleChange={metodoPagamentoChangeHandler}
                items={formasPagamento}
              />
            )}

            {categorias && categoria && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCategoria(e.target.value)}
                  className="customInputSelect"
                  value={categoria}
                >
                  {categorias &&
                    categoria &&
                    categorias.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Categoria</label>
                <Link to={`../../configuracoes/categoriasFinanceiro`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}

            {caixas && caixa && tipoEntrada === "reembolso" && (
              <div className="customInputContainer adicionar-entrada__modal__container--span2">
                <select
                  onChange={(e) => setCaixa(e.target.value)}
                  className="customInputSelect"
                  value={caixa}
                >
                  {caixas &&
                    caixa &&
                    caixas.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                </select>
                <label className="customInputLabelSelect">Caixa</label>
                <Link to={`../../configuracoes/caixas`}>
                  <ControlPointIcon className="customInputPlusSign" />
                </Link>
              </div>
            )}

            <textarea
              cols="30"
              rows="7"
              placeholder="observacao"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              className="adicionar-entrada__modal__container__textarea"
            ></textarea>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            {tipoEntrada === "clienteFornecedor" && (
              <>
                <span
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={preGuardarEntradaHandler}
                >
                  {isSubmitting ? "Guardando..." : "Guardar"}
                </span>
                <span
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={preGuardarEntradaHandler.bind(
                    null,
                    null,
                    "emitirFTE"
                  )}
                >
                  {isSubmitting ? "Guardando..." : "Guardar e emitir fte"}
                </span>
              </>
            )}

            {tipoEntrada === "reembolso" && (
              <>
                <span
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={preGuardarEntradaHandler}
                >
                  {isSubmitting ? "Guardando..." : "Guardar"}
                </span>
                <span
                  disabled={isSubmitting}
                  className={"blue-button"}
                  onClick={preGuardarEntradaHandler.bind(
                    null,
                    null,
                    "emitirFRE"
                  )}
                >
                  {isSubmitting
                    ? "Guardando e emitindo..."
                    : "Guardar e emitir FRE"}
                </span>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalEntrada;
