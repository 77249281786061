import React from "react";
import CardRelatorio from "../cardRelatorio/CardRelatorio";

function CardListingContainer({ data }) {
  return (
    <div className="relatorio__cardContainer">
      {data.map((dt) => (
        <CardRelatorio titulo={dt.titulo} valor={dt.valor} />
      ))}
    </div>
  );
}

export default CardListingContainer;
