import React from "react";
import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ElementoFicheiro from "./ElementoFicheiro";
import { useHttpClient } from "../../../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../../../shared/context/clinica-context";
import toast from "react-hot-toast";
import "./ModalAdiconarFicheiros.scss";
import { useLoading } from "../../../../../../shared/context/LoadingContext";
function ModalAdicionarFicheiros({
  open,
  handleCloseModal,
  clienteId,
  adicionarFicheiros,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ficheiros, setFicheiros] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    // Check file size (10MB limit)
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB in bytes
    let err = false;
    let tamanhoTotal = 0;
    let tempFicheiros = [];

    // Iterate through selected files
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      // Check file extension
      const allowedExtensions =
        /(\.jpg|\.jpeg|\.png|\.stl|\.ply|\.bmp|\.pdf|\.doc|\.docx|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.test(file.name)) {
        err = true;
        setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
        break;
      } else if (file.size + tamanhoTotal > maxSizeInBytes) {
        err = true;
        setErrorMessage(`O ficheiro "${file.name}" tem uma extensão inválida.`);
        break;
      } else {
        tamanhoTotal += file.size;
        let nomeFicheiro = file.name.split(".").slice(0, -1).join(".");
        tempFicheiros.push({
          file,
          descricao: nomeFicheiro,
          observacao: "",
        });
      }
    }

    if (!err) {
      setFicheiros(tempFicheiros);
      setErrorMessage("");
    }
  };

  const preSubmitHandler = () => {
    setErrorMessage("");
    setIsSubmitting(true);
    handleSubmit();
  };

  const changeDescricao = (descri, index) => {
    const tempFile = [...ficheiros];
    tempFile[index].descricao = descri;
    setFicheiros(tempFile);
  };

  const changeObservacao = (observ, index) => {
    const tempFile = [...ficheiros];
    tempFile[index].observacao = observ;
    setFicheiros(tempFile);
  };

  const handleSubmit = async () => {
    startLoading();
    try {
      const formData = new FormData();

      ficheiros.forEach((f, index) => {
        const modifiedFile = new File(
          [f.file],
          `${clinica.clinica._id}-${clienteId}-${f.file.name}`,
          { type: f.file.type }
        );

        formData.append("ficheiros", modifiedFile);
        formData.append("descricao", f.descricao);
        formData.append("observacao", f.observacao);
      });
      formData.append("nomeCriador", auth.nome);
      formData.append("idCriador", auth.userId);

      const respostaFicheiros = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/ficheiros/${clienteId}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      toast.success("Ficheiros adicionados com sucesso");
      adicionarFicheiros(respostaFicheiros.cliente.ficheiros);
    } catch (err) {
      console.error("err", err);
    } finally {
      handleCloseModal();
      stopLoading();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-despesa__modal"
          id="modal__adicionar-despesa"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Adicionar Ficheiros
          </h1>

          <div className="modalAdicionarFicheiros__container">
            {ficheiros.length === 0 && (
              <>
                <div className="modalAdicionarFicheiros__container-adicionar">
                  <svg
                    className="modalAdicionarFicheiros__container-adicionar--svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" fill="white" />
                    <path
                      d="M5 12V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12"
                      stroke="#88959e"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 3L12 15M12 15L16 11M12 15L8 11"
                      stroke="#88959e"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="modalAdicionarFicheiros__container-adicionar--msgEscolher">
                    Clique embaixo para escolher ficheiros
                  </span>
                  <div>
                    <input
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                    <label
                      htmlFor="fileInput"
                      className="modalAdicionarFicheiros__container-adicionar--btn-adicionar"
                    >
                      Escolher Ficheiros
                    </label>
                  </div>

                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    O Tamanho máximo do upload é 10MB
                  </span>
                  <span className="modalAdicionarFicheiros__container-adicionar--regras-ficheiro">
                    Formatos aceites: .pdf, .png, .jpg, .stl, .ply, .jpeg, .bmp,
                    .doc, .xls e .xlsx
                  </span>
                </div>

                {errorMessage && (
                  <div className="modalAdicionarFicheiros__errorMessage">
                    {errorMessage}
                  </div>
                )}
              </>
            )}

            <div className="modalAdicionarFicheiros__ficheiro">
              {ficheiros.length > 0 &&
                ficheiros.map((f, i) => (
                  <ElementoFicheiro
                    ficheiro={f}
                    i={i}
                    key={i}
                    changeDescricao={changeDescricao}
                    changeObservacao={changeObservacao}
                  />
                ))}
            </div>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            {ficheiros.length > 0 && (
              <span
                disabled={isSubmitting}
                className="blue-button"
                onClick={preSubmitHandler}
              >
                {isSubmitting
                  ? "Guardando..."
                  : `Guardar ${ficheiros.length} ficheiros`}
              </span>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalAdicionarFicheiros;
