import { useState, memo } from "react";
import ValorDataCaixa from "./ValorDataCaixa";

import moment from "moment";

import "./DinheiroTab.scss";

function TransferenciaTab({
  valor,
  handleCloseModalReceber,
  receberHandler,
  totalPorReceber,
  caixas,
  parcela,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dadosValorDataCaixa, setDadosValorDataCaixa] = useState({
    valor: valor,
    data: moment(),
    caixa: caixas[0].id,
    observacao: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const changeValorDataCaixa = (data) => {
    setDadosValorDataCaixa(data);
  };

  const preReceberHandler = (e, emitir) => {
    let mensagemDeErro = "";

    if (!dadosValorDataCaixa.data) {
      mensagemDeErro = "Introduza a data de recebimento";
      setErrorMessage(mensagemDeErro);
    } else if (
      !moment.isMoment(dadosValorDataCaixa.data) ||
      !dadosValorDataCaixa.data.isValid()
    ) {
      mensagemDeErro = "Introduza a data de recebimento corretamente";
      setErrorMessage(mensagemDeErro);
    }

    if (!isSubmitting && !mensagemDeErro) {
      setIsSubmitting(true);

      receberHandler(
        {
          quantiaPaga: Number(dadosValorDataCaixa.valor),
          metodoPagamento: "Internet banking",
          dataPagamento: dadosValorDataCaixa.data,
          caixa: dadosValorDataCaixa.caixa,
          parcelaPaga: dadosValorDataCaixa.valor === valor,
          quantiaPorPagar: valor - dadosValorDataCaixa.valor,
          observacao: dadosValorDataCaixa.observacao,
        },
        emitir
      );
    }
  };

  return (
    <div className="tabContainer tabContainer--dinheiro">
      <ValorDataCaixa
        valor={valor}
        changeValorDataCaixa={changeValorDataCaixa}
        caixas={caixas}
        totalPorReceber={totalPorReceber}
        tab="dinheiro"
      />
      {errorMessage && (
        <span className="fluxo-atendimento__modal__erros">{errorMessage}</span>
      )}

      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalReceber}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          className="blue-button"
          disabled={isSubmitting}
          onClick={preReceberHandler}
        >
          {isSubmitting ? "Recebendo..." : "Receber"}
        </span>

        {!parcela.documentoEletronico &&
          (parcela.cancelamentosDocumentosEletronicos?.length === 0 ||
            (parcela.cancelamentosDocumentosEletronicos?.length > 0 &&
              parcela.cancelamentosDocumentosEletronicos[
                parcela.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === true)) && (
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={preReceberHandler.bind(null, null, "emitirFRE")}
            >
              {isSubmitting ? "Recebendo..." : "Receber e emitir FRE"}
            </span>
          )}
      </div>
    </div>
  );
}

export default memo(TransferenciaTab);
