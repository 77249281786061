import { useState, useEffect, useCallback } from "react";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

function ModalFiltrosOrcamentos({
  openModalFiltros,
  handleCloseModalFiltros,
  handleFiltrar,
  orcamentos,
  filtrosAplicados,
}) {
  const [openModal, setOpenModal] = useState(false);

  const [radioAprovados, setRadioAprovados] = useState(
    filtrosAplicados.includes("aprovados") ? true : false
  );
  const [radioNAprovados, setRadioNAprovados] = useState(
    filtrosAplicados.includes("naoAprovados") ? true : false
  );

  const [radioTerminados, setRadioTerminados] = useState(
    filtrosAplicados.includes("terminados") ? true : false
  );
  const [radioNTerminados, setRadioNTerminados] = useState(
    filtrosAplicados.includes("naoTerminados") ? true : false
  );

  const [radioInps, setRadioInps] = useState(
    filtrosAplicados.includes("inps") ? true : false
  );
  const [radioParticular, setRadioParticular] = useState(
    filtrosAplicados.includes("particular") ? true : false
  );

  useEffect(() => {
    setOpenModal(openModalFiltros);
  }, [openModalFiltros]);

  const handleAprovadosChange = useCallback((e) => {
    setRadioAprovados(e.target.checked);
    setRadioNAprovados(!e.target.checked);
  }, []);

  const handleNAprovadosChange = useCallback((e) => {
    setRadioNAprovados(e.target.checked);
    setRadioAprovados(!e.target.checked);
  }, []);

  const handleTerminadosChange = useCallback((e) => {
    setRadioTerminados(e.target.checked);
    setRadioNTerminados(!e.target.checked);
  }, []);

  const handleNTerminadosChange = useCallback((e) => {
    setRadioNTerminados(e.target.checked);
    setRadioTerminados(!e.target.checked);
  }, []);

  const handleInpsChange = useCallback((e) => {
    setRadioInps(e.target.checked);
    setRadioParticular(!e.target.checked);
  }, []);

  const handleParticularChange = useCallback((e) => {
    setRadioParticular(e.target.checked);
    setRadioInps(!e.target.checked);
  }, []);

  const filtrarModalHandler = useCallback(() => {
    //////////////////////////////////////
    //Orcamentos////////////////////////////
    let orcamentosFiltrados = [];
    try {
      orcamentos.forEach((orcamento) => {
        if (
          (orcamento.transformado && radioAprovados) ||
          (!orcamento.transformado && radioNAprovados) ||
          (!radioAprovados && !radioNAprovados)
        ) {
          if (
            (orcamento.terminado && radioTerminados) ||
            (!orcamento.terminado && radioNTerminados) ||
            (!radioTerminados && !radioNTerminados)
          ) {
            if (
              (orcamento.beneficio === "INPS" && radioInps) ||
              (orcamento.beneficio === "Particular" && radioParticular) ||
              (!radioInps && !radioParticular)
            ) {
              orcamentosFiltrados.push({ ...orcamento });
            }
          }
        }
      });
    } catch (err) {
      console.error("err", err);
    }

    const filtrosApli = [];
    radioAprovados && filtrosApli.push("aprovados");
    radioNAprovados && filtrosApli.push("naoAprovados");
    radioTerminados && filtrosApli.push("terminados");
    radioNTerminados && filtrosApli.push("naoTerminados");
    radioInps && filtrosApli.push("inps");
    radioParticular && filtrosApli.push("particular");

    handleFiltrar(orcamentosFiltrados, filtrosApli);
    handleCloseModalFiltros();
  }, [
    orcamentos,
    radioAprovados,
    radioNAprovados,
    handleFiltrar,
    handleCloseModalFiltros,
    radioTerminados,
    radioNTerminados,
    radioInps,
    radioParticular,
  ]);

  const resetModalHandler = useCallback(() => {
    handleFiltrar(orcamentos, []);
    handleCloseModalFiltros();
  }, [orcamentos, handleFiltrar, handleCloseModalFiltros]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalFiltros}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box" id="filtros__modal">
          <div className="filtros__modal__header">
            <h1>Filtrar por</h1>
            <span onClick={resetModalHandler}>Resetar</span>
          </div>

          <div className="filtros__modal__container">
            <div className="modalFiltros__radios">
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="beneficioRadios"
                  id="radioInps"
                  checked={radioInps}
                  onChange={handleInpsChange}
                />
                <label htmlFor="radioInps">Inps</label>
              </div>
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="beneficioRadios"
                  id="radioParticular"
                  checked={radioParticular}
                  onChange={handleParticularChange}
                />
                <label htmlFor="radioParticular">Particular</label>
              </div>
            </div>
          </div>

          <div className="filtros__modal__container">
            <div className="modalFiltros__radios">
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="aprovadosRadios"
                  id="radioAprovados"
                  checked={radioAprovados}
                  onChange={handleAprovadosChange}
                />
                <label htmlFor="radioAprovados">Aprovados</label>
              </div>
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="aprovadosRadios"
                  id="radioNAprovados"
                  checked={radioNAprovados}
                  onChange={handleNAprovadosChange}
                />
                <label htmlFor="radioNAprovados">Não Aprovados</label>
              </div>
            </div>
          </div>

          <div className="filtros__modal__container">
            <div className="modalFiltros__radios">
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="terminadosRadios"
                  id="radioTerminados"
                  checked={radioTerminados}
                  onChange={handleTerminadosChange}
                />
                <label htmlFor="radioTerminados">Finalizados</label>
              </div>
              <div className="modalFiltros__radios__container">
                <input
                  type="radio"
                  name="terminadosRadios"
                  id="radioNTerminados"
                  checked={radioNTerminados}
                  onChange={handleNTerminadosChange}
                />
                <label htmlFor="radioNTerminados">Não Finalizados</label>
              </div>
            </div>
          </div>

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalFiltros}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span className="blue-button" onClick={filtrarModalHandler}>
              Filtrar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalFiltrosOrcamentos;
