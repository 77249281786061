import { useState, useEffect } from "react";

//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import "./ModalEntrada.scss";

function ModalVerEntrada({
  openModalVerEntrada,
  handleCloseModalVerEntrada,
  dadosModalVerEntrada,
}) {
  const [openModal, setOpenModal] = useState(openModalVerEntrada);

  useEffect(() => {
    setOpenModal(openModalVerEntrada);
  }, [openModalVerEntrada]);

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModalVerEntrada}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box adicionar-entrada__modal"
          id="modal__adicionar-entrada"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Ver entrada{" "}
            {dadosModalVerEntrada.tipo === "reembolso" && "- Reembolso"}
          </h1>
          <div className="adicionar-entrada__modal__container">
            <div className="customInputContainer adicionar-entrada__modal__container--span2">
              <input
                type="text"
                defaultValue={dadosModalVerEntrada.descricao}
                className="customInput inputNumber"
                id="descricao"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="descricao">
                Descrição
              </label>
            </div>

            <div className="customInputContainer adicionar-entrada__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalVerEntrada.valor}
                className="customInput inputNumber"
                id="valor"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="valor">
                Valor
              </label>
            </div>

            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-entrada__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data limite pagamento*"
                inputFormat="DD-MM-YYYY"
                value={dadosModalVerEntrada.dataLimitePagamento}
                renderInput={(params) => <TextField {...params} />}
                disabled
                onChange={() => {}}
              />
            </LocalizationProvider>

            <div className="customInputContainer adicionar-entrada__modal__container--span2">
              <input
                type="text"
                defaultValue={
                  dadosModalVerEntrada.categoria.categoria
                    ? dadosModalVerEntrada.categoria.categoria
                    : dadosModalVerEntrada.categoria.value
                }
                className="customInput inputNumber"
                id="categoria"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="categoria">
                Categoria
              </label>
            </div>

            <div className="customInputContainer adicionar-entrada__modal__container--span1">
              <input
                type="text"
                defaultValue={
                  dadosModalVerEntrada.caixa.caixa
                    ? dadosModalVerEntrada.caixa.caixa
                    : dadosModalVerEntrada.caixa.value
                }
                className="customInput inputNumber"
                id="caixa"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="caixa">
                Caixa
              </label>
            </div>
            {dadosModalVerEntrada.observacoes && (
              <textarea
                cols="30"
                rows="7"
                defaultValue={
                  dadosModalVerEntrada.observacoes
                    ? dadosModalVerEntrada.observacoes
                    : ""
                }
                className="adicionar-entrada__modal__container__textarea"
                disabled
                style={{ color: "#88959e" }}
              ></textarea>
            )}
          </div>

          <div
            className="adicionar-entrada__modal__pagamento"
            style={{ marginTop: 25, marginBottom: 25 }}
          >
            {dadosModalVerEntrada.entradaRecebida && (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  className="adicionar-entrada__modal__container--span1"
                >
                  <DesktopDatePicker
                    className="valorDataCaixa__fields--field muiDatePicker"
                    label="Data de pagamento*"
                    inputFormat="DD-MM-YYYY"
                    value={dadosModalVerEntrada.dataPagamento}
                    renderInput={(params) => <TextField {...params} />}
                    disabled
                    onChange={() => {}}
                  />
                </LocalizationProvider>

                <div className="customInputContainer adicionar-entrada__modal__container--span1">
                  <input
                    type="text"
                    defaultValue={dadosModalVerEntrada.metodoPagamento}
                    className="customInput inputNumber"
                    id="metodoPagamento"
                    style={{ pointerEvents: "none", color: "#88959e" }}
                  />
                  <label className="customInputLabel" htmlFor="metodoPagamento">
                    Metodo do Pagamento
                  </label>
                </div>
              </>
            )}
            <div className="customInputContainer adicionar-entrada__modal__container--span1">
              <input
                type="text"
                defaultValue={
                  dadosModalVerEntrada.tipo === "reembolso"
                    ? dadosModalVerEntrada.cliente?.nome
                    : dadosModalVerEntrada.clienteFornecedor?.nome
                }
                className="customInput inputNumber"
                id="metodoPagamento"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="metodoPagamento">
                {dadosModalVerEntrada.tipo === "reembolso"
                  ? "Cliente"
                  : "Cliente/Fornecedor"}
              </label>
            </div>
            <div className="customInputContainer adicionar-entrada__modal__container--span1">
              <input
                type="text"
                defaultValue={dadosModalVerEntrada.recebidoPor?.name}
                className="customInput inputNumber"
                id="metodoPagamento"
                style={{ pointerEvents: "none", color: "#88959e" }}
              />
              <label className="customInputLabel" htmlFor="metodoPagamento">
                Recebido Por
              </label>
            </div>
          </div>

          {dadosModalVerEntrada.comprovativo && (
            <div className="verComprovativoContainer">
              <span>Comprovativo:</span>
              <a
                href={`${process.env.REACT_APP_BACKEND_LINK}/${dadosModalVerEntrada.comprovativo.ficheiro}`}
                download
                target="_blank"
                rel="noreferrer"
              >
                {dadosModalVerEntrada.comprovativo.descricao}
              </a>
            </div>
          )}

          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleCloseModalVerEntrada}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalVerEntrada;
