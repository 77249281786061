import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import validarAvaliacao from "./helperFunctions/validation";

import uuid from "react-uuid";
import toast from "react-hot-toast";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import ElementoAvaliacao from "./Elemento/ElementoAvaliacao";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

import { useLoading } from "../../../shared/context/LoadingContext";
import "../../../shared/css/PlanoAvaliacaoOrcamento.scss";
import "../../../style/formError.scss";

const DENTES_CRIANCAS = [
  "51",
  "52",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "71",
  "72",
  "73",
  "74",
  "75",
  "81",
  "82",
  "83",
  "84",
  "85",
  "Arcada Superior",
  "Arcada Inferior",
  "Arcadas",
];
const DENTES_ADULTOS = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
];
const DENTES = [...DENTES_ADULTOS, ...DENTES_CRIANCAS];

function NewAvaliacao() {
  const { startLoading, stopLoading } = useLoading();
  const auth = useContext(AuthContext);
  const [medicoResponsavel, setMedicoResponsavel] = useState(
    auth.role === "Secretária(o)" || auth.role === "Assistente"
      ? auth.usuariosAssociados[0].id
      : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cliente, setCliente] = useState();
  const [tratamentos, setTratamentos] = useState();
  const [tratamentosAutocomplete, setTratamentosAutocomplete] = useState();
  const [serviceList, setServiceList] = useState([
    {
      id: "1",
      dentesTratados: [],
      faces: [],
      show: true,
      erro: "",
      aceitaDentes: true,
    },
  ]);
  const [observacoes, setObservacoes] = useState("");

  const { sendRequest } = useHttpClient();
  const clinica = useContext(ClinicaContext);

  const clienteId = useParams().clienteId;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        // Fetch tratamentos and cliente in parallel
        const [tratamentosResponse, clienteResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/populated/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${clienteId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process tratamentos data
        const sortedTratamentos = sortTratamentos(
          tratamentosResponse.tratamentos
        );
        setTratamentos(sortedTratamentos);
        setTratamentosAutocomplete(
          formatTratamentosAutocomplete(sortedTratamentos)
        );
        updateServiceList(sortedTratamentos[0]);

        // Process cliente data
        setCliente(clienteResponse.cliente);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        // Trate o erro (por exemplo, mostre uma mensagem de erro ao usuário)
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clinica, auth.token, clienteId]);

  // Helper functions
  ////////////////////////////////////////////////////////
  const sortTratamentos = (tratamentos) => {
    const naturalCompare = (a, b) => {
      const ax = [],
        bx = [];
      a.designacao.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.designacao.replace(/(\d+)|(\D+)/g, (_, $1, $2) => {
        bx.push([$1 || Infinity, $2 || ""]);
      });
      while (ax.length && bx.length) {
        const an = ax.shift(),
          bn = bx.shift();
        const nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }
      return ax.length - bx.length;
    };
    return tratamentos.sort(naturalCompare);
  };

  const formatTratamentosAutocomplete = (tratamentos) => {
    return tratamentos.map((t) => ({
      id: t.id,
      label: t.designacao,
      categoria: t.categoria.categoria,
    }));
  };

  const updateServiceList = (firstTratamento) => {
    setServiceList((currentServiceList) => [
      {
        ...currentServiceList[0],
        tratamento: firstTratamento.id,
        aceitaDentes: firstTratamento.aceitaDentes,
      },
    ]);
  };
  ///////////////////////////////////////////////

  //funcoes executadas pelas childs
  const tratamentoChangeHandler = (id, tratamento, acDentes, erro) => {
    let list = serviceList;

    const servico = list.filter((el) => {
      return el.id === id;
    });

    const indice = list.indexOf(servico[0]);

    list[indice].tratamento = tratamento;
    list[indice].erro = erro;
    list[indice].aceitaDentes = acDentes;
    setServiceList(list);
  };

  const dentesChangeHandler = (id, dentes, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].dentesTratados = dentes;
    list[indice].erro = erro;
    setServiceList(list);
  };

  const facesChangeHandler = (id, faces, erro) => {
    let list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice].faces = faces;
    list[indice].erro = erro;
    setServiceList(list);
  };

  const addService = () => {
    setServiceList([
      ...serviceList,
      {
        id: uuid(),
        tratamento: tratamentos[0].id,
        dentesTratados: [],
        faces: [],
        show: true,
        erro: "",
        aceitaDentes: tratamentos[0].aceitaDentes,
      },
    ]);
  };

  const removeService = (id) => {
    const list = serviceList;

    const servico = list.filter((el, i) => {
      return el.id === id;
    });
    const indice = list.indexOf(servico[0]);

    list[indice] = { show: false };
    setServiceList([...list]);
  };

  const submitHandler = async () => {
    startLoading();
    try {
      const filteredServiceList = [];
      let erroServiceList = "";
      const formErrorMessage = validarAvaliacao(serviceList, tratamentos);

      for (const service of serviceList) {
        if (service.erro) {
          erroServiceList = service.erro;
          break; // This terminates the entire loop
        }
      }

      //Filtered services that show
      if (!formErrorMessage && !erroServiceList) {
        serviceList.forEach((service) => {
          if (service.show) {
            filteredServiceList.push({
              tratamento: service.tratamento,
              dentes: service.dentesTratados,
              faces: service.faces,
            });
          }
        });

        let finalServices = [];
        filteredServiceList.forEach((service) => {
          if (service.dentes.length > 1) {
            const tratamento_filtrado = tratamentos.filter(
              (t) => t.id === service.tratamento
            )[0];

            if (tratamento_filtrado.multiDentes) {
              finalServices.push({
                tratamento: service.tratamento,
                dente: service.dentes.join(", "),
                faces: service.faces,
                orcamentado: false,
              });
            } else {
              service.dentes.forEach((d) => {
                finalServices.push({
                  tratamento: service.tratamento,
                  dente: d,
                  faces: service.faces,
                  orcamentado: false,
                });
              });
            }
          } else {
            finalServices.push({
              tratamento: service.tratamento,
              dente: service.dentes[0],
              faces: service.faces,
              orcamentado: false,
            });
          }
        });

        const formData = new FormData();
        formData.append("observacoes", observacoes);
        formData.append("cliente", clienteId);
        formData.append(
          "medico_responsavel",
          auth.role === "Dentista" || auth.role === "Médico/Administrador"
            ? auth.userId
            : medicoResponsavel
        );
        formData.append("ativo", true);
        formData.append("procedimentos", JSON.stringify(finalServices));
        formData.append("clinica", clinica.clinica._id);
        formData.append("criado_por", auth.userId);

        //NOTIFICACAO DA AVALIACAO PARA Secretárias
        const formData3 = new FormData();
        formData3.append("cliente", clienteId);
        formData3.append("clinica", clinica.clinica._id);
        if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
          formData3.append(
            "user",
            JSON.stringify(auth.usuariosAssociados.map((u) => u.id))
          );
          //Se o utilizador é uma secretária, enviar notificacao aos assistentes do medico e ao medico
        } else if (auth.role === "Secretária(o)") {
          const assistentes = auth.assistentesDoMedico
            ? auth.assistentesDoMedico.find(
                (m) => m.medicoId === medicoResponsavel
              ).assistentes
            : [];

          formData3.append(
            "user",
            JSON.stringify([medicoResponsavel, ...assistentes])
          );
          //Se o utilizador é uma assistente, enviar notificacao aos assistentes do medico e ao medico
        } else if (auth.role === "Assistente") {
          const secretarias = auth.secretariasDoMedico
            ? auth.secretariasDoMedico.find(
                (m) => m.medicoId === medicoResponsavel
              ).secretarias
            : [];
          formData3.append(
            "user",
            JSON.stringify([medicoResponsavel, ...secretarias])
          );
        }
        formData3.append("tipo", "efetuou avaliação");
        formData3.append("mensagem", `${cliente.nome} Efetuou uma avaliação`);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/avaliacoes`,
            "POST",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          toast.success("Avaliação adicionada com sucesso!");
        } catch (err) {
          console.error("err", err);
        }
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/notificacoes`,
            "POST",
            formData3,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          navigate(`../../../clientes/${clienteId}-avaliacao`);
        } catch (err) {
          console.error("err", err);
        }
      } else {
        toast.error(`${formErrorMessage || erroServiceList}`);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  const preSubmitHandler = () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const replicarTratamentoHandler = (servico) => {
    const tratamentoAReplicar = servico;

    tratamentoAReplicar.aceitaDentes = tratamentos.filter(
      (t) => t.id === servico.tratamento.id
    )[0].aceitaDentes;

    tratamentoAReplicar.id = uuid();
    tratamentoAReplicar.tratamento = tratamentoAReplicar.tratamento.id;
    setServiceList((current) => [...current, tratamentoAReplicar]);
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          {cliente && (
            <Navbar
              title="Criar Avaliação"
              icon={NoteAltOutlinedIcon}
              paths={[
                {
                  nome: cliente.nome,
                  link: `../../../clientes/${cliente.id}-avaliacao`,
                },
                { nome: "Novo" },
              ]}
            />
          )}
          {tratamentos &&
            serviceList &&
            cliente &&
            tratamentosAutocomplete &&
            serviceList[0].tratamento && (
              <div className="bottom-orcamento caixa">
                {auth.role === "Secretária(o)" && (
                  <div className="avaliacaoPickMedcoContainer form-input">
                    <span className="avaliacaoPickMedcoContainer__medicoHeader">
                      Médico responsável
                    </span>
                    <select
                      id="select_medicoResponsavel"
                      name="medicoResponsavel"
                      value={medicoResponsavel}
                      onChange={(e) => setMedicoResponsavel(e.target.value)}
                      className="input"
                    >
                      {auth.usuariosAssociados.map((med, index) => (
                        <option value={med.id} key={index}>
                          {med.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {serviceList.map(
                  (singleService, i) =>
                    singleService.show && (
                      <div key={i} className="avaliacao__item">
                        <ElementoAvaliacao
                          //single service data
                          dentesTratados={singleService.dentesTratados}
                          faces={singleService.faces}
                          tratamento={singleService.tratamento}
                          //outros
                          tratamentos={tratamentos}
                          tratamentosAutocomplete={tratamentosAutocomplete}
                          removeService={removeService}
                          id={singleService.id}
                          dentes={DENTES}
                          show={singleService.show}
                          //funcoes
                          tratamentoChangeHandler={tratamentoChangeHandler}
                          dentesChangeHandler={dentesChangeHandler}
                          facesChangeHandler={facesChangeHandler}
                          replicarTratamentoHandler={replicarTratamentoHandler}
                        />
                      </div>
                    )
                )}
                <button
                  className="add-btn"
                  onClick={addService}
                  style={{ marginLeft: "10px", marginTop: "15px" }}
                >
                  +
                </button>

                <div className="form-input mb-20">
                  <textarea
                    className="input"
                    cols="30"
                    rows="5"
                    placeholder="ESCREVA AQUI OBSERVAÇÕES ACERCA DA AVALIAÇÃO SE TIVER."
                    onChange={(e) => setObservacoes(e.target.value)}
                  ></textarea>
                </div>

                <div className="botoes">
                  <button
                    className="cancel-btn"
                    onClick={() =>
                      navigate(`../../../clientes/${clienteId}-avaliacao`)
                    }
                  >
                    Cancelar
                  </button>

                  <button
                    disabled={isSubmitting}
                    className="blue-button"
                    onClick={preSubmitHandler}
                  >
                    {isSubmitting ? "Guardando..." : "Guardar"}
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default NewAvaliacao;
