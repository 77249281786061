import React, { useEffect, useState, memo, useCallback } from "react";
import "./Debitos.scss";

import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import ListaAtraso from "../listaAtraso/ListaAtraso";
function Debitos({ sendRequest, clinica, auth }) {
  const [dadosDivida, setDadosDivida] = useState();
  const [openModal, setOpenModal] = useState("");

  useEffect(() => {
    const fetchContasCorrentes = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/ematraso/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setDadosDivida(responseData);
      } catch (err) {}
    };
    fetchContasCorrentes();
  }, [auth.token, clinica.clinica._id, sendRequest]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <>
      <div className="administrativoBottomContainers__container administrativoBottomContainers__container--debitos dasboard__middle__Earnings caixa">
        <span className="box-title">Débitos</span>
        {dadosDivida && (
          <div className="lucro-box">
            <div className="lucro-data">
              <span className="lucro lucro--red">
                <AttachMoneyIcon className="administrativoBottomContainers__debitosEmAtraso__twoDivs__element__iconText--icon" />
                {"  "}
                {dadosDivida.totalDividas.toLocaleString("pt-Br")}
              </span>
              <span className="lucro-description">Em atraso</span>
            </div>
            <hr />
            <div className="lucro-data">
              <span className="lucro">
                <PersonIcon className="administrativoBottomContainers__debitosEmAtraso__twoDivs__element__iconText--icon" />{" "}
                {dadosDivida.totalClientes}
              </span>
              <span className="lucro-description">Clientes</span>
            </div>
          </div>
        )}

        <div
          className="button-more-div"
          onClick={(e) => setOpenModal("listaAtraso")}
        >
          <span className="button-more">Mais</span>
          <span className="more-arrow"> {">"} </span>
        </div>
      </div>

      {openModal === "listaAtraso" && (
        <ListaAtraso
          open={openModal === "listaAtraso"}
          data={dadosDivida.dividas}
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
}

export default memo(Debitos);
