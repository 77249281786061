const validarAvaliacao = (serviceList, tratamentos) => {
  let errorMessage = null;

  const itemsOnServiceList = serviceList.filter((s) => s.show === true);

  if (itemsOnServiceList.length === 0) {
    errorMessage = "Crie pelo menos um procedimento!";
  }

  serviceList.forEach((service) => {
    if (service.show) {
      if (service.aceitaDentes) {
        if (service.dentesTratados.length === 0) {
          errorMessage = "Um dos procedimentos está sem dentes definidos!";
        }
      }
    }
  });

  return errorMessage;
};

export default validarAvaliacao;
