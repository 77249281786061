import { useState } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputText from "../../../../../../../shared/components/inputs/InputText/InputText";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useLoading } from "../../../../../../../shared/context/LoadingContext";
import "../ModalAdicionarCredencial/ModalAdicionarCredencial.scss";

import toast from "react-hot-toast";

function ModalEditarCredencial({
  open,
  handleCloseModaisCredencial,
  idOrcamento,
  dataInicial,
  nrCredencialInicial,
  sendRequest,
  token,
  handleUpdateCredencialData,
}) {
  const { startLoading, stopLoading } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataCredencial, setDataCredencial] = useState(moment(dataInicial));
  const [nrCredencial, setNrCredencial] = useState(nrCredencialInicial);

  const nrCredencialChangeHandler = (value) => {
    setNrCredencial(value);
  };

  const submit = async () => {
    if (!dataCredencial) {
      toast.error("A data de credencial é obrigatória");
    } else if (!moment.isMoment(dataCredencial) || !dataCredencial?.isValid()) {
      toast.error("Preencha a data de credencial corretamente.");
    } else if (!nrCredencial || nrCredencial.trim().length === 0) {
      toast.error("Por Favor introduza o número do credencial");
    } else {
      startLoading();
      setIsSubmitting(true);
      try {
        const formData = new FormData();
        formData.append("dataCredencial", dataCredencial);
        formData.append("nrCredencial", nrCredencial);

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/orcamentos/credencial/${idOrcamento}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + token,
          }
        );

        toast.success("Dados do credencial editados com sucesso!");
        handleUpdateCredencialData(nrCredencial, dataCredencial, idOrcamento);
        handleCloseModaisCredencial();
      } catch (err) {
        console.error("err", err);
      } finally {
        setIsSubmitting(false);
        stopLoading();
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModaisCredencial}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="fluxo-atendimento__modal__box credencial__modal"
        id="modal__credencial"
      >
        <h1 className="fluxo-atendimento__modal__titulo">
          Editar dados do Credencial
        </h1>

        <div className="modalCredencialContainer">
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            className="adicionar-despesa__modal__container--span1"
          >
            <DesktopDatePicker
              className="valorDataCaixa__fields--field muiDatePicker"
              label="Data de Inicio*"
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
              onChange={(value) => setDataCredencial(value)}
              value={dataCredencial}
            />
          </LocalizationProvider>

          <InputText
            className="adicionar-entrada__modal__container--span2"
            label="Nº do Credencial*"
            initialValue={nrCredencial}
            handleChange={nrCredencialChangeHandler}
          />
        </div>

        <div className="fluxo-atendimento__modal__bottom_btns">
          <span
            className="cancel-btn"
            onClick={handleCloseModaisCredencial}
            style={{ display: "block" }}
          >
            Fechar
          </span>
          <span
            className="blue-button"
            disabled={isSubmitting}
            onClick={submit}
            style={{ display: "block" }}
          >
            {isSubmitting ? "Editando..." : "Editar"}
          </span>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalEditarCredencial;
