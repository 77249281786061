import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

function ModalVerAvaliacoes({
  openModal,
  handleCloseModal,
  procedimentos,
  observacao,
}) {
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="fluxo-atendimento__modal__box ver-avaliacao__modal">
          <>
            <h1 className="fluxo-atendimento__modal__titulo">Avaliação</h1>
            <span className="fluxo-atendimento__modal__designacao">
              Tratamento
            </span>
            <span className="fluxo-atendimento__modal__designacao">
              Dentes/Região
            </span>
            <span className="fluxo-atendimento__modal__designacao">Faces</span>

            {procedimentos &&
              procedimentos.map(
                (proc, index) =>
                  !proc.procedimento_completo && (
                    <React.Fragment key={index}>
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.tratamento.designacao}
                      </span>
                      <span className="fluxo-atendimento__modal__cell">
                        {proc.dente}
                      </span>
                      <span className="fluxo-atendimento__modal__cell ">
                        {proc.faces && proc.faces.join(", ")}
                      </span>
                    </React.Fragment>
                  )
              )}

            {observacao && (
              <>
                <span className="fluxo-atendimento__modal__observacao-titulo">
                  Observação
                </span>
                <textarea
                  className="fluxo-atendimento__modal__textarea"
                  cols="30"
                  rows="4"
                  readOnly
                  defaultValue={observacao}
                ></textarea>
              </>
            )}
          </>
          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span className="cancel-btn" onClick={() => handleCloseModal()}>
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalVerAvaliacoes;
