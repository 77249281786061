import moment from "moment";

const dataGridData = (data) => {
  const rows = data.map((dt) => {
    switch (dt.tipo) {
      case "receita":
        return {
          tipo: "receita",
          cliente: dt.cliente,
          idCliente: dt.idCliente,
          dadosCliente: dt.dadosCliente,
          idContaCorrente: dt.idContaCorrente,
          id: dt.id,
          contaId: dt.contaId,
          nome: dt.entrada
            ? `Entrada Orçamento -`
            : `Parcela Orçamento ${dt.codigo} -`,
          data: moment(dt.dataLimitePagamento).format("DD-MM-YYYY"),
          valor:
            dt.quantiaPorPagar > 0
              ? `${dt.quantiaPorPagar.toLocaleString("pt-BR")}$00`
              : `${dt.quantiaPaga.toLocaleString("pt-BR")}$00`,
          receber: "receber",
          metodoPagamento: dt.metodoPagamento,
          nrParcela: dt.nrParcela,
          dataLimitePagamento: dt.dataLimitePagamento,
          dataPagamento: dt.dataPagamento,
          parcelaPaga: dt.parcelaPaga,
          documentoEletronico: dt.documentoEletronico,
          saldo: dt.saldo,
          beneficio: dt.beneficio,
        };
      case "entrada":
        return {
          id: dt.id,
          dataPagamento: dt.dataPagamento,
          valor: dt.valor ? `${dt.valor.toLocaleString("pt-BR")}$00` : "",
          tipo: dt.tipo,
          metodoPagamento: dt.metodoPagamento,
          descricao: dt.descricao,
          comprovativo: dt.comprovativo,
          documentoEletronico: dt.documentoEletronico,
          saldo: dt.saldo,
          entradaRecebida: dt.entradaRecebida,
          cliente: dt.cliente,
        };
      default:
        return {};
    }
  });

  return rows;
};

export default dataGridData;
