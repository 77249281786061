//MUI
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { fixSpaces } from "../../../../../../../shared/util/helperFunctions";

import "./ModalMotivoEventoDebitos.scss";

function ModalMotivoEventoDebitos({
  open,
  handleCloseModal,
  modalData,
  handleCancelarRecebimento,
}) {
  const [erro, setErro] = useState("");
  const [motivoEvent, setMotivoEvent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const beforeSubmitHandler = () => {
    if (fixSpaces(motivoEvent).length < 10) {
      setErro(
        "Deve indicar pelo menos 10 carateres no campo (Descrição de Motivo de Emissão de Evento)"
      );
    } else {
      setIsSubmitting(true);
      submitHandler();
    }
  };

  const submitHandler = () => {
    setIsSubmitting(true);
    handleCancelarRecebimento(
      modalData.id,
      modalData.idConta,
      motivoEvent,
      "Event"
    );

    /////////////////////////////////
    setIsSubmitting(false);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box motivoCancelamento__modal"
          id="modal__motivoCancelamento"
        >
          <h1 className="fluxo-atendimento__modal__titulo">
            Anular TVE - Motivo de Emissão do Evento
          </h1>

          <div className="motivoCancelamento__modal__container">
            <textarea
              className="motivoCancelamento__modal__textarea"
              cols={4}
              rows={4}
              onChange={(e) => setMotivoEvent(e.target.value)}
              value={motivoEvent}
              placeholder="Descrição do motivo de emissão do Evento"
            />
          </div>

          <div className="motivoCancelamento__modal__erro">{erro && erro}</div>
          <div
            style={{
              gridColumn: "1/-1",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <span
              className="cancel-btn"
              onClick={handleCloseModal}
              style={{ display: "block" }}
            >
              Fechar
            </span>

            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={beforeSubmitHandler}
            >
              {isSubmitting ? "Enviando..." : "Enviar"}
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ModalMotivoEventoDebitos;
