// src/shared/hooks/useErrorHandler.js
import { useCallback } from "react";
import toast from "react-hot-toast";

const ERROR_MESSAGES = {
  // Network errors
  NETWORK_ERROR: "Erro de conexão. Por favor verifique sua internet.",
  TIMEOUT_ERROR: "A conexão expirou. Por favor tente novamente.",

  // Authentication errors
  AUTH_ERROR: "Sessão expirada. Por favor faça login novamente.",
  FORBIDDEN_ERROR: "Você não tem permissão para realizar esta ação.",

  // E-fatura specific errors
  EFATURA_OFFLINE:
    "O sistema de E-fatura está offline. Por favor tente mais tarde.",
  EFATURA_ERROR: "Erro na comunicação com E-fatura. Por favor tente novamente.",

  // Generic errors
  SERVER_ERROR: "Erro no servidor. Por favor tente novamente mais tarde.",
  VALIDATION_ERROR: "Por favor verifique os dados inseridos.",
  UNKNOWN_ERROR: "Ocorreu um erro. Por favor tente novamente.",

  // Business logic errors
  DUPLICATE_ENTRY: "Este registro já existe no sistema.",
  NOT_FOUND: "Registro não encontrado.",
};

export const useErrorHandler = () => {
  const handleError = useCallback((error) => {
    // Don't show error message for aborted requests
    if (error.name === "AbortError") {
      return;
    }

    let errorMessage;
    let errorType;

    // If it's an axios error response
    if (error.response) {
      const { status, data } = error.response;

      // First check if we have a proper backend error code and message
      if (data.code && data.message) {
        errorMessage = data.message;
        errorType = data.code.toLowerCase(); // Convert backend code to type
      }
      // Then check for specific known cases
      else if (data.code === "EFATURA_OFFLINE") {
        errorMessage = ERROR_MESSAGES.EFATURA_OFFLINE;
        errorType = "efatura";
      }
      // Then fall back to status code handling
      else {
        switch (status) {
          case 400:
            errorMessage = data.message || ERROR_MESSAGES.VALIDATION_ERROR;
            errorType = "validation";
            break;
          case 401:
            errorMessage = ERROR_MESSAGES.AUTH_ERROR;
            errorType = "auth";
            break;
          case 403:
            errorMessage = ERROR_MESSAGES.FORBIDDEN_ERROR;
            errorType = "auth";
            break;
          case 404:
            errorMessage = ERROR_MESSAGES.NOT_FOUND;
            errorType = "not-found";
            break;
          case 409:
            errorMessage = ERROR_MESSAGES.DUPLICATE_ENTRY;
            errorType = "duplicate";
            break;
          case 500:
            errorMessage = data.message || ERROR_MESSAGES.SERVER_ERROR;
            errorType = "server";
            break;
          default:
            errorMessage = data.message || ERROR_MESSAGES.UNKNOWN_ERROR;
            errorType = "unknown";
        }
      }

      // Special case for E-fatura errors in message
      if (data.message?.toLowerCase().includes("efatura")) {
        errorMessage = ERROR_MESSAGES.EFATURA_ERROR;
        errorType = "efatura";
      }
    }
    // Network errors
    else if (error.request) {
      errorMessage = ERROR_MESSAGES.NETWORK_ERROR;
      errorType = "network";
    }
    // Other errors
    else {
      errorMessage = error.message || ERROR_MESSAGES.UNKNOWN_ERROR;
      errorType = "unknown";
    }

    // Log error for debugging
    console.error("Error details:", {
      originalError: error,
      handledMessage: errorMessage,
      handledType: errorType,
      response: error.response?.data,
    });

    // Show toast
    toast.error(errorMessage, {
      duration: 10000,
      style: {
        borderRadius: "10px",
      },
      icon: getErrorIcon(errorType),
    });

    return {
      message: errorMessage,
      type: errorType,
    };
  }, []);

  return { handleError };
};

// Helper function to get appropriate icon based on error type
const getErrorIcon = (errorType) => {
  switch (errorType) {
    case "network":
      return "🌐";
    case "auth":
      return "🔒";
    case "validation":
      return "⚠️";
    case "efatura":
      return "📝";
    case "server":
      return "⚡";
    default:
      return "❌";
  }
};
