import { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import lightenColor from "../lightenColor";

import { useHttpClient } from "../../../../../shared/hooks/http-hook";
import { AuthContext } from "../../../../../shared/context/auth-context";
import { ClinicaContext } from "../../../../../shared/context/clinica-context";

//MUI TABLE
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//MUI MODAL
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
//

//Mui button dropdown
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
//

//Mui Select data
//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { useLoading } from "../../../../../shared/context/LoadingContext";
import "./MarcacoesAssistente.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function MarcacoesAssistente({ verPdf }) {
  const { startLoading, stopLoading } = useLoading();

  const [marcacoes, setMarcacoes] = useState();
  const [allMarcacoes, setAllMarcacoes] = useState();
  const [allMarcacoesSpecificDate, setAllMarcacoesSpecificDate] = useState([]);
  const [open, setOpen] = useState(false);
  const [fotoCliente, setFotoCliente] = useState("");
  const [dataSelectAtendimentosDe, setDataSelectAtendimentosDe] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [medicosSelectValue, setMedicosSelectValue] = useState("todos");
  const [nrMarcacoes, setNrMarcacoes] = useState(0);
  const [listaPresenca, setListaPresenca] = useState([]);
  const [dataDatePicker, setDataDatePicker] = useState(moment());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { sendRequest } = useHttpClient();
  const [verAtendidos, setVerAtendidos] = useState(false);
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);

  //Mui Button adicionar
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);

  const handleClickDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleImprimir = (idMedico) => {
    handleCloseDropdown();

    verPdf(
      idMedico,
      marcacoes,
      dataSelectAtendimentosDe === "escolher-data"
        ? moment(dataDatePicker).format("DD-MM-YYYY")
        : dataSelectAtendimentosDe
    );
  };

  useEffect(() => {
    if (auth.usuariosAssociados && auth.usuariosAssociados.length === 1)
      setMedicosSelectValue(auth.usuariosAssociados[0].id);

    const fetchMarcacoesAndListaPresenca = async () => {
      try {
        startLoading();
        const formData = new FormData();
        formData.append(
          "medicos",
          JSON.stringify(auth.usuariosAssociados.map((m) => m.id))
        );

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcacoessecretaria/${clinica.clinica._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseData2 = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/marcacoessecretaria/${clinica.clinica._id}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        const responseDataLista = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/listapresenca/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setListaPresenca(responseDataLista.listaPresenca);
        const tempAllMarcacoes = [];

        responseData.marcacoes.forEach((mr) => {
          tempAllMarcacoes.push({
            id: mr._id,
            nome: mr.cliente.nome,
            contacto: mr.cliente.contacto,
            foto: mr.cliente.foto,
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
            idPlano: mr.avaliacao ? null : mr.planoTratamento,
            idCliente: mr.cliente._id,
            medico: mr.medico,
            categoriaTratamento: mr.categoriaTratamento,
            avaliacao: mr.avaliacao ? true : false,
            atendido: mr.atendido,
            faltou: mr.faltou,
          });
        });

        responseData2.marcacoes.forEach((mr) => {
          tempAllMarcacoes.push({
            id: mr._id,
            nome: mr.cliente.nome,
            contacto: mr.cliente.contacto,
            foto: mr.cliente.foto,
            data_inicio_execucao: mr.data_inicio_execucao,
            data_fim_execucao: mr.data_fim_execucao,
            idCliente: mr.cliente._id,
            medico: mr.medico,
            categoriaTratamento: mr.categoriaTratamento,
            avaliacao: mr.avaliacao ? true : false,
            atendido: mr.atendido,
            faltou: mr.faltou,
            emTransicao: true,
          });
        });

        const tempMarcacoes = tempAllMarcacoes.filter(
          (mr) =>
            moment(mr.data_inicio_execucao).format("DD-MM-YYYY") ===
            moment().format("DD-MM-YYYY")
        );

        const sortedAllMarcacoes = tempAllMarcacoes.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });

        const sorteMarcacoes = tempMarcacoes.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });

        setAllMarcacoes(sortedAllMarcacoes);
        setNrMarcacoes(sorteMarcacoes.length);
        setMarcacoes(sorteMarcacoes);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchMarcacoesAndListaPresenca();
  }, [sendRequest, clinica, auth]);

  //Modal Imagem do cliente
  const handleOpen = (foto) => {
    setOpen(true);
    setFotoCliente(foto);
  };

  const handleClose = () => setOpen(false);

  const filterMarcacoes = useCallback(
    (medico, data, marcacoesSpecificDate = [...allMarcacoesSpecificDate]) => {
      let tempMarcacoes;
      let temp;
      if (data === "escolher-data") {
        if (medico === "todos") {
          temp = [...marcacoesSpecificDate];
        } else {
          temp = marcacoesSpecificDate.filter((mr) => mr.medico._id === medico);
        }
      } else if (data === moment().format("DD-MM-YYYY")) {
        if (medico === "todos") {
          temp = allMarcacoes.filter(
            (mr) =>
              moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
          );
        } else {
          temp = allMarcacoes.filter(
            (mr) =>
              mr.medico._id === medico &&
              moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
          );
        }
        temp.sort((a, b) => {
          return (
            new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
          );
        });
      } else {
        if (medico === "todos") {
          temp = allMarcacoes.filter(
            (mr) =>
              moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
          );
        } else {
          temp = allMarcacoes.filter(
            (mr) =>
              mr.medico._id === medico &&
              moment(mr.data_inicio_execucao).format("DD-MM-YYYY") === data
          );
        }
      }
      tempMarcacoes = temp.sort((a, b) => {
        return (
          new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
        );
      });

      setMarcacoes(tempMarcacoes);
      setNrMarcacoes(tempMarcacoes.length);
    },
    [allMarcacoes, allMarcacoesSpecificDate]
  );

  const changeSelectAtendimentosDe = useCallback(
    (e) => {
      setDataSelectAtendimentosDe(e.target.value);
      if (e.target.value === "escolher-data") {
        setShowDatePicker(true);
      } else {
        setShowDatePicker(false);
        filterMarcacoes(medicosSelectValue, e.target.value);
      }
    },

    [filterMarcacoes, medicosSelectValue]
  );

  const changeMedicoDaLista = (e) => {
    setMedicosSelectValue(e.target.value);
    filterMarcacoes(e.target.value, dataSelectAtendimentosDe);
  };

  const handleTerminar = (id) => {
    setMarcacoes((current) => current.filter((m) => m.id !== id));
    setAllMarcacoes((current) => current.filter((m) => m.id !== id));
  };

  const handleAguardando = useCallback(
    async (id) => {
      if (!listaPresenca.includes(id)) {
        try {
          const formData = new FormData();
          formData.append("clienteId", id);

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/listaPresenca/adicionarclientealista/${clinica.clinica._id}`,
            "PATCH",
            formData,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          setListaPresenca((prev) => [...prev, id]);
        } catch (err) {
          console.error("err", err);
        }
      }
    },
    [auth.token, clinica.clinica._id, listaPresenca, sendRequest]
  );

  const changeDataDatePicker = async (value) => {
    setDataDatePicker(value);
    startLoading();
    try {
      const formData = new FormData();
      formData.append(
        "medicos",
        JSON.stringify(auth.usuariosAssociados.map((m) => m.id))
      );
      formData.append("date", value);
      const tempMarcacoes = [];
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agenda/marcacoessecretaria/specificdate/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const responseData2 = await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/agendamentosemtransicao/marcacoessecretaria/specificDate/${clinica.clinica._id}`,
        "PATCH",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      responseData.marcacoes.forEach((mr) => {
        tempMarcacoes.push({
          id: mr._id,
          nome: mr.cliente.nome,
          contacto: mr.cliente.contacto,
          foto: mr.cliente.foto,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
          idCliente: mr.cliente._id,
          medico: mr.medico,
          categoriaTratamento: mr.categoriaTratamento,
          avaliacao: mr.avaliacao ? true : false,
          atendido: mr.atendido,
          faltou: mr.faltou,
          emTransicao: true,
        });
      });

      responseData2.marcacoes.forEach((mr) => {
        tempMarcacoes.push({
          id: mr._id,
          nome: mr.cliente.nome,
          contacto: mr.cliente.contacto,
          foto: mr.cliente.foto,
          data_inicio_execucao: mr.data_inicio_execucao,
          data_fim_execucao: mr.data_fim_execucao,
          idCliente: mr.cliente._id,
          medico: mr.medico,
          categoriaTratamento: mr.categoriaTratamento,
          avaliacao: mr.avaliacao ? true : false,
          atendido: mr.atendido,
          faltou: mr.faltou,
          emTransicao: true,
        });
      });

      const temp = tempMarcacoes.sort((a, b) => {
        return (
          new Date(a.data_inicio_execucao) - new Date(b.data_inicio_execucao)
        );
      });

      setAllMarcacoesSpecificDate(temp);
      setNrMarcacoes(temp.length);

      filterMarcacoes(medicosSelectValue, "escolher-data", temp);
    } catch (err) {
      console.error("err", err);
    }
    stopLoading();
  };

  return (
    <>
      {auth && auth.usuariosAssociados && (
        <>
          <div className="marcacoes-secretaria-header-row">
            <div className="box-escolha-de-dia">
              <span className="box-title--atendimentosSecretaria">
                Atendimentos de:{" "}
              </span>
              <select
                onChange={changeSelectAtendimentosDe}
                value={dataSelectAtendimentosDe}
              >
                <option
                  value={moment().subtract(1, "days").format("DD-MM-YYYY")}
                >
                  Ontem
                </option>
                <option value={moment().format("DD-MM-YYYY")}>Hoje</option>
                <option value={moment().add(1, "days").format("DD-MM-YYYY")}>
                  Amanhã
                </option>
                <option value="escolher-data">Escolher Data</option>
              </select>

              {auth.usuariosAssociados.length > 1 && (
                <>
                  <span className="box-title--atendimentosSecretaria">
                    do(s) médico(s):{" "}
                  </span>
                  <select onChange={changeMedicoDaLista}>
                    <option value="todos">Todos</option>
                    {auth.usuariosAssociados.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.nome}
                      </option>
                    ))}
                  </select>
                </>
              )}

              {showDatePicker && (
                <div>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    className="adicionar-despesa__modal__container--span1"
                  >
                    <DesktopDatePicker
                      className="valorDataCaixa__fields--field muiDatePicker"
                      label="Data*"
                      inputFormat="DD-MM-YYYY"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(value) => changeDataDatePicker(value)}
                      value={dataDatePicker}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <span className="box-title--atendimentosSecretaria">{`${nrMarcacoes} marcações`}</span>

              <div
                className="checkbox-wrapper-51"
                id="ver-atendidos__checkbox-container"
              >
                <span className="box-title--atendimentosSecretaria">
                  Mostrar Atendidos
                </span>
                <input
                  type="checkbox"
                  id="mostrarRecebidos"
                  onChange={(e) => setVerAtendidos(e.target.checked)}
                  checked={verAtendidos}
                />
                <label htmlFor="mostrarRecebidos" className="toggle">
                  <span>
                    <svg width="10px" height="10px" viewBox="0 0 10 10">
                      <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                    </svg>
                  </span>
                </label>
              </div>
            </div>

            <div>
              <Button
                variant="contained"
                color="success"
                id="basic-button"
                aria-controls={openDropdown ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropdown ? "true" : undefined}
                onClick={handleClickDropdown}
                style={{ padding: "5px 10px", fontSize: "13px" }}
              >
                Imprimir
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropdown}
                onClose={handleCloseDropdown}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {auth.usuariosAssociados.map((m, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleImprimir.bind(null, m)}
                    style={{ padding: "5px 10px", fontSize: "14px" }}
                  >
                    {m.nome}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </>
      )}
      {marcacoes && marcacoes.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="marcacoesAdministrativo__tableHeading">
                <TableCell>Cliente</TableCell>
                <TableCell align="left">Nome</TableCell>
                <TableCell align="left">Médico</TableCell>
                <TableCell align="left">Procedimento</TableCell>
                <TableCell align="left">Contacto</TableCell>
                <TableCell align="left">Horário Inicial</TableCell>
                <TableCell align="left">Horário Final</TableCell>
                <TableCell align="left">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {marcacoes.map((row) => {
                if (!verAtendidos && (row.atendido || row.faltou)) {
                  return null; // Skip rendering rows that don't match the condition
                }
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <img
                        src={`${
                          row.foto
                            ? process.env.REACT_APP_BACKEND_LINK +
                              "/" +
                              row.foto
                            : "/images/fallBackPicture.png"
                        }`}
                        alt=""
                        className="marcacoesAdministrativo__clienteImg"
                        onClick={handleOpen.bind(
                          null,
                          `${process.env.REACT_APP_BACKEND_LINK}/${row.foto}`
                        )}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        to={`/clientes/${row.idCliente}`}
                        className="linkNoNomeDoCliente"
                      >
                        <span>{row.nome}</span>
                      </Link>
                    </TableCell>
                    <TableCell align="left" style={{ color: row.medico.cor }}>
                      <span
                        className="marcacoesAdministrativo__nome__medico__span"
                        style={{
                          color: row.medico.cor,
                          backgroundColor: lightenColor(
                            row.medico.cor,
                            40,
                            0.4
                          ),
                        }}
                      >
                        {row.medico.name}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {row.avaliacao
                        ? "Avaliação"
                        : row.categoriaTratamento.categoria}
                    </TableCell>
                    <TableCell align="left">{row.contacto}</TableCell>
                    <TableCell align="left">
                      {moment(row.data_inicio_execucao).format("HH:mm")}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.data_fim_execucao).format("HH:mm")}
                    </TableCell>
                    <TableCell align="left">
                      {dataSelectAtendimentosDe ===
                      moment().format("DD-MM-YYYY") ? (
                        row.atendido ? (
                          <span
                            className="spanMensagemAtendido"
                            onClick={handleTerminar.bind(null, row.id)}
                          >
                            Atendido
                          </span>
                        ) : row.faltou ? (
                          <span
                            className="spanMensagemFaltou"
                            onClick={handleTerminar.bind(null, row.id)}
                          >
                            Faltou
                          </span>
                        ) : null
                      ) : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {marcacoes && marcacoes.length === 0 && (
        <span className="nenhumProcedimentoMsg">
          Nenhum procedimento por mostrar
        </span>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={fotoCliente} alt="" className="fotoModal" />
        </Box>
      </Modal>
    </>
  );
}

export default MarcacoesAssistente;
