import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import { AuthContext } from "../../../shared/context/auth-context";
import "../../../shared/css/lists.scss";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import DatatableViewOnly from "../components/datatable/DatatableViewOnly";

function AgendamentosViewOnly() {
  const [medicos, setMedicos] = useState();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchMedicos = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/${clinica.clinica._id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        const temp = [];
        response.users.forEach((u) => {
          if (
            u.role.role === "Dentista" ||
            u.role.role === "Médico/Administrador"
          ) {
            temp.push({ ...u, nome: u.name });
          }
        });
        setMedicos(temp);
      } catch (err) {}
    };
    fetchMedicos();
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        {medicos && (
          <>
            <Navbar
              title="Agendamentos"
              icon={CalendarMonthOutlinedIcon}
              medicos={medicos}
            />
            <DatatableViewOnly medicos={medicos} />
          </>
        )}
      </div>
    </div>
  );
}

export default AgendamentosViewOnly;
