import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import dataGridData from "./components/datagridData";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import moment from "moment";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import toast from "react-hot-toast";

import { useLoading } from "../../../shared/context/LoadingContext";
import FiltrosDfesPorEmitir from "./components/filtros/FiltrosDfesPorEmitir";

function DfesPorEmitir({ clinicaId, auth }) {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const [rows, setRows] = useState([]);
  const [contasCorrentes, setContasCorrentes] = useState();
  const [contasCorrentesAbsolut, setContasCorrentesAbsolut] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [entradasAbsolut, setEntradasAbsolut] = useState([]);

  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroCaixa, setFiltroCaixa] = useState();
  const [filtroCategoria, setFiltroCategoria] = useState();
  const [filtroMetodoPagamento, setFiltroMetodoPagamento] = useState();

  const fetchDfesPorEmitir = async (startDate, endDate) => {
    startLoading();
    try {
      let responseDataCc, responseDataEntradas;
      if (startDate === "todos" || endDate === "todos") {
        [responseDataCc, responseDataEntradas] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/todos/poremitir/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/todos/poremitir/${clinicaId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);
      } else {
        const formData = new FormData();
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        [responseDataCc, responseDataEntradas] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/financeiro/daterange/poremitir/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/entradas/clinica/daterange/poremitir/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);
      }

      const updateState = (data, permission, setState, setAbsoluteState) => {
        if (auth.perm.includes(permission)) {
          setState(data);
          setAbsoluteState(data);
        } else {
          setState([]);
          setAbsoluteState([]);
        }
      };

      updateState(
        responseDataCc.contasCorrentes,
        "r-rec",
        setContasCorrentes,
        setContasCorrentesAbsolut
      );

      updateState(
        responseDataEntradas,
        "r-rec",
        setEntradas,
        setEntradasAbsolut
      );

      handleFiltrar(
        responseDataCc.contasCorrentes,
        responseDataEntradas,
        [],
        null,
        null,
        null
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    fetchDfesPorEmitir(new Date().toISOString(), new Date().toISOString());
  }, []);

  useEffect(() => {
    let dataParaDataGrid = [];

    if (contasCorrentes) {
      contasCorrentes.forEach((contaCorrente) => {
        contaCorrente.contas.forEach((conta) => {
          conta.parcelas.forEach((parcela) => {
            dataParaDataGrid.push({
              tipo: "receita",
              cliente: contaCorrente.cliente.nome,
              idCliente: contaCorrente.cliente._id,
              dadosCliente: contaCorrente.cliente,
              idContaCorrente: contaCorrente._id,
              beneficio: conta.orcamento.beneficio,
              id: parcela._id,
              contaId: conta._id,
              dataLimitePagamento: parcela.dataLimitePagamento,
              dataPagamento: parcela.dataPagamento,
              quantiaPorPagar: parcela.quantiaPorPagar,
              quantiaPaga: parcela.quantiaPaga,
              metodoPagamento: parcela.metodoPagamento,
              entrada: parcela.entrada,
              nrParcela: parcela.nrParcela,
              parcelaPaga: parcela.parcelaPaga,
              documentoEletronico: parcela.documentoEletronico,
              codigo: conta.orcamento.codigo,
            });
          });
        });
      });
    }

    if (entradas && entradas.length > 0) {
      entradas.forEach((entrada) => {
        dataParaDataGrid.push({
          tipo: "entrada",
          id: entrada._id,
          cliente:
            entrada.tipo === "reembolso"
              ? entrada.cliente
              : entrada.clienteFornecedor,
          dataPagamento: entrada.dataPagamento,
          valor: entrada.valor,
          metodoPagamento: entrada.metodoPagamento,
          descricao: entrada.descricao,
          documentoEletronico: entrada.documentoEletronico,
        });
      });
    }

    dataParaDataGrid = dataParaDataGrid.sort(
      (a, b) => new Date(a.dataPagamento) - new Date(b.dataPagamento)
    );

    setRows(dataGridData(dataParaDataGrid));
  }, [contasCorrentes, entradas]);

  const handleFiltrar = (
    newContasCorrentes,
    newEntradas,
    filtros,
    filtroCai,
    filtroCat,
    filtroMet
  ) => {
    setFiltroCaixa(filtroCai);
    setFiltroCategoria(filtroCat);
    setFiltroMetodoPagamento(filtroMet);
    setFiltrosAplicados(filtros);
    if (newContasCorrentes) {
      setContasCorrentes([...newContasCorrentes]);
    }
    if (newEntradas) {
      setEntradas([...newEntradas]);
    }
  };

  const handleOcultarParcela = async (idParcela, idConta, idContaCorrente) => {
    try {
      let resposta;
      const formData = new FormData();
      formData.append("idParcela", idParcela);
      formData.append("idConta", idConta);
      try {
        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/ocultarparcela/${idContaCorrente}`,
          "PATCH",
          formData,
          { Authorization: "Bearer " + auth.token }
        );
      } catch (err) {
        console.error("err", err);
      }

      if (resposta.success) {
        const novasContasCorrentes = contasCorrentes.map((cc) => {
          if (cc._id !== idContaCorrente) return cc;
          return {
            ...cc,
            contas: cc.contas.map((conta) => {
              if (conta._id !== idConta) return conta;

              return {
                ...conta,
                parcelas: conta.parcelas.filter((p) => p._id !== idParcela),
              };
            }),
          };
        });

        setContasCorrentes(novasContasCorrentes);

        toast.success("Parcela ocultada com sucesso!");
      } else {
        toast.error(
          "Ocorreu um erro ao ocultar a parcela. Por favor tente novamente."
        );
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const actionColumn = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.tipo === "receita" && (
              <span className="spanCellPago">
                {moment(params.row.dataPagamento).format("DD-MM-YYYY")}
              </span>
            )}

            {params.row.tipo === "entrada" && (
              <span>
                {params.row.dataPagamento
                  ? moment(params.row.dataPagamento).format("DD-MM-YYYY")
                  : moment(params.row.dataLimitePagamento).format("DD-MM-YYYY")}
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 6,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoNome">
                <span className="spanCellPago">
                  {params.row.nome}{" "}
                  <Link
                    to={`/clientes/${params.row.idCliente}-debitos`}
                    className="link-cliente-receita"
                  >
                    {" "}
                    {params.row.cliente}
                  </Link>
                </span>
                {params.row.nrParcela && (
                  <Tooltip
                    title={`Parcelado em ${params.row.nrParcela[2]} vezes`}
                  >
                    <IconButton>
                      <span className="nrParcela">{params.row.nrParcela}</span>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="spanCellPago">
                {params.row.descricao}
                {" - "}
                {params.row.cliente && (
                  <span style={{ color: "#175c93" }}>
                    {params.row.cliente.nome}
                  </span>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "estado",
      headerName: "Pagamento",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" && (
              <div className="cellDebitoEstado">
                <span
                  className={`debitos__container__estado ${
                    params.row.estado === "Em atraso"
                      ? "debitos__container__estado--atraso"
                      : params.row.estado === "Pago"
                      ? "debitos__container__estado--pago"
                      : ""
                  }`}
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}

            {params.row.tipo === "entrada" && (
              <div className="cellDebitoEstado">
                <span
                  className={
                    "debitos__container__estado debitos__container__estado--pago"
                  }
                >
                  {params.row.metodoPagamento}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="cellAction" style={{ marginLeft: "auto" }}>
              <span
                className={`debitos__container__valor ${
                  params.row.estado === "Em atraso"
                    ? "debitos__container__valor--atraso"
                    : params.row.estado === "Pago"
                    ? "debitos__container__valor--pago"
                    : ""
                }`}
              >
                {params.row.valor}
              </span>
            </div>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.tipo === "receita" &&
              params.row.beneficio === "Particular" &&
              params.row.metodoPagamento === "Dinheiro" && (
                <span
                  className="financeiro__ocultarBtn"
                  onClick={handleOcultarParcela.bind(
                    null,
                    params.row.id,
                    params.row.contaId,
                    params.row.idContaCorrente
                  )}
                >
                  Ocultar
                </span>
              )}
          </>
        );
      },
    },
  ];

  return (
    <div className="financeiro__container caixa">
      <div className="financeiro__adicionarEFiltros">
        <FiltrosDfesPorEmitir
          contasCorrentes={contasCorrentesAbsolut}
          entradas={entradasAbsolut}
          handleFiltrar={handleFiltrar}
          filtrosAplicados={filtrosAplicados}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          clinicaId={clinicaId}
          auth={auth}
          fetchContasCorrentesDfesPorEmitir={fetchDfesPorEmitir}
        />
      </div>

      {rows && (
        <div style={{ height: 600, width: "100%" }}>
          <DataGrid
            rows={rows}
            pageSize={10}
            rowsPerPageOptions={[10]}
            columns={actionColumn}
          />
        </div>
      )}
    </div>
  );
}

export default DfesPorEmitir;
