import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "moment/locale/pt";
import TextField from "@mui/material/TextField";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";
import PdfImpressoWrapper from "../../../shared/components/PdfGenerator/PdfImpresso/PdfImpressoWrapper";
import ModeloForm from "./components/ModeloForm";

import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";

import ModalInfo from "../../../shared/components/UIElements/ModalInfo";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import { useLoading } from "../../../shared/context/LoadingContext";

import "../../../style/formError.scss";
import "./NewImpresso.scss";
function NewImpresso() {
  const { startLoading, stopLoading } = useLoading();

  const [showPdf, setShowPdf] = useState(false);
  const [pdfData, setPdfData] = useState();
  const [sugestoes, setSugestoes] = useState();
  const [cliente, setCliente] = useState();
  const [clinicaData, setClinicaData] = useState();
  const [medicoData, setMedicoData] = useState();
  const [sugestaoModalOpen, setSugestaoModalOpen] = useState(false);
  const [textoImpresso, setTextoImpresso] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const clienteId = useParams().clienteId;

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
  });

  //fetch receita, medicos e avaliacao
  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [
          sugestoesResponse,
          clienteResponse,
          clinicaResponse,
          medicoResponse,
        ] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesImpresso/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/${clienteId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/clinicas/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/${auth.userId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process sugestoes
        setSugestoes(
          sugestoesResponse.sugestoesImpresso.map((s) => ({
            id: s.id,
            titulo: s.sugestao.titulo,
            textoImpresso: s.sugestao.textoImpresso,
          }))
        );

        // Set cliente
        setCliente(clienteResponse.cliente);

        // Set clinica data
        setClinicaData(clinicaResponse.clinica);

        // Set medico data
        setMedicoData(medicoResponse.user);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [sendRequest, clinica.clinica._id, clienteId, auth.userId, auth.token]);

  const showPreviousPage = () => {
    setShowPdf(false);
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return {
        ...currentModalProps,
        show: false,
      };
    });
  };

  const submitHandler = async () => {
    startLoading();
    try {
      const formData2 = new FormData();
      formData2.append("clienteId", clienteId);
      formData2.append("medicoId", auth.userId);
      formData2.append("clinicaId", clinica.clinica._id);
      formData2.append("impresso", textoImpresso);
      formData2.append("criadoPor", auth.userId);

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/impressos`,
        "POST",
        formData2,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      setPdfData({
        cliente: cliente,
        clinica: clinicaData,
        medico: medicoData,
        textoImpresso: textoImpresso,
      });
      setShowPdf(true);
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  const handleAdicionarSugestao = async (titulo, texto) => {
    if (!titulo || titulo.trim() === "") {
      toast.error("O titulo da sugestão não possui dados.");
    } else if (!texto || texto.trim() === "") {
      toast.error("O texto da sugestão não possui dados.");
    } else {
      startLoading();
      let resposta;
      try {
        const formData = new FormData();
        formData.append("clinicaId", clinica.clinica._id);
        formData.append(
          "sugestao",
          JSON.stringify({ titulo: titulo, textoImpresso: texto })
        );
        formData.append("criadoPor", auth.userId);

        resposta = await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesImpresso`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );

        toast.success("Sugestão guardada com sucesso");
        if (resposta) {
          setSugestoes((prev) => {
            return [
              ...prev,
              {
                id: resposta.sugestaoImpresso._id,
                titulo: titulo,
                textoImpresso: texto,
              },
            ];
          });
        }
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
        closeModalSugestao();
      }
    }
  };

  const deleteSugestao = async (id) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/sugestoesImpresso/${id}`,
        "DELETE",
        {},
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setSugestoes((current) => {
        return current.filter((c) => c.id !== id);
      });
      toast.success("Sugestão apagada com sucesso");
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const sugestãoDeletionConfirmation = (id) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Eliminação de sugestão!`,
      message: `Tem a certeza que pretende eliminar a sugestão?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: deleteSugestao.bind(null, id),
    });
  };

  const closeModalSugestao = () => {
    setSugestaoModalOpen(false);
  };

  const preSubmitHandler = () => {
    if (!textoImpresso || textoImpresso.trim() === "") {
      toast.error("O campo de texto do impresso não pode estar vazio.");
    } else {
      if (!isSubmitting) {
        setIsSubmitting(true);
        submitHandler();
      }
    }
  };

  return (
    <>
      {showPdf && pdfData && (
        <PdfImpressoWrapper
          showPreviousPage={showPreviousPage}
          impressoData={pdfData}
        />
      )}

      {!showPdf && (
        <>
          <ModalInfo {...modalProps} />
          <div className="new">
            <Sidebar />
            <div className="newContainer">
              {cliente && (
                <Navbar
                  title={`Gerar Prescrição - ${cliente.nome}`}
                  icon={MedicationOutlinedIcon}
                />
              )}
              <div className="bottom-orcamento caixa">
                <div className="impresso-texto">
                  <TextField
                    margin="dense"
                    id="sugestaoTextoImpresso"
                    label="Texto"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={textoImpresso}
                    onChange={(e) => setTextoImpresso(e.target.value)}
                  />
                </div>

                {sugestoes && (
                  <div className="prescricao-sugestoes-container">
                    {sugestoes.map((s, i) => (
                      <div className="prescricao-sugestoes-row" key={i}>
                        <div className="prescricao-sugestoes">
                          {s.titulo} - {s.textoImpresso}
                        </div>

                        <div className="prescricao-sugestoes-buttons">
                          <span
                            className="prescricao-sugestoes-add-btn"
                            onClick={() => setTextoImpresso(s.textoImpresso)}
                          >
                            Adicionar
                          </span>

                          <span
                            className="prescricao-sugestoes-delete-btn"
                            onClick={sugestãoDeletionConfirmation.bind(
                              null,
                              s.id
                            )}
                          >
                            Apagar
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Button
                  variant="outlined"
                  onClick={() => setSugestaoModalOpen(true)}
                >
                  Adicionar Sugestão
                </Button>

                <ModeloForm
                  handleAdicionarSugestao={handleAdicionarSugestao}
                  sugestaoModalOpen={sugestaoModalOpen}
                  closeModalSugestao={closeModalSugestao}
                />
                <div className="botoes">
                  <button
                    className="cancel-btn botao"
                    onClick={() => navigate(`../../../clientes/${clienteId}`)}
                  >
                    Cancelar
                  </button>

                  <button
                    disabled={false}
                    className="blue-button"
                    onClick={preSubmitHandler}
                  >
                    {isSubmitting ? "Gerando..." : "Gerar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default NewImpresso;
