const dataGridData = (data) => {
  const rows = data.map((dt) => {
    return {
      id: dt.id,
      dataLimitePagamento: dt.dataLimitePagamento,
      dataPagamento: dt.dataPagamento,
      valor: `${dt.valor.toLocaleString("pt-BR")}$00`,
      tipo: dt.tipo,
      estado: dt.estado,
      metodoPagamento: dt.metodoPagamento,
      despesaPaga: dt.despesaPaga,
      descricao: dt.descricao,
      comprovativo: dt.comprovativo,
      caixa: dt.caixa,
      categoria: dt.categoria,
    };
  });

  return rows;
};

export default dataGridData;
