import React, { useEffect, useCallback, useState, useRef } from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

import "./FiltrosOrcamento.scss";
import ModalFiltrosOrcamentos from "./ModalFiltrosOrcamentos";

function FiltrosOrcamento({
  fetchDataByRange,
  handleFiltrar,
  filtrosAplicados,
  orcamentosAbsolut,
  orcamentosParaTextSearch,
  filtroTextoSearch,
}) {
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(1, "months").startOf("day")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [openModalFiltros, setOpenModalFiltros] = useState(false);
  const [textSearchQuery, setTextSearchQuery] = useState("");
  const timerRef = useRef(null);

  useEffect(() => {
    if (filtroTextoSearch === "") {
      setTextSearchQuery("");
    }
  }, [filtroTextoSearch]);

  const handleCloseModalFiltros = useCallback(() => {
    setOpenModalFiltros(false);
  }, []);

  const dateChange = (data, tipo) => {
    if (tipo === "inicial") {
      setDataInicial(data);
      fetchDataByRange(data, dataFinal);
    } else {
      setDataFinal(data);
      fetchDataByRange(dataInicial, data);
    }
  };

  const applyTextSearchOrcamentos = (query) => {
    if (query) {
      const lowerCaseText = query.toLowerCase();
      const filteredOrcamentos = [];

      orcamentosParaTextSearch.forEach((orcamento) => {
        if (orcamento.nome.toLowerCase().includes(lowerCaseText))
          filteredOrcamentos.push({ ...orcamento });
      });
      return filteredOrcamentos;
    } else {
      return orcamentosParaTextSearch;
    }
  };

  const handleTextSearch = (query) => {
    setTextSearchQuery(query);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const filteredOrcamentos = applyTextSearchOrcamentos(query);
      handleFiltrar(filteredOrcamentos, [], null, query);
    }, 500);
  };

  const handleModalFiltros = (filteredOrcamentos, appliedFilters) => {
    // Reset text search when modal filters are applied
    setTextSearchQuery("");
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    handleFiltrar(filteredOrcamentos, appliedFilters, "");
  };

  return (
    <div className="relatorio__filtros__container">
      <div className="relatorio__filtros__container__left">
        <span className="relatorio__filtros__texto">Periodo</span>
        <div className="financeiro__filtros__periodoContainer">
          <div className="financeiro__filtros__periodoContainer__dataInicioContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => dateChange(value, "inicial")}
                value={dataInicial}
              />
            </LocalizationProvider>
          </div>
          <div className="financeiro__filtros__periodoContainer__dataFimContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => dateChange(value, "final")}
                value={dataFinal}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="textBoxSearch__container">
          <input
            type="text"
            placeholder="Procurar por cliente"
            className="textBoxSearch__input"
            value={textSearchQuery}
            onChange={(e) => handleTextSearch(e.target.value)}
          />
          <SearchIcon className="financeiro__filtros__search-icon" />
        </div>

        <div
          className={`financeiro__filtros__filtrar ${
            filtrosAplicados && filtrosAplicados.length > 0
              ? "financeiro__filtros__filtrar--ativo"
              : ""
          }`}
          onClick={(e) => setOpenModalFiltros(true)}
        >
          <FilterAltIcon
            className={
              filtrosAplicados && filtrosAplicados.length > 0
                ? "filtro__ativo financeiro__filtros__icon"
                : "financeiro__filtros__icon"
            }
          />
          <span
            className={
              filtrosAplicados && filtrosAplicados.length > 0
                ? "filtro__ativo financeiro__filtros__icon-text"
                : "financeiro__filtros__icon-text"
            }
          >
            Filtrar
          </span>
        </div>
      </div>
      <div className="relatorio__filtros__container__right">
        <span className="relatorio__filtros__container__right__btn relatorio__filtros__container__right__btn--blue">
          Exportar Excel
        </span>
        <span className="relatorio__filtros__container__right__btn relatorio__filtros__container__right__btn--green">
          Gerar Relatório
        </span>
      </div>

      {openModalFiltros && (
        <ModalFiltrosOrcamentos
          openModalFiltros={openModalFiltros}
          handleCloseModalFiltros={handleCloseModalFiltros}
          handleFiltrar={handleModalFiltros}
          orcamentos={orcamentosAbsolut}
          filtrosAplicados={filtrosAplicados ? filtrosAplicados : []}
        />
      )}
    </div>
  );
}

export default FiltrosOrcamento;
