// Calculate the subtotal from the service list
const calculateSubtotal = (serviceList) => {
  return serviceList.reduce(
    (sum, service) => sum + (service.show ? service.pr_total : 0),
    0
  );
};

// Calculate the total after applying the discount
const calculateTotal = (
  newSubtotal,
  discount,
  discountType,
  originalSubtotal
) => {
  if (discountType === "%") {
    const discountEmPercentagem = originalSubtotal / discount;
    return Math.round(newSubtotal - newSubtotal / discountEmPercentagem);
  } else if (discountType === "$") {
    return Math.round(newSubtotal - discount);
  }
  return newSubtotal;
};

// Main function to update all calculations
const updateCalculations = (
  serviceList,
  originalDiscount,
  discountType,
  originalSubtotal
) => {
  if (!serviceList || !Array.isArray(serviceList) || serviceList.length === 0) {
    console.error("Invalid serviceList:", serviceList);
    return { newSubtotal: 0, newTotal: 0 };
  }

  const newSubtotal = calculateSubtotal(serviceList);
  const newTotal = calculateTotal(
    newSubtotal,
    originalDiscount,
    discountType,
    originalSubtotal
  );

  return {
    newSubtotal,
    newTotal,
  };
};

export { calculateSubtotal, calculateTotal, updateCalculations };
