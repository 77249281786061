import moment from "moment";

const dataGridData = (data, nomeCliente) => {
  const rows = data.map((dt) => {
    switch (dt.tipo) {
      case "receita":
        return {
          tipo: "receita",
          orcamento: dt.orcamento,
          id: dt.id,
          contaId: dt.contaId,
          nome: dt.entrada ? "Entrada" : `Parcela`,
          data: moment(dt.dataLimitePagamento).format("DD-MM-YYYY"),
          valor:
            dt.quantiaPorPagar > 0
              ? `${dt.quantiaPorPagar.toLocaleString("pt-BR")}$00`
              : `${dt.quantiaPaga.toLocaleString("pt-BR")}$00`,
          receber: "receber",
          estado: dt.estado,
          metodoPagamento: dt.metodoPagamento,
          nrParcela: dt.nrParcela,
          dataLimitePagamento: dt.dataLimitePagamento,
          dataPagamento: dt.dataPagamento,
          parcelaPaga: dt.parcelaPaga,
          documentoEletronico: dt.documentoEletronico,
          cancelamentosDocumentosEletronicos:
            dt.cancelamentosDocumentosEletronicos,
          nrRecibo: dt.nrRecibo,
          motivoEncerramento: dt.motivoEncerramento,
          status: dt.status,
        };
      case "entrada":
        return {
          id: dt.id,
          dataPagamento: dt.dataPagamento,
          dataLimitePagamento: dt.dataLimitePagamento,
          valor: dt.valor ? `${dt.valor.toLocaleString("pt-BR")}$00` : "",
          tipo: dt.tipo,
          cliente: dt.cliente,
          tipoEntrada: dt.tipoEntrada,
          metodoPagamento: dt.metodoPagamento,
          descricao: dt.descricao,
          comprovativo: dt.comprovativo,
          nrRecibo: dt.nrRecibo,
          documentoEletronico: dt.documentoEletronico,
          cancelamentosDocumentosEletronicos:
            dt.cancelamentosDocumentosEletronicos,
          entradaRecebida: dt.entradaRecebida,
          faturaEReciboEletronicos: dt.faturaEReciboEletronicos,
        };
      default:
        return {};
    }
  });

  return rows;
};

export default dataGridData;
