import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.scss";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import BorderHorizontalOutlinedIcon from "@mui/icons-material/BorderHorizontalOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupIcon from "@mui/icons-material/Group";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { useHttpClient } from "../../hooks/http-hook";
import logo from "./logo.png";

import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { ClinicaContext } from "../../context/clinica-context";

function Sidebar() {
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const navigate = useNavigate();
  const [medico, setMedico] = useState();

  const { sendRequest } = useHttpClient();

  const logout = () => {
    navigate("/");
    auth.logout();
  };

  useEffect(() => {
    if (auth.role === "Administrador Não Médico") {
      const fetchMedicos = async () => {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/users/ativos/${clinica.clinica._id}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );

          const temp = response.users.filter(
            (u) =>
              u.role.role === "Dentista" ||
              u.role.role === "Médico/Administrador"
          );
          setMedico({ id: temp[0].id, nome: temp[0].name });
        } catch (err) {}
      };
      fetchMedicos();
    }
  }, [auth.role, auth.token, clinica.clinica._id, sendRequest]);

  return (
    <>
      {auth && auth.perm && (
        <div className="sidebar">
          <div className="sidebar__top">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img className="sidebar__logo" src={logo} alt="" />
            </Link>
          </div>
          <ul className="sidebar__ul">
            <div className="sidebar__iconExpand__container">
              <p className="sidebar__title">MENU</p>
            </div>

            <Link to="/" style={{ textDecoration: "none" }}>
              <li className="sidebar__li">
                <DashboardIcon className="sidebar__icon" />
                <span className="sidebar__span">Dashboard</span>
              </li>
            </Link>

            {auth.perm.includes("r-paAg") &&
              (auth.role === "Dentista" ||
                auth.role === "Médico/Administrador") && (
                <Link
                  to={`/agendamentos/${auth.userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <li className="sidebar__li">
                    <Inventory2OutlinedIcon className="sidebar__icon" />
                    <span className="sidebar__span">Agendamentos</span>
                  </li>
                </Link>
              )}

            {auth.perm.includes("r-paAg") &&
              auth.role === "Secretária(o)" &&
              auth.usuariosAssociados && (
                <Link
                  to={`/agendamentos/${auth.usuariosAssociados[0].id}`}
                  style={{ textDecoration: "none" }}
                >
                  <li className="sidebar__li">
                    <Inventory2OutlinedIcon className="sidebar__icon" />

                    <span className="sidebar__span">Agendamentos</span>
                  </li>
                </Link>
              )}

            {/* Rota para ver agendamentos de administrador não médico */}
            {auth.perm.includes("r-paAg") &&
              auth.role === "Administrador Não Médico" &&
              //O codigo seguinte apresenta a opcao agendamentos quando os medicos forem selecionados
              //Caso ainda não houverem medicos vindos da base de dados, é apresentado o texto Agendamentos
              //Porem sem funcao alguma. Apenas para evita uma glitch no ecra
              (medico ? (
                <Link
                  to={`/agendamentosviewonly/${medico.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <li className="sidebar__li">
                    <Inventory2OutlinedIcon className="sidebar__icon" />

                    <span className="sidebar__span">Agendamentos</span>
                  </li>
                </Link>
              ) : (
                <li className="sidebar__li">
                  <Inventory2OutlinedIcon className="sidebar__icon" />

                  <span className="sidebar__span">Agendamentos</span>
                </li>
              ))}

            {auth.perm.includes("r-paCli") && (
              <Link to="/clientes" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <PersonOutlineOutlinedIcon className="sidebar__icon" />
                  <span className="sidebar__span">Clientes</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paTra") && (
              <Link to="/tratamentos" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <BorderHorizontalOutlinedIcon className="sidebar__icon" />

                  <span className="sidebar__span">Tratamentos</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paFi") && (
              <Link to="/financeiro" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <AttachMoneyIcon className="sidebar__icon" />

                  <span className="sidebar__span">Financeiro</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paSt") && (
              <Link to="/stock" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <Inventory2Icon className="sidebar__icon" />
                  <span className="sidebar__span">Stock</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paRel") && (
              <Link to="/relatorios" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <TrendingUpIcon className="sidebar__icon" />
                  <span className="sidebar__span">Relatórios</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paFun") && (
              <Link to="/funcionarios" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <GroupIcon className="sidebar__icon" />

                  <span className="sidebar__span">Funcionários</span>
                </li>
              </Link>
            )}

            {(auth.role === "Médico/Administrador" ||
              auth.role === "Administrador Não Médico") && (
              <Link to="/users" style={{ textDecoration: "none" }}>
                <li className="sidebar__li">
                  <GroupIcon className="sidebar__icon" />

                  <span className="sidebar__span">Utilizadores</span>
                </li>
              </Link>
            )}

            {auth.perm.includes("r-paClin") && clinica.clinica && (
              <Link
                to={`/configuracoes/permissoes`}
                style={{ textDecoration: "none" }}
              >
                <li className="sidebar__li">
                  <SettingsIcon className="sidebar__icon" />
                  <span className="sidebar__span">Configurações</span>
                </li>
              </Link>
            )}
            <p className="sidebar__title">UTILIZADOR</p>
            <Link
              to={`/users/${auth.userId}/perfil`}
              style={{ textDecoration: "none" }}
            >
              <li className="sidebar__li">
                <AccountCircleOutlinedIcon className="sidebar__icon" />
                <span className="sidebar__span">Perfil</span>
              </li>
            </Link>
            <li className="sidebar__li" onClick={logout}>
              <LogoutOutlinedIcon className="sidebar__icon" />

              <span className="sidebar__span">Sair</span>
            </li>
          </ul>
        </div>
      )}
    </>
  );
}

export default Sidebar;
