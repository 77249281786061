import { useState, memo } from "react";
import ValorDataCaixa from "./ValorDataCaixa";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./ChequeTab.scss";

import moment from "moment";
import InputNumber from "../../../../../../../shared/components/inputs/InputNumber/InputNumber";
import InputText from "../../../../../../../shared/components/inputs/InputText/InputText";

function ChequeTab({
  valor,
  handleCloseModalReceber,
  receberHandler,
  totalPorReceber,
  caixas,
  parcela,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numero, setNumero] = useState("");
  const [banco, setBanco] = useState("");
  const [dataCheque, setDataCheque] = useState(moment());
  const [nrConta, setNrConta] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dadosValorDataCaixa, setDadosValorDataCaixa] = useState({
    valor: valor,
    data: moment(),
    caixa: caixas[0].id,
    observacao: "",
  });

  const changeValorDataCaixa = (data) => {
    setDadosValorDataCaixa(data);
  };

  const preReceberHandler = (e, emitir) => {
    if (!isSubmitting) {
      //Validation
      let mensagemDeErro = "";
      if (
        !nrConta ||
        !numero ||
        !banco ||
        !dataCheque ||
        !dadosValorDataCaixa.caixa ||
        !dadosValorDataCaixa.valor
      ) {
        mensagemDeErro = "Preencha todos os campos";
        setErrorMessage(mensagemDeErro);
      }

      if (!dataCheque) {
        mensagemDeErro = "Introduza a data do cheque";
        setErrorMessage(mensagemDeErro);
      } else if (!moment.isMoment(dataCheque) || dataCheque.isValid()) {
        mensagemDeErro = "Data do cheque inválida.";
      }

      if (!dadosValorDataCaixa.data) {
        mensagemDeErro = "Introduza a data de recebimento";
        setErrorMessage(mensagemDeErro);
      } else if (
        !moment.isMoment(dadosValorDataCaixa.data) ||
        !dadosValorDataCaixa.data.isValid()
      ) {
        mensagemDeErro = "Introduza a data de recebimento corretamente";
        setErrorMessage(mensagemDeErro);
      }

      if (!mensagemDeErro) {
        setIsSubmitting(true);
        receberHandler(
          {
            quantiaPaga: Number(dadosValorDataCaixa.valor),
            metodoPagamento: "Cheque",
            dataPagamento: dadosValorDataCaixa.data,
            caixa: dadosValorDataCaixa.caixa,
            parcelaPaga: dadosValorDataCaixa.valor === valor,
            quantiaPorPagar: valor - dadosValorDataCaixa.valor,
            observacao: dadosValorDataCaixa.observacao,
            nrCheque: numero,
            banco: banco,
            dataCheque: dataCheque,
            nrContaCliente: nrConta,
          },
          emitir
        );
      }
    }
  };

  const numeroChangeHandler = (value) => {
    setNumero(value);
  };

  const contaClienteChangeHandler = (value) => {
    setNrConta(value);
  };

  const bancoChangeHandler = (value) => {
    setBanco(value);
  };

  return (
    <div className="cheque__tab__container">
      <ValorDataCaixa
        valor={valor}
        changeValorDataCaixa={changeValorDataCaixa}
        caixas={caixas}
        totalPorReceber={totalPorReceber}
        tab="cheque"
      />
      <div className="cheque__tab__container__dados-cheque">
        <h2 className="cheque__tab__container__titulo">Dados do cheque</h2>
        <div className="cheque__tab__container__campos-cheque">
          <div className="cheque__tab__container__campos-cheque--1">
            <InputNumber
              key="numeroCheque"
              id="numeroCheque"
              initialValue={0}
              label="Número Cheque*"
              handleChange={numeroChangeHandler}
            />

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data*"
                inputFormat="DD-MM-YYYY"
                value={dataCheque}
                onChange={(value) => setDataCheque(value)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="cheque__tab__container__campos-cheque--2">
            <InputText
              initialValue={nrConta}
              label="Conta do cliente*"
              handleChange={contaClienteChangeHandler}
              notEmpty={true}
            />
            <InputText
              initialValue={banco}
              label="Banco*"
              handleChange={bancoChangeHandler}
              notEmpty={true}
            />
          </div>
        </div>
      </div>
      {errorMessage && (
        <span className="fluxo-atendimento__modal__erros">{errorMessage}</span>
      )}
      <div className="fluxo-atendimento__modal__bottom_btns">
        <span
          className="cancel-btn"
          onClick={handleCloseModalReceber}
          style={{ display: "block" }}
        >
          Fechar
        </span>

        <span
          className="blue-button"
          disabled={isSubmitting}
          onClick={preReceberHandler}
        >
          {isSubmitting ? "Recebendo..." : "Receber"}
        </span>

        {!parcela.documentoEletronico &&
          (parcela.cancelamentosDocumentosEletronicos?.length === 0 ||
            (parcela.cancelamentosDocumentosEletronicos?.length > 0 &&
              parcela.cancelamentosDocumentosEletronicos[
                parcela.cancelamentosDocumentosEletronicos?.length - 1
              ]?.documentoCancelamento?.succeeded === true)) && (
            <span
              className="blue-button"
              disabled={isSubmitting}
              onClick={preReceberHandler.bind(null, null, "emitirFRE")}
            >
              {isSubmitting ? "Recebendo..." : "Receber e emitir FRE"}
            </span>
          )}
      </div>
    </div>
  );
}

export default memo(ChequeTab);
