import React, { useState, useEffect, memo } from "react";
//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import "./ModalCredenciais.scss";
import { Link } from "react-router-dom";
import moment from "moment";
function ModalCredenciais({ openModal, handleClose, orcamentos }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const tempRows = [];
    orcamentos.forEach((or, index) => {
      tempRows.push({
        id: or.cliente._id + index,
        idCliente: or.cliente._id,
        nome: or.cliente.nome,
        dataExpiracaoCredencial: moment(or.dataExpiracaoCredencial).format(
          "DD-MM-YYYY"
        ),
        nrCredencial: or.nrCredencial,
        total: or.total.toLocaleString("pt-Br") + "$00",
        expirado: moment() > moment(or.dataExpiracaoCredencial).endOf("day"),
        medicoResponsavel: or.medico_responsavel.name,
        diasRestantes: Math.max(
          0,
          moment(or.dataExpiracaoCredencial).endOf("day").diff(moment(), "day")
        ),
      });
    });
    setRows(tempRows);
  }, [orcamentos]);

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <span>
            <Link
              to={`/clientes/${params.row.idCliente}-orcamento`}
              className={`modalProcedimentosPorAgendar__link ${
                params.row.expirado && "modalCredencial__row__expirado"
              }`}
            >
              {params.row.nome}
            </Link>
          </span>
        );
      },
    },
    {
      field: "nrCredencial",
      headerName: "Nr Credencial",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`${
              params.row.expirado && "modalCredencial__row__expirado"
            } marcacoesAdministrativo__nome__medico__span`}
          >
            {params.row.nrCredencial}
          </span>
        );
      },
    },
    {
      field: "diasRestantes",
      headerName: "Dias restantes",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`marcacoesAdministrativo__nome__medico__span ${
              params.row.expirado && "modalCredencial__row__expirado"
            }`}
          >
            {params.row.expirado
              ? "Expirado"
              : params.row.diasRestantes === 1
              ? params.row.diasRestantes + " dia"
              : params.row.diasRestantes + " dias"}
          </span>
        );
      },
    },
    {
      field: "dataExpiracaoCredencial",
      headerName: "Data Expiração",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`marcacoesAdministrativo__nome__medico__span ${
              params.row.expirado && "modalCredencial__row__expirado"
            }`}
          >
            {params.row.dataExpiracaoCredencial}
          </span>
        );
      },
    },
    {
      field: "medicoResponsavel",
      headerName: "Médico",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`marcacoesAdministrativo__nome__medico__span ${
              params.row.expirado && "modalCredencial__row__expirado"
            }`}
          >
            {params.row.medicoResponsavel}
          </span>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            className={`marcacoesAdministrativo__nome__medico__span ${
              params.row.expirado && "modalCredencial__row__expirado"
            }`}
          >
            {params.row.total}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="fluxo-atendimento__modal__box"
          id="modalProcedimentosPorAgendar"
        >
          <h1 className="fluxo-atendimento__modal__titulo">Credenciais</h1>
          <div className="modalProcedimentosPorAgendar__container">
            {rows && (
              <div style={{ height: "80vh", width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                />
              </div>
            )}
          </div>
          <div className="fluxo-atendimento__modal__bottom_btns">
            <span
              className="cancel-btn"
              onClick={handleClose}
              style={{ display: "block" }}
            >
              Fechar
            </span>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default memo(ModalCredenciais);
