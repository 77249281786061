import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../shared/context/auth-context";
import moment from "moment";
import ModalAdicionarEvolucao from "./ModalAdicionarEvolucao/ModalAdicionarEvolucao";
import ModalEditarEvolucao from "./ModalEditarEvolucao/ModalEditarEvolucao";
import ModalEvolucao from "./ModalEvolucao";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ModalInfo from "../../../../../shared/components/UIElements/ModalInfo";
import toast from "react-hot-toast";
import "./Historico.scss";
import { useLoading } from "../../../../../shared/context/LoadingContext";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
  Box,
  Chip,
} from "@mui/material";

function Historico({ clienteId, sendRequest, clienteData }) {
  const { startLoading, stopLoading } = useLoading();

  const [planosTratamento, setPlanosTratamento] = useState();
  const [showModal, setShowModal] = useState(false);
  const [dataModal, setDataModal] = useState();
  const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
  const [openModalEditarEvolucao, setOpenModalEditarEvolucao] = useState(false);
  const [evolucoes, setEvolucoes] = useState();
  const [dadosEvolucaoAEditar, setDadosEvolucaoAEditar] = useState();
  const auth = useContext(AuthContext);

  useEffect(() => {
    setEvolucoes(clienteData.evolucoes);
  }, [clienteData]);

  const [modalProps, setModalProps] = useState({
    show: false,
    icon: "",
    title: "",
    message: "",
    form: false,
  });

  useEffect(() => {
    const fetchPlanosTratamentoByCliente = async () => {
      startLoading();
      try {
        let responseData;
        try {
          responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/planostratamento/historico/${clienteId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.error("err", err);
        }

        const temp = responseData.planosTratamento.sort((a, b) => {
          return new Date(b.data_inicio) - new Date(a.data_inicio);
        });

        temp.forEach((plano) => {
          if (plano.data_inicio) {
            plano.data_inicio = moment(plano.data_inicio).format("DD-MM-YYYY");
          }
          if (plano.data_fim) {
            plano.data_fim = moment(plano.data_fim).format("DD-MM-YYYY");
          }
        });

        //Filtrando por medico conforme o usuario
        //DEPOIS VERIFICAR OUTROS USUARIOS
        let tempPlanosTratamento = [];

        if (auth.role === "Médico/Administrador" || auth.role === "Dentista") {
          tempPlanosTratamento = responseData.planosTratamento.filter(
            (pl) => pl.medico_responsavel.id === auth.userId
          );
        } else if (auth.role === "Secretária(o)") {
          responseData.planosTratamento.forEach((pl) => {
            auth.usuariosAssociados.forEach((medico) => {
              if (medico.id === pl.medico_responsavel.id)
                tempPlanosTratamento.push(pl);
            });
          });
        } else if (auth.role === "Administrador Não Médico") {
          tempPlanosTratamento = [...responseData.planosTratamento];
        }

        setPlanosTratamento(tempPlanosTratamento);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };
    fetchPlanosTratamentoByCliente();
  }, [sendRequest, clienteId, auth]);

  const handleShowEvolucao = (proc, idPlano) => {
    setDataModal({ proc: proc, idPlano: idPlano });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setDataModal();
    setShowModal(false);
  };

  const handleOpenModalAdicionarEvolucao = () => {
    setOpenModalEvolucao(true);
  };

  const handleCloseModalEvolucao = () => {
    setOpenModalEvolucao(false);
    setOpenModalEditarEvolucao(false);
    setDadosEvolucaoAEditar({});
  };

  const handleModalSubmitSuccess = (evolu) => {
    setOpenModalEvolucao(false);
    setEvolucoes((current) => [...current, evolu]);
  };

  const handleModalEditarSuccess = (evolu) => {
    setOpenModalEditarEvolucao(false);

    setEvolucoes((prevItems) =>
      prevItems.map((item) => (item.id === evolu.id ? { ...evolu } : item))
    );
  };

  const handleModalCancel = () => {
    setModalProps((currentModalProps) => {
      return { ...currentModalProps, show: false };
    });
  };

  const handleApagarEvolucao = async (idEvolucao) => {
    setModalProps({
      show: true,
      icon: "info",
      title: `Apagar Evolução!`,
      message: `Tem a certeza que pretende apagar a evolução?`,
      form: true,
      onCancel: handleModalCancel,
      onConfirm: apagarEvolucao.bind(null, idEvolucao),
    });
  };

  const apagarEvolucao = async (idEvolucao) => {
    startLoading();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_LINK}/api/clientes/apagarevolucao/${idEvolucao}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      toast.success("Evolução eliminada com sucesso");
      setEvolucoes((current) => current.filter((c) => c.id !== idEvolucao));
    } catch (err) {
      console.error("err", err);
      console.error(err);
    } finally {
      stopLoading();
      handleModalCancel();
    }
  };

  const handleOpenModalEditar = async (idEvolucao) => {
    setDadosEvolucaoAEditar(evolucoes.find((ev) => ev.id === idEvolucao));
    setOpenModalEditarEvolucao(true);
  };

  const updatePlanosTratamento = (newPlano) => {
    setPlanosTratamento((current) =>
      current.map((p) => (p._id === newPlano._id ? newPlano : p))
    );
  };
  return (
    <>
      <ModalInfo {...modalProps} />
      <ModalAdicionarEvolucao
        handleCloseModalEvolucao={handleCloseModalEvolucao}
        handleModalSubmitSuccess={handleModalSubmitSuccess}
        open={openModalEvolucao}
        cliente={{ nome: clienteData.nome, id: clienteData.id }}
        sendRequest={sendRequest}
        auth={auth}
      />
      {dadosEvolucaoAEditar && (
        <ModalEditarEvolucao
          handleCloseModalEvolucao={handleCloseModalEvolucao}
          handleModalEditarSuccess={handleModalEditarSuccess}
          open={openModalEditarEvolucao}
          cliente={{ nome: clienteData.nome, id: clienteData.id }}
          sendRequest={sendRequest}
          auth={auth}
          dadosEvolucao={dadosEvolucaoAEditar}
        />
      )}

      {planosTratamento && planosTratamento.length > 0 && (
        <div className="historicoContainer caixa">
          {planosTratamento.map((plano) => (
            <Accordion
              key={plano._id}
              defaultExpanded={
                !plano.contaLiquidada &&
                plano.total !== 0 &&
                plano.status !== "INTERROMPIDO"
              }
              sx={{
                mb: 2,
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: "2rem",
                      "&:hover": {
                        transform: "scale(1.2)",
                        color: "primary.main",
                        filter: "brightness(1.2)",
                        transition: "all 0.2s ease-in-out",
                      },
                    }}
                  />
                }
                sx={{
                  height: "55px",
                  backgroundColor:
                    plano.status === "INTERROMPIDO" ? "#fff8f8" : "#fdfdfd",
                  borderLeft:
                    plano.status === "INTERROMPIDO"
                      ? "4px solid #eb3c5f"
                      : "4px solid #4caf50",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {(plano.data_inicio || plano.data_fim) && (
                      <span className="debitos__accordion__title">
                        Plano de Tratamento {plano.numero} -{" "}
                        {plano.data_inicio ? plano.data_inicio : "?"} -{" "}
                        {plano.data_fim ? plano.data_fim : "?"}
                      </span>
                    )}
                    {plano.status === "INTERROMPIDO" && (
                      <>
                        <span className="debitos__accordion__title">
                          Plano de Tratamento {plano.numero} -
                        </span>
                        <Chip
                          label="Tratamento Interrompido"
                          size="small"
                          sx={{
                            color: "#eb3c5f",
                            backgroundColor: "rgba(241, 167, 182, 0.47)",
                          }}
                        />
                      </>
                    )}

                    {plano.canDelete && (
                      <>
                        <span className="debitos__accordion__title">
                          Plano de Tratamento {plano.numero} -
                        </span>
                        <Chip
                          label="Por iniciar"
                          size="small"
                          sx={{
                            color: "#fdcb7f",
                            backgroundColor: "#fff7eb",
                          }}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                {plano.status === "INTERROMPIDO" &&
                  plano.motivoEncerramento && (
                    <Alert
                      severity="info"
                      sx={{ mb: 2 }}
                      icon={
                        plano.motivoEncerramento?.descricao ? (
                          <Tooltip title={plano.motivoEncerramento?.descricao}>
                            <InfoIcon sx={{ cursor: "help" }} />
                          </Tooltip>
                        ) : (
                          <InfoIcon />
                        )
                      }
                    >
                      <Typography variant="body2">
                        <strong>Motivo da Interrupção:</strong>{" "}
                        {plano.motivoEncerramento?.tipo}
                        <br />
                        <div style={{ marginTop: "7px" }}>
                          <strong>Data:</strong>{" "}
                          {moment(plano.motivoEncerramento?.data).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          <strong style={{ marginLeft: "15px" }}>
                            Responsável:{" "}
                          </strong>
                          {plano.motivoEncerramento?.responsavel?.name}
                        </div>
                      </Typography>
                    </Alert>
                  )}

                <div className="historico__colapse-container">
                  <div className="historico__colapse-container__row--header">
                    <span>Data Inicio</span>
                    <span>Procedimento</span>
                    <span>Sessões</span>
                    <span>Dente</span>
                    <span>Faces</span>
                    <span>Status</span>
                  </div>

                  {plano.procedimentos.map(
                    (proc, idx) =>
                      proc.sessoes.length > 0 && (
                        <div
                          className="historico__colapse-container__row caixa"
                          key={idx}
                        >
                          <div className="historico__colapse-container__row__item">
                            <span>
                              {proc.data_inicio_execucao
                                ? moment(proc.data_inicio_execucao).format(
                                    "DD-MM-YYYY"
                                  )
                                : moment(
                                    proc.sessoes[0].data_inicio_execucao
                                  ).format("DD-MM-YYYY")}
                            </span>
                          </div>
                          <div className="historico__colapse-container__row__item">
                            <span
                              className="historico__colapse-container__row__item--tratamento"
                              onClick={() =>
                                handleShowEvolucao(proc, plano._id)
                              }
                            >
                              {proc.tratamento.designacao}
                            </span>
                            <span>
                              {proc.medico.genero === "feminino"
                                ? "Dra. "
                                : "Dr. "}
                              {proc.medico.name}
                            </span>
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.sessoes ? proc.sessoes.length : 1}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.dente}
                          </div>
                          <div className="historico__colapse-container__row__item">
                            {proc.faces.join(", ")}
                          </div>
                          <div
                            className="historico__colapse-container__row__item"
                            style={{}}
                          >
                            <span
                              className={`historicoStatus ${
                                proc.procedimento_completo
                                  ? "historicoConcluido"
                                  : proc.sessoes.length > 0
                                  ? "historicoTransformado"
                                  : ""
                              }`}
                            >
                              {proc.procedimento_completo
                                ? "Finalizado"
                                : proc.sessoes.length > 0
                                ? "Em Andamento"
                                : ""}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}

      <div className="adicionarEvolucaoButtonContainer">
        <span
          className="blue-button"
          onClick={handleOpenModalAdicionarEvolucao}
        >
          Adicionar Evolução
        </span>
      </div>

      {evolucoes && (
        <div className="evolucaoClienteSolo__container">
          {evolucoes.map((ev, index) => (
            <div className="caixa evolucaoItemContainer" key={index}>
              <div className="evolucaoClienteSolo">
                <span className="evolucao-descricao">{ev.evolucao}</span>
                <div>
                  <span className="evolucao-datas">
                    {moment(ev.data_inicio_execucao).format("DD/MM/YYYY HH:mm")}
                  </span>{" "}
                  {"  -  "}
                  <span className="evolucao-datas">
                    {moment(ev.data_fim_execucao).format("HH:mm")} -{" "}
                    {ev.medico ? ev.medico.name : ""}
                  </span>
                </div>
              </div>
              <div className="evolucao-btns">
                {ev.medico?._id === auth.userId && (
                  <>
                    <Tooltip title="Apagar">
                      <IconButton
                        onClick={handleApagarEvolucao.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <DeleteIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        onClick={handleOpenModalEditar.bind(null, ev._id)}
                        className="modalEvolucao__container__btns__icon-btn"
                      >
                        <EditIcon className="evolucao-btns__btn" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {showModal && dataModal && (
        <ModalEvolucao
          data={dataModal}
          openModalEvolucao={showModal}
          handleCloseModal={handleCloseModal}
          auth={auth}
          sendRequest={sendRequest}
          updatePlanosTratamento={updatePlanosTratamento}
        />
      )}
    </>
  );
}

export default Historico;
