import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useHttpClient } from "../../../../shared/hooks/http-hook";
import { useLoading } from "../../../../shared/context/LoadingContext";
import toast from "react-hot-toast";
import moment from "moment";

import { localeText } from "../../../../shared/util/datatableTranslations";

import { DataGrid } from "@mui/x-data-grid";
import FiltrosOrcamento from "./filtrosOrcamentos/FiltrosOrcamento";
const PAGESIZE = 15;

const columns = [
  {
    field: "nome",
    headerName: "Cliente",
    flex: 5,
  },
  {
    field: "beneficio",
    headerName: "Beneficio",
    flex: 2,
  },
  {
    field: "data",
    headerName: "Data",
    flex: 2,
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    flex: 1,
  },
  {
    field: "total",
    headerName: "Total",
    flex: 1,
  },
  {
    field: "porPagar",
    headerName: "PorPagar",
    flex: 1,
  },
  {
    field: "desconto",
    headerName: "Desconto",
    flex: 1,
  },
];

function RelatoriosOrcamentos({ auth, clinicaId }) {
  const { sendRequest } = useHttpClient();
  const { startLoading, stopLoading } = useLoading();
  const [orcamentosAbsolut, setOrcamentosAbsolut] = useState();
  const [orcamentos, setOrcamentos] = useState();
  const [orcamentosParaTextSearch, setOrcamentosParaTextSearch] = useState([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState([]);
  const [filtroTextoSearch, setFiltroTextoSearch] = useState("");

  useEffect(() => {
    fetchDataByRange(
      moment().subtract(1, "months").startOf("day"),
      moment().endOf("day")
    );
  }, []);

  const fetchDataByRange = async (dataInicial, dataFinal) => {
    if (
      moment(dataInicial)
        .startOf("day")
        .isBefore(moment(dataFinal.endOf("day")))
    ) {
      const formData = new FormData();
      formData.append(
        "dataInicial",
        moment(dataInicial).startOf("day").toISOString()
      );
      formData.append(
        "dataFinal",
        moment(dataFinal).endOf("day").toISOString()
      );

      try {
        startLoading();
        const [orcamentosResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/contascorrentes/relatorios/orcamento/${clinicaId}`,
            "PATCH",
            formData,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        const tempOrcamentos = [];
        try {
          orcamentosResponse.contas.forEach((c, index) => {
            const totalPago = c.parcelas.reduce(
              (sum, item) => sum + Number(item.quantiaPaga),
              0
            );
            tempOrcamentos.push({
              id: c.orcamento._id,
              _id: c.orcamento._id,
              nome: c.clienteNome,
              idCliente: c.clienteId,
              data: moment(c.orcamento.created_at).format(
                "DD-MM-YYYY hh:mm:ss"
              ),
              subtotal: c.orcamento.subtotal.toLocaleString("pt-Br") + "$00",
              total: c.orcamento.total.toLocaleString("pt-Br") + "$00",
              desconto: c.orcamento.desconto.toLocaleString("pt-Br") + "$00",
              transformado: c.orcamento.transformado,
              terminado: c.orcamento.terminado,
              porPagar: (c.total - totalPago).toLocaleString("pt-Br") + "$00",
              beneficio: c.orcamento.beneficio,
            });
          });
        } catch (err) {
          console.error("err", err);
        }

        setOrcamentos(tempOrcamentos);
        setOrcamentosAbsolut(tempOrcamentos);
        setOrcamentosParaTextSearch(tempOrcamentos);

        handleFiltrar(tempOrcamentos, [], "");
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Ações",
      flex: 2,
      renderCell: (params) => {
        return (
          <span className="listaDivida__tableContent">
            <Link
              to={`/clientes/${params.row.idCliente}-orcamento`}
              className="listaDivida__clientAnchor"
            >
              <button className="secretaria__dashboard__modalAcoesBtn">
                Ver Orçamento
              </button>
            </Link>
          </span>
        );
      },
    },
  ];

  const handleFiltrar = (newOrcamentos, filtros, textoSearch) => {
    if (newOrcamentos) {
      setOrcamentos([...newOrcamentos]);
    }
    setFiltroTextoSearch(textoSearch);
    setFiltrosAplicados(filtros);
  };

  return (
    <>
      <FiltrosOrcamento
        fetchDataByRange={fetchDataByRange}
        handleFiltrar={handleFiltrar}
        filtrosAplicados={filtrosAplicados}
        orcamentosParaTextSearch={orcamentosParaTextSearch}
        orcamentosAbsolut={orcamentosAbsolut}
        filtroTextoSearch={filtroTextoSearch}
      />

      <div className="orcamentos-por-aprovar__modal__container">
        {orcamentos && (
          <DataGrid
            autoWidth
            className="datagrid"
            rows={orcamentos}
            columns={columns.concat(actionColumn)}
            pageSize={PAGESIZE}
            rowsPerPage={PAGESIZE}
            localeText={localeText}
          />
        )}
      </div>
    </>
  );
}

export default RelatoriosOrcamentos;
