import React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./ModalVerPlano.scss";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import DoneIcon from "@mui/icons-material/Done";
import SettingsIcon from "@mui/icons-material/Settings";

function ModalVerPlano({ plano, openModal, handleCloseModal }) {
  const [planoTratamento, setPlanoTratamento] = useState();

  useEffect(() => {
    const sortedProcedimentos = [...plano.procedimentos].sort((a, b) => {
      if (a.procedimento_completo && !b.procedimento_completo) {
        return 1;
      }
      if (!a.procedimento_completo && b.procedimento_completo) {
        return -1;
      }
      return 0;
    });
    const planoFinal = { ...plano, procedimentos: sortedProcedimentos };
    setPlanoTratamento(planoFinal);
  }, [plano]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* Verificar se nos dados da modal conter avaliacao. Se sim, estamos lidando com dados do orcamento */}
      <Box
        className="fluxo-atendimento__modal__box"
        id="modal-executar-procedimentos"
      >
        <div className="verPlano caixa">
          <span className="verPlano__tabela-heading verPlano__tabela-heading--1st">
            Tratamento
          </span>
          <span className="verPlano__tabela-heading">Dentes</span>
          <span className="verPlano__tabela-heading">Faces</span>
          <span className="verPlano__tabela-heading">Sessões</span>
          <span className="verPlano__tabela-heading">Status</span>
          {planoTratamento &&
            planoTratamento.procedimentos.map(
              (p, index) =>
                !p.procedimento_completo && (
                  <React.Fragment key={index}>
                    <span className="verPlano__lineItem verPlano__lineItem--1st">
                      {p.tratamento.designacao}
                    </span>
                    <span className="verPlano__lineItem">{p.dente}</span>
                    <span className="verPlano__lineItem">
                      {p.faces.join(" ,")}
                    </span>

                    <span className="verPlano__lineItem">
                      {p.sessoes.length}
                    </span>
                    <div className="verPlano__procedimento__container verPlano__lineItem">
                      <span
                        className={`
                        verPlano__procedimento
                        ${
                          p.sessoes.length > 0
                            ? "verPlano__procedimento-emProgresso"
                            : "verPlano__procedimento-novo"
                        }
                      `}
                      >
                        {p.sessoes.length > 0 ? (
                          <>
                            <SettingsIcon className="verPlano__procedimento__icon" />{" "}
                            Em Progresso
                          </>
                        ) : (
                          <>
                            <StarBorderIcon className="verPlano__procedimento__icon" />{" "}
                            Novo
                          </>
                        )}
                      </span>
                    </div>
                  </React.Fragment>
                )
            )}
          <div className="linhaCinzenta">Finalizados</div>
          {planoTratamento &&
            planoTratamento.procedimentos.map(
              (p, index) =>
                p.procedimento_completo && (
                  <React.Fragment key={index}>
                    <span className="verPlano__lineItem verPlano__lineItem--1st">
                      {p.tratamento.designacao}
                    </span>
                    <span className="verPlano__lineItem">{p.dente}</span>
                    <span className="verPlano__lineItem">
                      {p.faces.join(" ,")}
                    </span>

                    <span className="verPlano__lineItem">
                      {p.sessoes.length}
                    </span>
                    <div className="verPlano__procedimento__container verPlano__lineItem">
                      <span
                        className="
                        verPlano__procedimento verPlano__procedimento-completo"
                      >
                        <DoneIcon className="verPlano__procedimento__icon" />{" "}
                        Completo
                      </span>
                    </div>
                  </React.Fragment>
                )
            )}
          <div className="botoes">
            <button className="cancel-btn" onClick={handleCloseModal}>
              Fechar
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ModalVerPlano;
