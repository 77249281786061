import { useState, useRef, useEffect, useCallback } from "react";

import moment from "moment";
import "./FiltrosReceitas.scss";

//MUI
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArticleIcon from "@mui/icons-material/Article";
import SearchIcon from "@mui/icons-material/Search";
///////////
import ModalFiltrosReceitas from "./ModalFiltrosReceitas";

function FiltrosReceitas({
  contasCorrentesAbsolut,
  entradasAbsolut,
  contasCorrentesParaTextSearch,
  entradasParaTextSearch,
  handleFiltrar,
  handleExportar,
  filtrosAplicados,
  filtroCaixa,
  filtroCategoria,
  filtroMetodoPagamento,
  clinicaId,
  auth,
  fetchContasCorrentesEntrada,
  filtroTextoSearch,
}) {
  const [mostrarDatas, setMostrarDatas] = useState(false);
  const [dataInicial, setDataInicial] = useState(moment().startOf("day"));
  const [dataFinal, setDataFinal] = useState(moment().endOf("day"));
  const [openModalFiltros, setOpenModalFiltros] = useState(false);
  const [textSearchQuery, setTextSearchQuery] = useState("");
  const periodoRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    if (filtroTextoSearch === "") {
      setTextSearchQuery("");
    }
  }, [filtroTextoSearch]);

  const fetchByPeriodo = useCallback(() => {
    const periodo = periodoRef.current.value;
    let startDate;
    let endDate;

    if (periodo === "hoje") {
      startDate = moment();
      endDate = moment();
    }
    if (periodo === "ontem") {
      startDate = moment().subtract(1, "days").startOf("day");
      endDate = moment().subtract(1, "days").endOf("day");
    }
    if (periodo === "semana") {
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
    }
    if (periodo === "mes") {
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
    }
    if (periodo === "-mes") {
      startDate = moment().subtract(1, "months").startOf("month");
      endDate = moment().subtract(1, "months").endOf("month");
    }
    if (periodo === "-30d") {
      startDate = moment().startOf("day").subtract(30, "days");
      endDate = moment().endOf("day");
    }
    if (periodo === "+30d") {
      startDate = moment().startOf("day");
      endDate = moment().endOf("day").add(30, "days");
    }

    fetchContasCorrentesEntrada(startDate, endDate);
  }, [fetchContasCorrentesEntrada]);

  const escolherPeriodoHandler = useCallback(
    (e) => {
      if (e.target.value === "periodo" && !mostrarDatas) {
        setMostrarDatas(true);
      } else if (e.target.value !== "periodo") {
        if (mostrarDatas) {
          setMostrarDatas(false);
        }
        fetchByPeriodo();
      }
    },
    [mostrarDatas, fetchByPeriodo]
  );

  const handleCloseModalFiltros = useCallback(() => {
    setOpenModalFiltros(false);
  }, []);

  const changeDatas = useCallback(
    (identificacao, valor) => {
      if (identificacao === "dataInicial") {
        setDataInicial(valor);
        if (valor <= dataFinal) {
          fetchContasCorrentesEntrada(valor, dataFinal);
        }
      } else {
        setDataFinal(valor);
        if (valor >= dataInicial) {
          fetchContasCorrentesEntrada(dataInicial, valor);
        }
      }
    },
    [dataInicial, dataFinal, fetchContasCorrentesEntrada]
  );

  const applyTextSearchContasCorrentes = (query) => {
    const lowerCaseText = query.toLowerCase();
    const filteredContasCorrentes = [];

    if (query) {
      contasCorrentesParaTextSearch.forEach((cc, index) => {
        //Se a procura for por cliente, devolvemos toda conta do cliente
        if (cc.cliente.nome.toLowerCase().includes(lowerCaseText)) {
          filteredContasCorrentes.push({ ...cc });
        } else {
          //Se a procura não for por cliente, procuramos os dados das parcelas
          let novasContas = [];
          let novaContaCorrente = { ...cc };
          cc.contas.forEach((conta) => {
            let novaConta = { ...conta };
            let novasParcelas = [];
            conta.parcelas.forEach((parcela) => {
              if (
                moment(parcela.dataLimitePagamento)
                  .format("DD-MM-YYYY")
                  .includes(lowerCaseText) ||
                parcela.quantiaPorPagar.toString() === lowerCaseText ||
                parcela.quantiaPaga.toString() === lowerCaseText ||
                (parcela.dataPagamento &&
                  moment(parcela.dataPagamento)
                    .format("DD-MM-YYYY")
                    .includes(lowerCaseText))
              )
                novasParcelas.push({ ...parcela });
            });
            novaConta.parcelas = [...novasParcelas];
          });
          novaContaCorrente.contas = [...novasContas];
          filteredContasCorrentes.push(novaContaCorrente);
        }
      });
      return filteredContasCorrentes;
    } else {
      return contasCorrentesParaTextSearch;
    }
  };

  const applyTextSearchEntradas = (query) => {
    if (query) {
      const lowerCaseText = query.toLowerCase();
      const filteredEntradas = [];

      entradasParaTextSearch.forEach((entrada) => {
        if (
          entrada.categoria.categoria.toLowerCase().includes(lowerCaseText) ||
          entrada.caixa.caixa.toLowerCase().includes(lowerCaseText) ||
          entrada.descricao?.toLowerCase().includes(lowerCaseText) ||
          entrada.metodoPagamento?.toLowerCase().includes(lowerCaseText) ||
          entrada.cliente?.nome?.toLowerCase().includes(lowerCaseText) ||
          entrada.clienteFornecedor?.nome
            ?.toLowerCase()
            .includes(lowerCaseText) ||
          moment(entrada?.dataPagamento)
            .format("DD-MM-YYYY")
            .includes(lowerCaseText) ||
          entrada.valor.toString() === lowerCaseText
        )
          filteredEntradas.push({ ...entrada });
      });
      return filteredEntradas;
    } else {
      return entradasParaTextSearch;
    }
  };

  const handleTextSearch = (query) => {
    setTextSearchQuery(query);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      const filteredContasCorrentes = applyTextSearchContasCorrentes(query);
      const filteredEntradas = applyTextSearchEntradas(query);
      handleFiltrar(
        filteredContasCorrentes,
        filteredEntradas,
        [],
        null,
        null,
        null,
        query,
        "textSearch"
      );
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleModalFiltros = (
    filteredContasCorrentes,
    filteredEntradas,
    appliedFilters,
    caixa,
    categoria,
    metodoPagamento
  ) => {
    // Reset text search when modal filters are applied
    setTextSearchQuery("");
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    handleFiltrar(
      filteredContasCorrentes,
      filteredEntradas,
      appliedFilters,
      caixa,
      categoria,
      metodoPagamento,
      "",
      "modalFiltros"
    );
  };

  return (
    <div className="financeiro__filtros">
      <span className="financeiro__filtros__texto">Exibindo financeiro</span>
      <select name="" id="" onChange={escolherPeriodoHandler} ref={periodoRef}>
        <option value="hoje">de hoje</option>
        <option value="ontem">de ontem</option>
        <option value="semana">dessa semana</option>
        <option value="mes">desse mês</option>
        <option value="-mes">do mês passado</option>
        <option value="-30d">dos últimos 30 dias</option>
        <option value="+30d">dos próximos 30 dias</option>
        <option value="periodo">escolher período</option>
      </select>

      {mostrarDatas && dataInicial && dataFinal && (
        <div className="financeiro__filtros__periodoContainer">
          <div className="financeiro__filtros__periodoContainer__dataInicioContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Inicial*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("dataInicial", value)}
                value={dataInicial}
              />
            </LocalizationProvider>
          </div>
          <div className="financeiro__filtros__periodoContainer__dataFimContainer financeiro__filtros__periodoContainer__dataContainer">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              className="adicionar-despesa__modal__container--span1"
            >
              <DesktopDatePicker
                className="valorDataCaixa__fields--field muiDatePicker"
                label="Data Final*"
                inputFormat="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => changeDatas("dataFinal", value)}
                value={dataFinal}
              />
            </LocalizationProvider>
          </div>
        </div>
      )}

      <div className="textBoxSearch__container">
        <input
          type="text"
          placeholder="Procurar"
          className="textBoxSearch__input"
          value={textSearchQuery}
          onChange={(e) => handleTextSearch(e.target.value)}
        />
        <SearchIcon className="financeiro__filtros__search-icon" />
      </div>

      <div
        className={`financeiro__filtros__filtrar ${
          (filtrosAplicados && filtrosAplicados.length > 0) ||
          (filtroCaixa && filtroCaixa !== "todas") ||
          (filtroCategoria && filtroCategoria !== "todas") ||
          (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
            ? "financeiro__filtros__filtrar--ativo"
            : ""
        }`}
        onClick={(e) => setOpenModalFiltros(true)}
      >
        <FilterAltIcon
          className={
            (filtrosAplicados && filtrosAplicados.length > 0) ||
            (filtroCaixa && filtroCaixa !== "todas") ||
            (filtroCategoria && filtroCategoria !== "todas") ||
            (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
              ? "filtro__ativo financeiro__filtros__icon"
              : "financeiro__filtros__icon"
          }
        />
        <span
          className={
            (filtrosAplicados && filtrosAplicados.length > 0) ||
            (filtroCaixa && filtroCaixa !== "todas") ||
            (filtroCategoria && filtroCategoria !== "todas") ||
            (filtroMetodoPagamento && filtroMetodoPagamento !== "todas")
              ? "filtro__ativo financeiro__filtros__icon-text"
              : "financeiro__filtros__icon-text"
          }
        >
          Filtrar
        </span>
      </div>

      <div className="financeiro__filtros__filtrar" onClick={handleExportar}>
        <ArticleIcon className="financeiro__filtros__icon" />
        <span className="financeiro__filtros__icon-text">Exportar Excel</span>
      </div>

      {openModalFiltros && (
        <ModalFiltrosReceitas
          openModalFiltros={openModalFiltros}
          handleCloseModalFiltros={handleCloseModalFiltros}
          handleFiltrar={handleModalFiltros}
          contasCorrentes={contasCorrentesAbsolut}
          entradas={entradasAbsolut}
          filtrosAplicados={filtrosAplicados ? filtrosAplicados : []}
          filtroCaixa={filtroCaixa}
          filtroCategoria={filtroCategoria}
          filtroMetodoPagamento={filtroMetodoPagamento}
          clinicaId={clinicaId}
          auth={auth}
        />
      )}
    </div>
  );
}

export default FiltrosReceitas;
