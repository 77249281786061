import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import { tratamentoSchema } from "../../../shared/util/schemas";
import Sidebar from "../../../shared/components/sidebar/Sidebar";
import Navbar from "../../../shared/components/navbar/Navbar";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";
import { ClinicaContext } from "../../../shared/context/clinica-context";

import toast from "react-hot-toast";

//Mui icons
import VaccinesIcon from "@mui/icons-material/Vaccines";

import "../new/New.scss";
import "../../../style/formError.scss";

//Mui Elementss
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import { useLoading } from "../../../shared/context/LoadingContext";

const DENTES = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "51",
  "52",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "71",
  "72",
  "73",
  "74",
  "75",
  "81",
  "82",
  "83",
  "84",
  "85",
  "Arcada Superior",
  "Arcada Inferior",
  "Arcadas",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#0c53fb",
    },
  },
};

function EditTratamento() {
  const { startLoading, stopLoading } = useLoading();

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const clinica = useContext(ClinicaContext);
  const tratamentoId = useParams().tratamentoId;

  const [formikInitialValues, setFormikInitialValues] = useState(null);

  const navigate = useNavigate();
  const [aceitaFaces, setAceitaFaces] = useState(false);
  const [multiDentes, setMultiDentes] = useState(false);
  const [aceitaDentes, setAceitaDentes] = useState(true);
  const [coberturaInps, setCoberturaInps] = useState(true);
  const [qtdDentes, setQtdDentes] = useState(0);
  const [qtdFaces, setQtdFaces] = useState(0);
  const [categoriasTratamento, setCategoriasTratamento] = useState([]);
  const [categoriaTratamento, setCategoriaTratamento] = useState();
  const [mensagemErro, setMensagemErro] = useState("");
  const [dentesEspecificos, setDentesEspecificos] = useState([]);

  const multiDentesChangeHandler = (valor) => {
    setMultiDentes(valor);
    if (!valor) {
      setQtdDentes(0);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const [categoriasResponse, tratamentoResponse] = await Promise.all([
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/categoriastratamento/clinica/${clinica.clinica._id}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
          sendRequest(
            `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/${tratamentoId}`,
            "GET",
            null,
            { Authorization: "Bearer " + auth.token }
          ),
        ]);

        // Process categorias
        setCategoriasTratamento(categoriasResponse.categoriasTratamento);

        // Process tratamento
        const { tratamento } = tratamentoResponse;
        processTratamentoData(tratamento);
      } catch (err) {
        console.error("err", err);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [auth.token, sendRequest, tratamentoId, clinica.clinica._id]);

  // Helper function
  const processTratamentoData = (tratamento) => {
    setCategoriaTratamento(tratamento.categoria);
    setAceitaFaces(tratamento.aceitaFaces);
    setMultiDentes(tratamento.multiDentes);

    if (tratamento.dentesEspecificos) {
      setDentesEspecificos(tratamento.dentesEspecificos.split(", "));
    }

    setAceitaDentes(tratamento.aceitaDentes);
    setCoberturaInps(tratamento.coberturaInps);
    setQtdDentes(tratamento.qtdDentes);
    setQtdFaces(tratamento.qtdFaces);

    setFormikInitialValues({
      codigo: tratamento.codigo,
      designacao: tratamento.designacao,
      precoParticular: tratamento.preco_particular,
      precoBeneficiario: tratamento.preco_beneficiario,
      precoSegurado: tratamento.preco_segurado,
      comparticipacaoInps: tratamento.comparticipacao_inps,
    });
  };

  const handleCancel = () => {
    resetForm();
    navigate("/tratamentos");
  };

  const onSubmit = async (values, actions) => {
    startLoading();
    try {
      let erro = false;
      if (multiDentes && qtdDentes <= 0) {
        setMensagemErro(
          "Tratamentos multiDentes requerem um número de dentes > 0"
        );
        erro = true;
      } else if (qtdFaces <= 0) {
        setMensagemErro("Quantidade de faces deve ser > 0");
        erro = true;
      } else {
        setMensagemErro("");
      }
      if (!erro) {
        const formData = new FormData();
        formData.append("codigo", values.codigo);
        formData.append("designacao", values.designacao);
        formData.append("categoria", categoriaTratamento);
        formData.append("preco_particular", values.precoParticular);
        formData.append("preco_beneficiario", values.precoBeneficiario);
        formData.append("preco_segurado", values.precoSegurado);
        formData.append("comparticipacao_inps", values.comparticipacaoInps);
        formData.append("aceitaFaces", aceitaFaces);
        formData.append("aceitaDentes", aceitaDentes);
        formData.append("coberturaInps", coberturaInps);
        formData.append("multiDentes", multiDentes);
        formData.append("qtdDentes", qtdDentes);
        formData.append("atualizadoPor", auth.userId);
        if (qtdFaces || qtdFaces > 0) formData.append("qtdFaces", qtdFaces);
        formData.append(
          "dentesEspecificos",
          dentesEspecificos.length > 0 ? dentesEspecificos.join(", ") : ""
        );

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_LINK}/api/tratamentos/${tratamentoId}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        actions.resetForm();
        toast.success("Tratamento atualizado com sucesso!");
        stopLoading();
        navigate("../../");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      stopLoading();
    }
  };

  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: formikInitialValues || {
      codigo: "",
      designacao: "",
      precoParticular: "",
      precoBeneficiario: "",
      precoSegurado: "",
      comparticipacaoInps: "",
    },
    enableReinitialize: true,
    validationSchema: tratamentoSchema,
    onSubmit,
  });

  const dentesEspecificosChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setDentesEspecificos(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <div className="new">
        <Sidebar />
        <div className="newContainer">
          <Navbar
            title="Editar Tratamento"
            icon={VaccinesIcon}
            paths={[{ nome: "Tratamentos", link: `../..` }, { nome: "Editar" }]}
          />
          <div className="bottom caixa">
            <div className="bottom-left">
              <img src="/images/tratamentos.jpg" alt="" />
            </div>
            <div className="bottom-right">
              <div className="form-input takes1-2">
                <input
                  id="codigo"
                  name="codigo"
                  type="text"
                  placeholder="aa-001"
                  value={values.codigo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.codigo && touched.codigo
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="codigo">
                  Codigo
                </label>
                {errors.codigo && touched.codigo ? (
                  <p className="error-message">{errors.codigo}</p>
                ) : null}
              </div>

              <div className="form-input takes2-3">
                <input
                  id="designacao"
                  name="designacao"
                  type="text"
                  placeholder="Remoção"
                  value={values.designacao}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.designacao && touched.designacao
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="designacao">
                  Designação*
                </label>
                {errors.designacao && touched.designacao ? (
                  <p className="error-message">{errors.designacao}</p>
                ) : null}
              </div>

              <div className="form-input takes1-2">
                {categoriaTratamento && (
                  <select
                    id="categoria"
                    name="categoria"
                    value={categoriaTratamento}
                    onChange={(e) => setCategoriaTratamento(e.target.value)}
                    className="input"
                  >
                    {categoriasTratamento &&
                      categoriasTratamento.map((cat, index) => (
                        <option value={cat.id} key={index}>
                          {cat.categoria}
                        </option>
                      ))}
                  </select>
                )}
              </div>

              {/* preco segurado */}
              <div className="form-input takes2-3">
                <input
                  id="precoSegurado"
                  name="precoSegurado"
                  type="number"
                  placeholder="Preço Segurado"
                  value={values.precoSegurado}
                  onChange={handleChange}
                  className={
                    errors.precoSegurado && touched.precoSegurado
                      ? "input-error input"
                      : "input"
                  }
                />
                <label className="label" htmlFor="precoSegurado">
                  Preço Segurado*
                </label>
                {errors.precoSegurado && touched.precoSegurado ? (
                  <p className="error-message">{errors.precoSegurado}</p>
                ) : null}
              </div>

              {/* comparticipacao Inps */}
              <div className="form-input takes1-2">
                <input
                  id="comparticipacaoInps"
                  name="comparticipacaoInps"
                  min={0}
                  max={100}
                  type="number"
                  placeholder="Comparticipação INPS"
                  value={values.comparticipacaoInps}
                  onChange={handleChange}
                  className="input"
                />
                <label className="label" htmlFor="comparticipacaoInps">
                  Comparticipação INPS*
                </label>
              </div>

              {/* preco beneficiario */}
              <div className="form-input takes2-3">
                <input
                  id="precoBeneficiario"
                  name="precoBeneficiario"
                  type="number"
                  placeholder="Preço Beneficiario"
                  value={values.precoBeneficiario}
                  onChange={handleChange}
                  className="input"
                />
                <label className="label" htmlFor="precoBeneficiario">
                  Preço Beneficiario*
                </label>
              </div>

              {/* preco particular */}
              <div className="form-input takes1-2">
                <input
                  id="precoParticular"
                  name="precoParticular"
                  type="number"
                  placeholder="Preço Particular"
                  value={values.precoParticular}
                  onChange={handleChange}
                  className="input"
                />
                <label className="label" htmlFor="precoParticular">
                  Preço Particular*
                </label>
              </div>

              {/* qtd dentes */}
              {multiDentes && (
                <div className="form-input takes2-3">
                  <input
                    id="qtdDentes"
                    name="qtdDentes"
                    type="number"
                    placeholder="Qtd Dentes"
                    value={qtdDentes}
                    onChange={(e) => setQtdDentes(e.target.value)}
                  />
                  <label className="label" htmlFor="qtdDentes">
                    Qtd Dentes*
                  </label>
                </div>
              )}

              {/* qtd faces */}
              <div className="form-input span2">
                <input
                  id="qtdFaces"
                  name="qtdFaces"
                  type="number"
                  placeholder="Qtd Faces"
                  value={qtdFaces}
                  onChange={(e) => setQtdFaces(e.target.value)}
                />
                <label className="label" htmlFor="qtdFaces">
                  Qtd Faces*
                </label>
              </div>

              <FormControl className="span2">
                <InputLabel id="demo-multiple-checkbox-label">
                  Dentes Específicos
                </InputLabel>
                <Select
                  id="demo-multiple-checkbox"
                  multiple
                  value={dentesEspecificos}
                  onChange={dentesEspecificosChangeHandler}
                  input={<OutlinedInput label="Dentes" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  style={{ color: "#0c53fb" }}
                >
                  {DENTES.map((dente) => (
                    <MenuItem key={dente} value={dente}>
                      <Checkbox
                        checked={dentesEspecificos.indexOf(dente) > -1}
                      />
                      <ListItemText primary={dente} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="tratamentos-checkboxes__container">
                {/* aceita faces */}
                <div className="form-input">
                  <div className="checkbox-tratamento">
                    <span className="checkbox-text">Aceita Faces?</span>
                    <input
                      type="checkbox"
                      id="aceitaFaces"
                      checked={aceitaFaces}
                      onChange={(e) => setAceitaFaces(e.target.checked)}
                    />
                    <label
                      htmlFor="aceitaFaces"
                      className="toggle tratamento__checkbox-label"
                      id="tratamento__aceitaFaces"
                    >
                      <span>
                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                          <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>

                {/* aceita dentes */}
                <div className="form-input">
                  <div className="checkbox-tratamento">
                    <span className="checkbox-text">Aceita Dentes?</span>
                    <input
                      type="checkbox"
                      id="aceitaDentes"
                      checked={aceitaDentes}
                      onChange={(e) => setAceitaDentes(e.target.checked)}
                    />
                    <label
                      htmlFor="aceitaDentes"
                      className="toggle tratamento__checkbox-label"
                    >
                      <span>
                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                          <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>

                {/* cobertura INPS */}
                <div className="form-input">
                  <div className="checkbox-tratamento">
                    <span className="checkbox-text">INPS?</span>
                    <input
                      type="checkbox"
                      id="coberturaInps"
                      checked={coberturaInps}
                      onChange={(e) => setCoberturaInps(e.target.checked)}
                    />
                    <label
                      htmlFor="coberturaInps"
                      className="toggle tratamento__checkbox-label"
                    >
                      <span>
                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                          <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>

                {/* multiplos dentes */}
                <div className="form-input">
                  <div className="checkbox-tratamento">
                    <span className="checkbox-text">Múltiplos Dentes?</span>
                    <input
                      type="checkbox"
                      id="multiDentes"
                      checked={multiDentes}
                      onChange={(e) =>
                        multiDentesChangeHandler(e.target.checked)
                      }
                    />
                    <label
                      htmlFor="multiDentes"
                      className="toggle tratamento__checkbox-label"
                      // id="tratamento__multiDentes"
                    >
                      <span>
                        <svg width="10px" height="10px" viewBox="0 0 10 10">
                          <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {mensagemErro && (
                <div className="fluxo-atendimento__modal__erros">
                  {mensagemErro}
                </div>
              )}

              <div className="botoes">
                <button className="cancel-btn" onClick={handleCancel}>
                  Cancelar
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="blue-button"
                  onClick={handleSubmit}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTratamento;
